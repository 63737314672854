import React, {FormEvent, useState} from 'react';
import {Alert, Button, Col, Form as BSForm, Modal, Row, Spinner} from 'react-bootstrap';
import {Item, itemStore} from '../../../../../../redux/entities/inventory/item';
import {FieldArray, Form, Formik, FormikErrors} from 'formik';
import {
  StyledInputColumn, TitleRow, TopPaddedInputRow,
  TopPaddedRow, TopPaddedRowSeparator
} from '../../../../../../components/util/form-components/standardFormikInputLayout';
import Input from '../../../../../../components/util/form-components/formik-inputs/Input/Input';
import {propertyOf} from '../../../../../../util';
import {makeItem} from '../../../../../../redux/factory/inventory/item';
import {Dispatch, bindActionCreators} from 'redux';
import {AxiosError} from 'axios/index';
import {getErrorResponseMessage} from '../../../../../../util/http';
import {SubmitProps} from '../../../../../../types';
import {CommonState} from '../../../../../../redux';
import {connect} from 'react-redux';
import Select from 'react-select';
import {convertToDropDownOptions} from '../../../../../../util/form';
import {Category, categoryStore} from '../../../../../../redux/entities/inventory/category';
import ItemPicture, {UpdateItemPictureRequest} from '../../../../../../components/util/ItemPicture/ItemPicture';
import {FormikState} from 'formik/dist/types';
import CheckBoxInput from '../../../../../../components/util/form-components/formik-inputs/CheckBoxInput/CheckBoxInput';
import {ItemModalSchema} from './ItemModalSchema';
import ItemOptionsFieldArray from './ItemOptionsFieldArray';
import {isNullOrWhitespace} from '../../../../../../util/string';

export type UpsertItemRequest = Item & {updateItemPictureRequest?: UpdateItemPictureRequest};

type Props = SubmitProps<Item> & {
  show: boolean;
  itemId?: string;
  existing?: Item;
  selectable?: boolean;
  title?: string;
  predeterminedCategoryId?: string;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const getFieldName = propertyOf<UpsertItemRequest>;

const ItemModal = (props: Props) => {
  const {show, existing, selectable = false, onSubmit, onCancel, categoryDropDownOptions, alphabeticallySortedItems, getItemById,
    title = `${existing ? 'Edit' : 'Create'} Inventory Item`, predeterminedCategoryId = false, actions: {upsertItem}} = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imagePictureRequest, setImagePictureRequest] = useState<UpdateItemPictureRequest | undefined>();
  const [searchValue, setSearchValue] = useState('');
  const dropDownOptions = convertToDropDownOptions(alphabeticallySortedItems);

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    association: Item,
    validate: (values: Item) => Promise<FormikErrors<Item>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void,
    resetForm: (nextState?: Partial<FormikState<Item>> | undefined) => void) => {
    setIsSubmitting(true);
    setErrorMessage('');
    e.persist();
    e.preventDefault();
    const errors = await validate(association);
    if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setIsSubmitting(false);
    } else {
      try {
        const request = {...association, updateItemPictureRequest: imagePictureRequest};
        if (!predeterminedCategoryId)
          await upsertItem(request);
        onSubmit(request);
      } catch (e: AxiosError | any) {
        setErrorMessage(getErrorResponseMessage(e));
      }
    }
    setIsSubmitting(false);
  };

  const renderButtons = () => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={onCancel} variant={'danger'} className={'modal-close-button'}>
            {'Cancel'}
          </Button>
        }
        {!isSubmitting ? <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };

  const renderDropDown = (setValues:  (values: React.SetStateAction<Item>, shouldValidate?: (boolean | undefined)) => void) => {
    if (!selectable) return null;
    return (
      <TopPaddedRow>
        <Select
          options={dropDownOptions}
          backspaceRemovesValue={true}
          onChange={(newValue) => setValues({...getItemById(newValue?.value as any as string ?? '')})}
          onFocus={(e) => e.target.select()}
          openMenuOnFocus={false}
          openMenuOnClick={false}
          isClearable={true}
          isSearchable={true}
          menuShouldScrollIntoView={true}
          placeholder={'Search an item to move and / or edit...'}
          styles={{
            menuPortal: base => ({ ...base, zIndex: 3000, overflowY: 'visible', lineHeight: '0.5rem', transform: 'translate(0, -8px)'}),
            menu: base => ({...base, width: 'max-content', minWidth: '100%'}),
            container: base => ({...base, width: '100%'})

          }}
          menuPortalTarget={document.querySelector('body')}
          components={{}}
        />
      </TopPaddedRow>
    );
  };

  return (
    <Modal show={show} centered={true} size={'lg'}>
      <Modal.Body>
        <Modal.Title>{title}</Modal.Title>
        <Formik initialValues={existing ?? makeItem()} validationSchema={ItemModalSchema} onSubmit={() => undefined}>
          {({values, validateForm, handleSubmit, setFieldValue, setValues, resetForm}) => {
            return (
            <Form onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit, resetForm)}>
              <Col style={{width: '100%'}}>
                {renderDropDown(setValues)}
                <TopPaddedRow>
                  <Col style={{padding: 0, display: 'flex', minWidth: '60%', flexDirection: 'column'}}>
                    <TopPaddedRow>
                      <StyledInputColumn label={'Name'} columnSize={6}>
                        <Input name={getFieldName('name')}/>
                      </StyledInputColumn>
                      <StyledInputColumn label={'Category'} columnSize={6}>
                        <Select
                          defaultValue={!!predeterminedCategoryId ?
                            categoryDropDownOptions.find(c => c.value as unknown as string === values.categoryId) :
                            categoryDropDownOptions.find(c => c.value as unknown as string === predeterminedCategoryId)
                          }
                          options={categoryDropDownOptions}
                          isClearable={!predeterminedCategoryId}
                          onChange={(newValue) => setFieldValue(getFieldName('categoryId'), newValue?.value)}
                          isDisabled={!!predeterminedCategoryId}
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 3000, overflowY: 'visible' }),
                            menu: base => ({...base, width: 'max-content', minWidth: '100%'})
                          }}
                          menuPortalTarget={document.querySelector('body')}
                          components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                        />
                      </StyledInputColumn>
                    </TopPaddedRow>
                    <TopPaddedRow>
                      <StyledInputColumn label={'Quantity'} columnSize={4}>
                        <Input name={getFieldName('quantity')} type={'number'}/>
                      </StyledInputColumn>
                      <Col sm={1}/>
                      <StyledInputColumn label={'Restrict Order Size'} columnSize={3}>
                        <CheckBoxInput name={getFieldName('restrictOrderSize')} label={'Yes'}/>
                      </StyledInputColumn>
                      <StyledInputColumn label={'Maximum Order Size'} columnSize={4}>
                        <Input name={getFieldName('maxOrderSize')} type={'number'} disabled={!values.restrictOrderSize} valueOnDisabled={0}/>
                      </StyledInputColumn>
                    </TopPaddedRow>
                  </Col>
                </TopPaddedRow>
                <TopPaddedRow style={{borderTop: '1px solid black'}}>
                  <Col style={{padding: '0'}}>
                    <ItemPicture
                      containerStyle={{justifyContent: 'center'}}
                      style={{display: 'flex', aspectRatio: '1 / 1', height: '12rem', width: '12rem'}}
                      label={'Image'}
                      inputRowProps={{label: 'Image', labelSize: 2}}
                      itemId={values.id}
                      onSubmit={(data: any) => setImagePictureRequest(data)}
                    />
                  </Col>
                  <Col style={{padding: '0'}}>
                    <ItemOptionsFieldArray/>
                  </Col>
                </TopPaddedRow>
                <TopPaddedRow>
                  {!isNullOrWhitespace(errorMessage) && <Alert variant={'danger'}>{errorMessage}</Alert>}
                  <BSForm.Group className={'modal-buttons'}>
                    {renderButtons()}
                  </BSForm.Group>
                </TopPaddedRow>
              </Col>
            </Form>
          );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertItem: itemStore.actions.upsert
  }, dispatch)});
const mapStateToProps = (state: CommonState) => ({
  inventory: itemStore.selectors.getAsArray(state),
  alphabeticallySortedItems: itemStore.selectors.alphabeticallySortedItems(state),
  getItemById: itemStore.selectors.getById(state),
  categoryDropDownOptions: convertToDropDownOptions(categoryStore.selectors.getAsArray(state), propertyOf<Category>('name'))
});
export default connect(mapStateToProps, mapDispatchToProps)(ItemModal);
