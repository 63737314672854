import {isArray} from 'lodash';
import {useCallback, useMemo} from 'react';
import {Item} from '../redux/entities/inventory/item';
import {CategoryTreeNode} from '../redux/entities/inventory/category';

export const emptyArray = [];

export const makeReduceToDictionary = <T, K extends keyof any, V>(getKey: (val: T) => K, getVal: (val: T) => V) => (dictionary: Record<K, V>, item: T) =>
  ({...dictionary, [getKey(item)]: getVal(item)});

export const reduceToDictionary = <T, K extends keyof any, V>(values: T[], getKey: (val: T) => K, getVal: (val: T) => V) =>
  values.reduce<Record<K, V>>(makeReduceToDictionary(getKey, getVal), {} as Record<K, V>);

export function convertDictionaryToArray<T>(obj: {[key: number]: T}) {
  return Object.values(obj);
}

export function convertArrayToDictionary<T>(arr: T[], key: keyof T): {[key: number]: T} {
  const obj: any = {};
  for (const item of arr) {
    obj[(item as any)[key]] = item;
  }
  return obj;
}

export const nodeListToArray = (nodeList: NodeList | HTMLCollection) => [].slice.call(nodeList) as HTMLElement[];

export function replaceAtIndex<T>(arr: T[], index: number, value: T) {
  return [
    ...arr.slice(0, index),
    value,
    ...arr.slice(index + 1)
  ];
}

export function distinct<T>(value: T, index: number, self: T[]) {
  return self.indexOf(value) === index;
}

export const flattenTreeNodes =
  <T extends Record<ChildKey, T[]> & {id: string}, ChildKey extends keyof T & string>(data?: readonly T[], childrenKey?: ChildKey): Omit<T, ChildKey>[] => {
    if (data !== undefined && childrenKey !== undefined)
      return data.reduce<Omit<T, ChildKey>[]>((acc, d) => {
        const removedChildren = Object.fromEntries(Object.entries(d).filter(([k]) => k !== childrenKey)) as any as Omit<T, ChildKey>;
        if ((d && childrenKey in d && d[childrenKey] !== undefined)) {
          const recursedChildren = flattenTreeNodes(d[childrenKey], childrenKey);
          return [...acc, removedChildren, ...recursedChildren];
        }
        return [...acc, removedChildren];
      }, []);
    return [];
};
