import {ListTable} from '../../../../../../components/util/lists/ListTable/ListTable';
import {
  ListHeaderWithTextInput, ResetValueFunc
} from '../../../../../../components/util/lists/ListHeaderWithTextInput/ListHeaderWithTextInput';
import {DeletableListRow} from '../../../../../../components/util/lists/ListTable/DeletableListRow';
import {Row} from 'react-bootstrap';
import {ArrayHelpers, FieldArray, useFormikContext} from 'formik';
import React, {useState} from 'react';
import {propertyOf} from '../../../../../../util';
import {Item, ItemOption} from '../../../../../../redux/entities/inventory/item';
import {makeItemOption} from '../../../../../../redux/factory/inventory/item';
import {AppTheme} from '../../../../../../appTheme';
import IconButton from '../../../../../../components/util/widgets/IconButton/IconButton';
import Input from '../../../../../../components/util/form-components/formik-inputs/Input/Input';

const prefix = propertyOf<Item>('itemOptions');

const ItemOptionsFieldArray = () => {
  const [selectedItemOptionIndex, setSelectedItemOptionIndex] = useState<number | null>(null);
  const generateFieldName = (index: number) => `${prefix}[${index}]`;
  const getFieldName = (name: keyof ItemOption, index: number) => `${generateFieldName(index)}.${name}`;

  const { values, submitForm } = useFormikContext<Item>();

  const onAdd = (helpers: ArrayHelpers, name: string, resetValue: ResetValueFunc) => {
    helpers.push({...makeItemOption(), name: name});
    resetValue();
  };

  return (
    <FieldArray
      name={prefix}
      render={helpers => (
        <ListTable
          noItemsLabel={'No subcategories have been added. This item will be shown as a normal item.'}
          style={{width: '100%'}}
          items={values.itemOptions}
          renderHeader={() => (
              <ListHeaderWithTextInput
                label={'Listed Options'}
                onAdd={(v, r) => onAdd(helpers, v, r)}
                disableAddButton={false}
                addOnSelect={true}
                inputSize={7}
                labelSize={5}
                iconButtonProps={{icon: 'plus', color: AppTheme.colors.success, styles: {marginLeft: '10px'}}}
              />
          )}
          renderItem={(itemOption, index) => (
            <DeletableListRow
              editable={true}
              onClick={() => setSelectedItemOptionIndex(index)}
              key={index}
              onDelete={() => helpers.remove(index)}
              deleteColumnSize={1}
              style={{
                padding: '2px 5px 2px 1rem',
                borderTopLeftRadius: !index ? '5px' : undefined,
                borderTopRightRadius: !index ? '5px' : undefined,
                backgroundColor: selectedItemOptionIndex === index ?  'rgb(131, 208, 228)' : undefined
              }}
              render={() => (
                <Row style={{display: 'flex', flexGrow: '1', marginRight: '1rem'}}>
                  {itemOption.name}
                </Row>
              )}
            />
          )}
        />
      )}
    />
  );
};

export default ItemOptionsFieldArray;
