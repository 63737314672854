import {OrderItem} from '../../../redux/entities/inventory/joining/orderItem';
import {Item, itemStore} from '../../../redux/entities/inventory/item';
import {isNullOrWhitespace} from '../../../util/string';
import {Col, Row} from 'react-bootstrap';
import React, {CSSProperties, useMemo} from 'react';
import ItemPicture from '../ItemPicture/ItemPicture';
import {TopPaddedRow} from '../form-components/standardFormikInputLayout';
import {CommonState} from '../../../redux';
import {connect} from 'react-redux';
import styles from './OrderItemsList.module.scss';
import {AppTheme} from '../../../appTheme';
import {categoryStore} from "../../../redux/entities/inventory/category";

const OrderItemList = (props: {orderItems: OrderItem[]; separateByCategory?: boolean; styles?: CSSProperties} & ReturnType<typeof mapStateToProps>) => {
  const {orderItems, separateByCategory = false, getItemById, categories} = props;

  const shownOrderItems = orderItems
    .filter(oi => (oi?.itemQuantity !== undefined && oi?.itemQuantity) || (oi?.itemOptions?.length > 0 && oi?.itemOptions?.find(o => o.quantity > 0)))

  const renderItemOrOptions = (orderItem: OrderItem, item: Item) => {
    const renderedOptions = orderItem?.itemOptions?.filter(o => o.quantity > 0).filter(o => !isNullOrWhitespace(o.itemOptionId));
    if (renderedOptions?.length > 0) return renderedOptions.map((orderItemOption) => {
      const itemOption = item?.itemOptions?.find(io => io?.id === orderItemOption?.itemOptionId);
      return (
        <Row key={itemOption?.id} className={styles['item-quantity']}>
          <div>{itemOption?.name}:</div>
          <div style={{marginLeft: '10px', fontWeight: 'bold'}}>{orderItemOption?.quantity}</div>
        </Row>
      );
    });
    return <Row className={styles['item-quantity']} style={{fontWeight: 'bold'}}>{orderItem?.itemQuantity ?? ''}</Row>;
  };

  const renderItems = (categoryId: string) => {
    return shownOrderItems
      .filter(oi => isNullOrWhitespace(categoryId) || getItemById(oi.itemId)?.categoryId === categoryId)
      .map((orderItem, index) => {
        const item = getItemById(orderItem.itemId);
        return (
          <TopPaddedRow
            key={item?.id}
            className={styles['list-row']}
            style={{...props.styles, backgroundColor: index % 2 !== 0 ? AppTheme.colors.largerOffsetBackground : undefined}}
          >
            <div style={{display: 'flex', flexDirection: 'column', flex: '1'}}>
              <div style={{display: 'flex', alignItems: 'center', flexWrap: 'nowrap', wordWrap: 'normal'}}>
                <ItemPicture itemId={item?.id} editable={false} style={{height: '2rem', width: '2rem', marginRight: '20px'}}/>
                <span style={{fontWeight: 'bold'}}>{item?.name}</span>
              </div>
              {!isNullOrWhitespace(orderItem?.extraComment) ?
                <div style={{marginTop: '10px'}}>
                  {orderItem.extraComment}
                </div>
                : null
              }
            </div>
            <Col className={styles['item-quantity-and-options']}>
              {renderItemOrOptions(orderItem, item)}
            </Col>
          </TopPaddedRow>
        );
      })
  }

  return (
    <Col style={{padding: 0}}>
      {separateByCategory ?
        categories
          .filter(category =>
            shownOrderItems.find(oi => getItemById((oi?.itemId === undefined || isNullOrWhitespace(oi.itemId)) ? '' : oi.itemId)?.categoryId === category.id)
          )
          .map(category => (
          <div id={category.id}>
            <h5 style={{fontSize: '1.1rem', marginTop: '2rem'}}>{category.name}</h5>
            {renderItems(category.id)}
          </div>
          ))
        : renderItems('')
      }
    </Col>
  );
};

const mapStateToProps = (state: CommonState) => ({
  getItemById: itemStore.selectors.getById(state),
  categories: categoryStore.selectors.getAsArray(state)
});
export default connect(mapStateToProps)(OrderItemList);
