import React, {CSSProperties, FormEvent, ImgHTMLAttributes, ReactNode, useEffect, useRef, useState} from 'react';
import {Button, Col, Row, Spinner} from 'react-bootstrap';
import {InputRow, InputRowProps} from '../form-components/InputRow';
import {Form as BSForm} from 'react-bootstrap';
import Input from '../form-components/formik-inputs/Input/Input';
import styles from './ItemPicture.module.scss';
import {AxiosError} from 'axios';
import StandardErrorMessage from '../StandardErrorMessage';
import {getLogoLink, noProfilePicture} from '../../../appTheme';
import {bindActionCreators, Dispatch} from 'redux';
import {CommonState} from '../../../redux';
import {connect} from 'react-redux';
import {getBase64} from '../../../util/file';
import {getErrorResponseMessage} from '../../../util/http';
import {itemStore} from '../../../redux/entities/inventory/item';
import {da} from 'date-fns/locale';
import {InputColumn, InputColumnProps} from '../form-components/InputColumn';
import {TopPaddedRow} from '../form-components/standardFormikInputLayout';
import {isNullOrWhitespace} from '../../../util/string';

type Props = {
  itemId: string;
  label?: string;
  containerStyle?: CSSProperties;
  editable?: boolean;
  onSubmit?: (request: UpdateItemPictureRequest) => void;
  sendDefaultRequest?: boolean;
  inputRowProps?: InputRowProps;
} & ImgHTMLAttributes<HTMLImageElement> & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export interface UpdateItemPictureRequest {
  itemId: string;
  imageBase64: string | null;
  fileName: string;
}

function ItemPicture(props: Props) {
  const {itemId, containerStyle, editable = true, onSubmit, sendDefaultRequest = true, getItemById, actions: {updateItemPicture},
  ...imageBaseProps} = props;
  const getImageUrl = () => {
    const imagePath = getItemById(itemId)?.imagePath;
    if (!isNullOrWhitespace(imagePath))
      return `${process.env.REACT_APP_STORAGE_URL}/Public/ItemPictures/${itemId}/${imagePath}`;
    return getLogoLink();
  };

  const [showEditorModal, setShowEditorModal] = useState(false);
  const [renderedImage, setRenderedImage] = useState(getImageUrl());
  const [updatedImageData, setUpdatedImageData] = useState('');
  const onChange = async (data: string) => {
    const request = {imageBase64: await encodeImage(data), fileName: getImageFileName(), itemId: itemId};
    setUpdatedImageData(data);
    setRenderedImage(request.imageBase64 ?? '');
    if (onSubmit) onSubmit(request);
  };
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!updatedImageData)
      setRenderedImage(getImageUrl());
  }, [getImageUrl, updatedImageData]);


  async function encodeImage(data: string) {
    if(data !== '') {
      return await getBase64(data as any as File);
    } else {
      return null;
    }
  }

  const getImageFileName = () => {
    const refFile = fileInputRef.current?.files ? fileInputRef.current.files[0] : null;
    return refFile?.name ?? '';
  };

  const resetModal = () => {
    setShowEditorModal(false);
    setUpdatedImageData('');
  };

  const editorForm = () => (
    <Col>
      <Row style={{overflow: 'hidden'}}>
        <div style={{marginRight: '20px'}}>
          {props.label}
        </div>
        <div style={{display: 'flex', maxWidth: '70%'}}>
          <Input name={'data'} forwardedRef={fileInputRef} type={'file'} setFormDataState={onChange}/>
        </div>
      </Row>
      <Row style={{paddingTop: 'auto', ...containerStyle}}>
        <img
          {...imageBaseProps}
          style={{display: 'block', objectFit: 'contain', height: '6em', width: '6rem', borderRadius: '50%', ...imageBaseProps.style}}
          src={updatedImageData ? URL.createObjectURL(updatedImageData as any as File) : renderedImage}
          alt={'Profile picture'}
        />
      </Row>
      <StandardErrorMessage errorMessage={errorMessage}/>
    </Col>
  );

  if (editable) return editorForm();
  return (
    <div>
      <img
        style={{height: '6rem', width: '6rem', borderRadius: '50%', ...props.style}}
        src={renderedImage}
        alt={'Item picture'}
      />
    </div>
  );
}

const mapStateToProps = (state: CommonState) => ({
  getItemById: itemStore.selectors.getById(state)
});
const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    updateItemPicture: itemStore.actions.updateItemPicture
  }, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(ItemPicture);
