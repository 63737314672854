import {Dictionary} from '../../types';
import {batchActions} from 'redux-batched-actions';
import {Item, itemStore} from '../entities/inventory/item';
import {Dispatch} from 'redux';
import {getInventoryManagementState} from '../../api/inventory/inventoryApi';
import {Order, orderStore} from '../entities/inventory/order';
import {Category, categoryStore} from '../entities/inventory/category';

export interface InventoryManagementState {
  items: Dictionary<Item>;
  orders: Dictionary<Order>;
  categories: Dictionary<Category>;
}

export const loadInventoryManagementData = () => async (dispatch: Dispatch) => {
  const response: InventoryManagementState = await getInventoryManagementState();
  await dispatch(batchActions([
    dispatch(itemStore.actions.set(response.items)),
    dispatch(orderStore.actions.set(response.orders)),
    dispatch(categoryStore.actions.set(response.categories))
  ], 'LOAD_INVENTORY_MANAGEMENT_STATE'));
};
