import React from 'react';
import {Col} from 'react-bootstrap';
import {
  StyledInputColumn,
  TitleRow,
  TopPaddedRow
} from '../../../../components/util/form-components/standardFormikInputLayout';
import Input from '../../../../components/util/form-components/formik-inputs/Input/Input';
import PhoneInputField
  from '../../../../components/util/form-components/formik-inputs/PhoneNumberInput/PhoneNumberInput';
import {ChangeViewButton, ChangeViewButtonProps, getConsumerInfoName} from '../HealthProgramOrder';
import {EBootstrapVariant, PublicMessage} from "../../../../types/PublicMessage";
import {isNullOrWhitespace} from "../../../../util/string";
import {PublicMessageAlert} from "../../../../components/util/PublicMessageAlert";

export function ConsumerInformationForm(props: Omit<ChangeViewButtonProps, 'title'> & {message: PublicMessage | null}) {
  return (
    <Col>
      <TitleRow
        label={
        <ChangeViewButton
          title={'Local Health Program Order Form'}
          asSinglePage={props.asSinglePage}
          setAsSinglePage={props.setAsSinglePage}
          setTab={props.setTab}
        />}
        centerTitle={true}>
        <TopPaddedRow style={{textAlign: 'center', justifyContent: 'center'}}>
          <span>For questions regarding this form, please contact Local Health Program Manager, Brian Jointer, at{' '}
            <a href={'mailto: abc@example.com'} style={{display: 'inline'}}>
              brian.jointer@soshealthandhope.org
            </a>
          </span>
        </TopPaddedRow>
        {!isNullOrWhitespace(props.message?.message) ? <PublicMessageAlert styles={{textAlign: 'center'}} message={props.message ?? {message: '', bootstrapVariant: EBootstrapVariant.none}}/> : null}
        <TopPaddedRow>
          <StyledInputColumn label={'Name of Organization'}>
            <Input name={getConsumerInfoName('organization')}/>
          </StyledInputColumn>
        </TopPaddedRow>
        <TopPaddedRow>
          <StyledInputColumn label={'What department or outreach will be serviced?'}>
            <Input name={getConsumerInfoName('departmentOrOutreach')}/>
          </StyledInputColumn>
        </TopPaddedRow>
      </TitleRow>
      <TitleRow label={'Contact Information'}>
        <TopPaddedRow>
          <StyledInputColumn label={'Name of Contact'} columnSize={6}>
            <Input name={getConsumerInfoName('name')}/>
          </StyledInputColumn>
          <StyledInputColumn label={'Contact Job Title'} columnSize={6}>
            <Input name={getConsumerInfoName('jobTitle')}/>
          </StyledInputColumn>
        </TopPaddedRow>
        <TopPaddedRow>
          <StyledInputColumn label={'Contact Email Address'} columnSize={6}>
            <Input name={getConsumerInfoName('emailAddress')}/>
          </StyledInputColumn>
          <StyledInputColumn label={'Contact Phone Number'} columnSize={6}>
            <PhoneInputField name={getConsumerInfoName('phoneNumber')}/>
          </StyledInputColumn>
        </TopPaddedRow>
      </TitleRow>
      <TitleRow label={'Organization Address'}>
        <TopPaddedRow>
          <StyledInputColumn label={'Street Address'} columnSize={6}>
            <Input name={getConsumerInfoName('streetAddress')}/>
          </StyledInputColumn>
          <StyledInputColumn label={'Street Address Line 2'} columnSize={6}>
            <Input name={getConsumerInfoName('streetAddressLineTwo')}/>
          </StyledInputColumn>
        </TopPaddedRow>
        <TopPaddedRow>
          <StyledInputColumn label={'City'} columnSize={6}>
            <Input name={getConsumerInfoName('city')}/>
          </StyledInputColumn>
          <StyledInputColumn label={'State'} columnSize={3}>
            <Input name={getConsumerInfoName('state')}/>
          </StyledInputColumn>
          <StyledInputColumn label={'Postal / ZIP'} columnSize={3}>
            <Input name={getConsumerInfoName('zip')}/>
          </StyledInputColumn>
        </TopPaddedRow>
      </TitleRow>
      <TitleRow label={'Order Beneficiaries'}>
        <TopPaddedRow>
          <StyledInputColumn label={'Who will benefit from this donation?'}>
            <Input name={getConsumerInfoName('beneficiaries')}/>
          </StyledInputColumn>
        </TopPaddedRow>
        <TopPaddedRow>
          <StyledInputColumn label={'Please tell us how this donation will help the organization and/or the recipients.'}>
            <Input name={getConsumerInfoName('beneficiariesDescription')} type={'textarea'} rows={8}/>
          </StyledInputColumn>
        </TopPaddedRow>
        <TopPaddedRow>
          <StyledInputColumn label={'Approximately how many people will benefit from this donation?'}>
            <Input name={getConsumerInfoName('beneficiariesQuantity')}/>
          </StyledInputColumn>
        </TopPaddedRow>
      </TitleRow>
    </Col>
  );
}
