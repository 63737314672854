import {Col, Form, Row} from 'react-bootstrap';
import {InputIconButton} from '../../widgets/InputIconButton/InputIconButton';
import React, {CSSProperties, useState} from 'react';
import {CommonListHeaderProps} from '../ListHeaderWithDropDown/ListHeaderWithDropDown';
import {OnChangeFormControlType} from '../../../../types';
import {IconButtonProps, LabeledIconButtonProps} from '../../widgets/IconButton/IconButton';

export type ResetValueFunc = () => void;

interface Props extends CommonListHeaderProps {
  onAdd: (value: string, resetValue: ResetValueFunc) => void;
  disableAddButton?: boolean;
  addButtonProps?: IconButtonProps;
  labelStyle?: CSSProperties;
  labelSize?: number;
  inputSize?: number;
}

export function ListHeaderWithTextInput({label, disableAddButton, iconButtonProps, onAdd, extraDropDown, labelStyle, labelSize, inputSize}: Props) {
  const [value, setFieldValue] = useState<string>('');
  return (
    <Row style={{width: '100%'}}>
      <Form.Label column={true} sm={labelSize ?? (extraDropDown ? 4 : 6)} style={{marginBottom: 20, ...labelStyle}}>{label}</Form.Label>
      {!disableAddButton && (
        <>
          {extraDropDown && <Col sm={3}>{extraDropDown}</Col>}
          <Col style={{padding: '0'}} sm={inputSize ?? (extraDropDown ? 3 : 4)}>
            <InputIconButton
              inputComponent={(
                <Form.Control
                  value={value}
                  onChange={(e: OnChangeFormControlType) => setFieldValue(e.currentTarget.value ?? '')}
                />
              )}
              iconButtonProps={{
                ...iconButtonProps,
                icon: iconButtonProps?.icon ?? 'plus',
                styles: {marginLeft: '5px', ...iconButtonProps?.styles},
                onClick: (e) =>  value !== undefined ? onAdd(value!, () => setFieldValue('')) : null
              }}
            />
          </Col>
        </>)}
    </Row>
  );
}
