import React, {FormEvent, useRef, useState} from 'react';
import {Button, Form as BSForm, Modal, Row, Spinner} from 'react-bootstrap';
import {InputRow} from '../../../../components/util/form-components/InputRow';
import {Form, Formik} from 'formik';
import Input from '../../../../components/util/form-components/formik-inputs/Input/Input';
import styles from './ProfilePicture.module.scss';
import {AxiosError} from 'axios';
import {getErrorResponseMessage} from '../../../../util/http';
import StandardErrorMessage from '../../../../components/util/StandardErrorMessage';
import {noProfilePicture} from '../../../../appTheme';
import {connect} from 'react-redux';
import {getBase64} from '../../../../util/file';

type Props = {
  userId: string;
  profilePicturePath: string;
  action: (request: UpdateProfilePictureRequest) => void;
};

export interface UpdateProfilePictureRequest {
  userId: string;
  imageBase64: string | null;
  fileName: string;
}

export function ProfilePicture(props: Props) {
  const {userId, profilePicturePath, action} = props;
  const [currentProfilePicture, setCurrentProfilePicture] = useState(profilePicturePath);
  const [showEditorModal, setShowEditorModal] = useState(false);
  const [profilePictureData, setProfilePictureData] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [localImageFilePreviewUrl, setLocalImageFilePreviewUrl] = useState<any>();

  async function encodeImage() {
    if(profilePictureData !== '') {
      return await getBase64(profilePictureData as any as File);
    } else {
      return null;
    }
  }

  const getImageFileName = () => {
    const refFile = fileInputRef.current?.files ? fileInputRef.current.files[0] : null;
    return refFile?.name ?? '';
  };

  const resetModal = () => {
    setShowEditorModal(false);
    setLocalImageFilePreviewUrl(null);
    setProfilePictureData('');
  };

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    association: UpdateProfilePictureRequest) => {
    setIsSubmitting(true);
    setErrorMessage('');
    e.persist();
    e.preventDefault();
    try {
      await action({fileName: getImageFileName(), userId: userId, imageBase64: await encodeImage()});

      resetModal();
    } catch (e: AxiosError | any) {
      setErrorMessage(getErrorResponseMessage(e));
    }
    setIsSubmitting(false);
  };

  const renderButtons = () => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button
            onClick={(event) => {
            event.preventDefault();
            resetModal();
          }}
            variant={'danger'}
            className={styles['close-button']}
          >
            Cancel
          </Button>
        }
        {!isSubmitting ? <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };

  const editorModal = () => (
      <Modal show={showEditorModal} centered={true} style={{zIndex: '1050'}}>
        <Modal.Body>
          <Modal.Title>Edit Profile Picture</Modal.Title>
            <Formik<UpdateProfilePictureRequest> initialValues={{imageBase64: profilePictureData, fileName: '', userId: userId}} onSubmit={() => undefined}>
              {({values}) => (
                <Form onSubmit={(e) => processSubmit(e, values)}>
                  <InputRow label={'Image File'} labelSize={2.5} columnSize={9} style={{paddingTop: '10px', paddingLeft: '15px'}}>
                    <Input name={'data'} forwardedRef={fileInputRef} type={'file'} setFormDataState={setProfilePictureData}/>
                  </InputRow>
                  {profilePictureData ?
                    <Row style={{paddingTop: '10px', paddingBottom: '10px', paddingRight: '15px', justifyContent: 'center'}}>
                      <img
                        style={{objectFit: 'contain', height: '6rem', width: '6rem', borderRadius: '50%'}}
                        src={URL.createObjectURL(profilePictureData as any as File)}
                        alt={'Profile picture'}
                      />
                    </Row> : null
                  }
                  <StandardErrorMessage errorMessage={errorMessage}/>
                  <Row style={{paddingTop: '10px'}}>
                    <BSForm.Group className={styles['form-buttons']}>
                      {renderButtons()}
                    </BSForm.Group>
                  </Row>
                </Form>
              )}
            </Formik>
        </Modal.Body>
      </Modal>
  );

  return (
    <>
      <div>
        <a
          onClick={(event) => {
            event.preventDefault();
            setShowEditorModal(true);
          }}
          className={styles['profile-picture']}
        >
          <img
            style={{objectFit: 'contain', height: '10rem', width: '10rem', borderRadius: '50%'}}
            src={profilePicturePath !== '' ? `${profilePicturePath}` : noProfilePicture()}
            alt={'Profile picture'}
          />
        </a>
      </div>
      {editorModal()}
    </>
  );
}

// const mapStateToProps = (state: WebState) => ({
//   currentUser: userStore.selectors.getCurrentUser(state)
// });
// const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
//     updateUserPreferences: userStore.actions.updateUserPreferences,
//     updateProfilePictureAction: userStore.actions.updateProfilePicture
//   }, dispatch)});

export default connect()(ProfilePicture);

