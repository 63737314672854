import {Col, Row} from 'react-bootstrap';
import React from 'react';
import IconButton from '../../../components/util/widgets/IconButton/IconButton';
import {RoutePaths} from '../../../router/RoutePaths';
import {AppTheme} from '../../../appTheme';

// differing font sizes on icon buttons and margins on icon texts are due to the user and box icons being different base sizes
export function Landing() {
  return (
    <Col className={'center fill-col'} style={{textAlign: 'center', height: '100%'}}>
      <Row style={{marginBottom: '3rem'}}>
        <h1>What would you like to do?</h1>
      </Row>
        <Row className={'center fill-row'} style={{width: '100%', justifyContent: 'space-evenly'}}>
          <Col style={{margin: '1rem 0'}}>
            <IconButton
              icon={'box'}
              link={RoutePaths.publicPaths.order}
              iconToolTipText={'Place an order'}
              size={'8x'}
              styles={{fontSize: '2rem'}}
              color={AppTheme.colors.publicPrimary}
            />
            <h5>I want to order supplies</h5>
          </Col>
          <Col style={{margin: '1rem 0'}}>
            <IconButton
              icon={'user'}
              link={RoutePaths.authPaths.login}
              iconToolTipText={'Login'}
              size={'8x'}
              styles={{fontSize: '1.83rem', marginTop: '.5rem'}}
              color={AppTheme.colors.publicPrimary}
            />
            <h5 style={{marginTop: '1rem'}}>I'm a staff member</h5>
          </Col>
      </Row>
    </Col>
  );
}
