import {api} from "./index";
import {EBootstrapVariant, PublicMessage} from "../types/PublicMessage";

export async function getPublicMessage() {
  return (await api.get<PublicMessage | null>('/PublicMessage')).data;
}

export async function postPublicMessage(values: PublicMessage) {
  return (await api.post<PublicMessage>('/PublicMessage', values)).data;
}
