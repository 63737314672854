import {Item, ItemOption} from '../../entities/inventory/item';

export function makeItem(): Item {
  return {
    id: '',
    name: '',
    quantity: 0,
    restrictOrderSize: false,
    maxOrderSize: 0,
    estimatedCost: 0,
    imagePath: '',
    categoryId: '',
    archived: false,
    orderItems: [],
    itemOptions: []
  };
}

export function makeItemOption(): ItemOption {
  return {
    id: '',
    itemId: '',
    quantity: 0,
    name: '',
    archived: false
  };
}
