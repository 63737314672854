import {Role} from '../entities/role';
import {AccountStatus, User, UserPreferences} from '../entities/user';
import {UserUpsert} from '../../pages/private/Configuration/UserManagement/components/UserModal/UserModal';

export function makeUser(role?: Role): User {
  return {
    id: '',
    email: '',
    createdAt: '',
    archivedAt: null,
    name: '',
    primaryPhone: '',
    secondaryPhone: '',
    role: role ?? {
      id: '',
      roleName: ''
    },
    roleId: '',
    address: '',
    addressLineTwo: '',
    city: '',
    state: '',
    zip: '',
    accountStatus: AccountStatus.EmailValidationRequired,
    profilePicturePath: ''
  };
}

export function makeUserRegistration(roleId?: string): UserUpsert {
  return {
    id: '',
    email: '',
    name: '',
    primaryPhone: '',
    secondaryPhone: '',
    address: '',
    addressLineTwo: '',
    city: '',
    state: '',
    zip: '',
    roleId: roleId ?? ''
  };
}

export function makeUserPreferences(user: User): UserPreferences {
  return {
    city: user.city,
    state: user.state,
    zip: user.zip,
    address: user.address,
    addressLineTwo: user.addressLineTwo,
    primaryPhone: user.primaryPhone,
    secondaryPhone: user.secondaryPhone
  };
}
