import {
  StyledInputColumn,
  TitleRow,
  TopPaddedRow
} from '../../../../components/util/form-components/standardFormikInputLayout';
import PaddedRowsListFormatter from '../../../../components/util/lists/PaddedRowsListFormatter/PaddedRowsListFormatter';
import React, {LegacyRef, useEffect, useRef, useState} from 'react';
import SignatureCanvas, {ReactSignatureCanvasProps} from 'react-signature-canvas';
import {Alert, Button, Row} from 'react-bootstrap';
import ReactSignatureCanvas from 'react-signature-canvas';
import {ChangeViewButton, ChangeViewButtonProps} from '../HealthProgramOrder';
import {useMount} from '../../../../hooks/useMount';
import {RedErrorMessage} from '../../../../components/util/form-components/RedErrorMessage/RedErrorMessage';

export const TermsOfServiceForm = React.forwardRef<ReactSignatureCanvas, Omit<ChangeViewButtonProps, 'title'>>((props, canvasRef) => {
  const [interval, setInterval] = useState(false);

  // @ts-ignore
  useEffect(() => { if (interval) canvasRef.current?.forceUpdate(); setInterval(false); }, [interval]);
  useMount(() => {
    setTimeout(() => setInterval(true), 1000);
  });

  // @ts-ignore
  const clearCanvas = () => canvasRef.current?.clear();

  return (
    <TitleRow
      label={
      <ChangeViewButton
        title={'Donation Agreement'}
        asSinglePage={props.asSinglePage}
        setAsSinglePage={props.setAsSinglePage}
        setTab={props.setTab}
      />}
      centerTitle={true
    }>
      <Alert variant={'warning'} style={{marginTop: '2rem'}}>
      <TopPaddedRow>
        Upon receiving supplies or equipment from SOS International, Incorporated...
      </TopPaddedRow>
      <TopPaddedRow style={{textAlign: 'start'}}>
        <PaddedRowsListFormatter type={'unordered'}>
          <span>
            The recipient agrees to submit information concerning how donated supplies were{' '}
            utilized via regular data requests from the Local Health Program Manager.
          </span>
          <span>The recipient agrees to not redistribute or sell donated supplies or{' '}
            equipment without expressed permission from SOS International, Inc.
          </span>
          <span>
            In the event a piece of donated equipment is no longer needed or cannot be used by the recipient,{' '}
            and is in working condition, the recipient agrees to return the equipment to SOS International, Inc.
          </span>
          <span>
            SOS works with public health and government agencies to distribute medical supplies in times of emergency. In the{' '}
            rare event of a community health crisis, the recipient agrees to return any equipment or supplies specifically requested by SOS.
          </span>
        </PaddedRowsListFormatter>
      </TopPaddedRow>
      </Alert>
      <TopPaddedRow>
        <StyledInputColumn style={{flex: '0'}} label={'Signature'}>
          <Row style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{border: '1px solid black', borderRadius: '3px', display: 'flex'}}>
              <SignatureCanvas
                ref={canvasRef}
                penColor={'black'}
                dotSize={3}

                canvasProps={{width: '270', height: '100', className: 'sigCanvas'}}
              />
            </div>
            <div style={{margin: '5px 0 0 auto'}}>
              <Button variant={'warning'} onClick={clearCanvas} style={{fontSize: '0.8rem'}}>
                Clear Signature
              </Button>
            </div>
            <RedErrorMessage name={'consumerInformation.signaturePath'}/>
          </Row>
        </StyledInputColumn>
      </TopPaddedRow>
    </TitleRow>
  );
});
