import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import styles from './Profile.module.scss';
import {Alert, Card, Col, Row} from 'react-bootstrap';
import {CenteredSpinner} from '../../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {RoutePaths} from '../../../router/RoutePaths';
import {useStandardEditor} from '../../../components/util/form-components/EditorForm/hooks';
import Input from '../../../components/util/form-components/formik-inputs/Input/Input';
import {EditorForm} from '../../../components/util/form-components/EditorForm/EditorForm';
import {AppTheme} from '../../../appTheme';
import {UserPreferences, userStore} from '../../../redux/entities/user';
import {makeUser, makeUserPreferences} from '../../../redux/factory/user';
import ProfilePicture from './components/ProfilePicture';
import DatepickerInput from '../../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import {
  StyledInputColumn,
  TitleRow,
  TopPaddedRow
} from '../../../components/util/form-components/standardFormikInputLayout';
import PhoneInputField from '../../../components/util/form-components/formik-inputs/PhoneNumberInput/PhoneNumberInput';
import {CommonState} from '../../../redux';
import {PasswordResetRequestForm} from '../../auth/ResetPassword/ResetPassword';
import {resetAuthenticatedPassword, resetPassword} from '../../../api/authApi';
import {getErrorResponseMessage, isBadRequest} from '../../../util/http';
import {useLocation} from 'react-router-dom';
import {propertyOf} from '../../../util';
import {LoadingButton} from '../../../components/util/widgets/LoadingButton/LoadingButton';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function Profile(props: Props) {
  const {currentUser, actions: {updateUserPreferences, updateProfilePictureAction}} = props;
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [profileErrorMessage, setProfileErrorMessage] = useState<string | undefined>();
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string | undefined>();
  const location = useLocation();

  const getFieldName = (name: keyof UserPreferences) => name;
  const profileEditor = useStandardEditor<UserPreferences>(
    'Profile',
    makeUser(),
    props,
    s => ({
      getEditUrl: () => RoutePaths.profilePaths.Edit,
      getCancelUrl: () => RoutePaths.profilePaths.View,
      onLoadForm: async () => makeUserPreferences(currentUser!),
      onNewForm: async () => makeUserPreferences(currentUser!),
      errorMessage: errorMessage,
      setErrorMessage: setErrorMessage,
      saveAndRedirect: async (form) => {
        try {
          await props.actions.updateUserPreferences(form);
        } catch (e) {
          if (isBadRequest(e)) {
            setProfileErrorMessage(getErrorResponseMessage(e));
          }
        }
        return RoutePaths.profilePaths.View;
      },
      footer: true,
      editButtonText: 'Edit Profile',
      saveButtonText: 'Save Profile',
      submitButtonColor: AppTheme.colors.logoBlue,
      editable: location.pathname === RoutePaths.privatePaths.profilePaths.Edit
    }));
  const {editable} = profileEditor;

  const passwordEditor = useStandardEditor<PasswordResetRequestForm>(
    'Password',
    {password: '', confirmPassword: ''} as PasswordResetRequestForm,
    {},
    s => ({
      getEditUrl: () => RoutePaths.profilePaths.EditPassword,
      getCancelUrl: () => RoutePaths.profilePaths.View,
      onLoadForm: async () => ({password: '', confirmPassword: '', email: currentUser?.email ?? ''} as PasswordResetRequestForm),
      onNewForm: async () => ({password: '', confirmPassword: '', email: currentUser?.email ?? ''} as PasswordResetRequestForm),
      errorMessage: passwordErrorMessage,
      setErrorMessage: setPasswordErrorMessage,
      saveAndRedirect: async (form) => {
        if (form.password !== form.confirmPassword) {
          setPasswordErrorMessage('Passwords do not match');
          return RoutePaths.profilePaths.EditPassword;
        }
        try {
          await resetAuthenticatedPassword({...form, email: currentUser?.email ?? ''});
          return RoutePaths.profilePaths.View;
        } catch (e) {
          if (isBadRequest(e)) {
            setPasswordErrorMessage(getErrorResponseMessage(e));
          }
          return RoutePaths.profilePaths.EditPassword;
        }
      },
      footer: true,
      editButtonText: 'Edit Password',
      saveButtonText: 'Save Password',
      submitButtonColor: AppTheme.colors.logoBlue,
      editable: location.pathname === RoutePaths.privatePaths.profilePaths.EditPassword
    }));

  const renderContent = () => {
    return (
      <Row style={{flexGrow: 1}}>
        <Col className={styles['page-body']}>
          <Col style={{display: 'flex', width: '100%'}}>
            <Row style={{display: 'flex', width: '100%', alignItems: 'center'}}>
              <div style={{marginRight: '2rem'}}>
                <ProfilePicture
                  userId={currentUser?.id ?? ''}
                  profilePicturePath={currentUser?.profilePicturePath ?? ''}
                  action={updateProfilePictureAction}
                />
              </div>
              <div>
                <h2 style={{color: AppTheme.colors.logoGreen}}>{currentUser?.name}</h2>
                <h5>{currentUser?.email}</h5>
              </div>
          </Row>
          </Col>
          <TopPaddedRow style={{marginTop: '10px'}}>
            <Col className={'fill-col'} style={{flex: '1', minWidth: '16rem'}}>
              <fieldset disabled={location.pathname === RoutePaths.profilePaths.EditPassword ?? undefined}>
                <EditorForm standardEditor={profileEditor}>
                  {({values, setFieldValue}) => {
                    return (
                      <Row>
                        <Col style={{padding: '0'}}>
                        <StyledInputColumn label='Primary Phone'>
                          <PhoneInputField name={getFieldName('primaryPhone')} disabled={!editable}/>
                        </StyledInputColumn>
                        <StyledInputColumn label='Secondary Phone'>
                          <PhoneInputField name={getFieldName('secondaryPhone')} disabled={!editable}/>
                        </StyledInputColumn>
                        <StyledInputColumn label='Address'>
                          <Input name={getFieldName('address')} disabled={!editable}/>
                        </StyledInputColumn>
                        <StyledInputColumn label='Address Line Two'>
                          <Input name={getFieldName('addressLineTwo')} disabled={!editable}/>
                        </StyledInputColumn>
                        </Col>
                        <Col>
                        <StyledInputColumn label='City'>
                          <Input name={getFieldName('city')} disabled={!editable}/>
                        </StyledInputColumn>
                        <StyledInputColumn label='State'>
                          <Input name={getFieldName('state')} disabled={!editable}/>
                        </StyledInputColumn>
                        <StyledInputColumn label='Zip Code'>
                          <Input name={getFieldName('zip')} disabled={!editable}/>
                        </StyledInputColumn>
                        </Col>
                      </Row>
                    );
                  }}
                </EditorForm>
              </fieldset>
            </Col>
            <Col className={'fill-col'} style={{flex: '0.6'}}>
              <fieldset disabled={location.pathname === RoutePaths.profilePaths.Edit ?? false}>
                <EditorForm standardEditor={passwordEditor}>
                  {({values, setFieldValue}) => {
                    return (
                      <Row style={{width: '100%'}}>
                        <Col style={{padding: '0'}}>
                          <StyledInputColumn label={'Password'}>
                            <Input
                              name={propertyOf<PasswordResetRequestForm>('password')}
                              placeholder={'Password'}
                              type={'password'}
                              overrideDebouncePeriod={1}
                              disabled={!passwordEditor.editable}
                            />
                          </StyledInputColumn>
                          <StyledInputColumn label={'Confirm Password'}>
                            <Input
                              name={propertyOf<PasswordResetRequestForm>('confirmPassword')}
                              placeholder={'Confirm Password'}
                              type={'password'}
                              overrideDebouncePeriod={1}
                              disabled={!passwordEditor.editable}
                            />
                          </StyledInputColumn>
                        </Col>
                      </Row>
                    );
                  }}
                </EditorForm>
              </fieldset>
            </Col>
          </TopPaddedRow>
        </Col>
      </Row>
    );
  };

  return loading ?  <CenteredSpinner/> : (errorMessage ? <CenteredErrorMessage message={errorMessage} /> : renderContent());
}

const mapStateToProps = (state: CommonState) => ({
  currentUser: userStore.selectors.getCurrentUser(state)
});
const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    updateUserPreferences: userStore.actions.updateUserPreferences,
    updateProfilePictureAction: userStore.actions.updateProfilePicture
  }, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
