import React, {FormEvent, useState} from "react";
import {useMount} from "../../../hooks/useMount";
import {AxiosError} from "axios";
import {getErrorResponseMessage, handleAxiosError} from "../../../util/http";
import {CenteredSpinner} from "../../../components/util/widgets/CenteredSpinner/CenteredSpinner";
import {CenteredErrorMessage} from "../../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage";
import {getPublicMessage, postPublicMessage} from "../../../api/publicMessageApi";
import {BootstrapVariantDropdownOptions, EBootstrapVariant, PublicMessage} from "../../../types/PublicMessage";
import {Alert, Button, Card, Form as BSForm, Row, Spinner} from "react-bootstrap";
import {
  StyledInputColumn,
  TitleRow,
  TopPaddedRow
} from "../../../components/util/form-components/standardFormikInputLayout";
import {Form, Formik} from "formik";
import Input from "../../../components/util/form-components/formik-inputs/Input/Input";
import {propertyOf} from "../../../util";
import {isNullOrWhitespace} from "../../../util/string";
import Select from "react-select";
import {PublicMessageAlert} from "../../../components/util/PublicMessageAlert";

export function MessageManagement (props: {}) {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState({message: '', bootstrapVariant: EBootstrapVariant.warning})

  useMount(async () => {
    try {
      const response = await getPublicMessage();
      if (!isNullOrWhitespace(response?.message)) setMessage(response!);
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load message'}));
    }
    setLoading(false);
  });

  const processSubmit = async (e: FormEvent<HTMLFormElement>, association: PublicMessage) => {
    setIsSubmitting(true);
    setErrorMessage('');
    e.persist();
    e.preventDefault();
    try {
      const response = await postPublicMessage(association);
    } catch (e: AxiosError | any) {
      setErrorMessage(getErrorResponseMessage(e));
    }
    setIsSubmitting(false);
  };

  const renderButton = () => {
    return (
      <>
        {!isSubmitting ?
          <Button variant={'success'} type={'submit'}>
            Submit
          </Button> :
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
        }
      </>
    );
  };

  const renderContent = () => (
    <div className={'fill-col'} style={{height: '100%', justifyContent: 'center', alignItems: 'center'}}>
      <Card style={{minWidth: '50%'}}>
        <Card.Body style={{width: '100%'}}>
          <Row>
            <Formik initialValues={{...message}} onSubmit={() => undefined}>
              {({values, setFieldValue}) => (
                <>
                  <TitleRow label={'Edit Order Alert'} centerTitle={true} style={{display: 'flex', flexDirection: 'column', flex: '1'}}>
                    <Form onSubmit={(e) => processSubmit(e, values)}>
                      <TopPaddedRow>
                        <StyledInputColumn label={'Message'}>
                          <Input name={propertyOf<PublicMessage>('message')} type={'textarea'} rows={8}/>
                        </StyledInputColumn>
                      </TopPaddedRow>
                      <TopPaddedRow>
                        <StyledInputColumn label={'Variant'}>
                          <Select
                            defaultValue={BootstrapVariantDropdownOptions.find(o => o.value === values.bootstrapVariant) ?? {value: EBootstrapVariant.warning, label: 'Warning'}}
                            options={BootstrapVariantDropdownOptions}
                            isClearable={false}
                            onChange={(newValue) => setFieldValue(propertyOf<PublicMessage>('bootstrapVariant'), newValue?.value)}
                            styles={{
                              menuPortal: base => ({ ...base, zIndex: 3000, overflowY: 'visible' }),
                              menu: base => ({...base, width: 'max-content', minWidth: '100%'})
                            }}
                            menuPortalTarget={document.querySelector('body')}
                            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                          />
                        </StyledInputColumn>
                      </TopPaddedRow>
                      <TopPaddedRow>
                        <BSForm.Group className={'modal-buttons'}>
                          {renderButton()}
                        </BSForm.Group>
                      </TopPaddedRow>
                    </Form>
                  </TitleRow>
                  <TitleRow label={'Preview'} centerTitle={true} style={{display: 'flex', flexDirection: 'column', flex: '0.8'}}>
                    <div className={'fill-col'} style={{marginTop: '1rem', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                      <PublicMessageAlert message={values} styles={{textAlign: 'center', width: '100%'}}/>
                    </div>
                  </TitleRow>
                </>
              )}
            </Formik>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );

  return loading ?  <CenteredSpinner/> : (errorMessage ? <CenteredErrorMessage message={errorMessage} /> : renderContent());
}
