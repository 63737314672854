import React, {useCallback, useRef, useState} from 'react';
import {connect} from 'react-redux';
import gridStyles from '../../../../GridStyles.module.scss';
import {bindActionCreators, Dispatch} from 'redux';
import {ConfirmationDialog} from '../../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import IconButton from '../../../../../components/util/widgets/IconButton/IconButton';
import {Button, Col, Row} from 'react-bootstrap';
import BootstrapTable, {BootstrapTableProps, ColumnDescription, SearchProps} from 'react-bootstrap-table-next';
// @ts-ignore
import ToolkitProvider, {ColumnToggle, Search, ToggleListProps} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {Navigate, useSearchParams} from 'react-router-dom';
import {CommonState} from '../../../../../redux';
import {Item, itemStore} from '../../../../../redux/entities/inventory/item';
import {propertyOf} from '../../../../../util';
import ItemModal from './ItemModal/ItemModal';
import CategoriesModal from './CategoriesModal/CategoriesModal';
import {categoryStore} from '../../../../../redux/entities/inventory/category';
import {AppTheme, noProfilePicture} from '../../../../../appTheme';
import ItemPicture from '../../../../../components/util/ItemPicture/ItemPicture';
// @ts-ignore @formatter:off
import paginationFactory, {PaginationProvider, PaginationListStandalone, PaginationTotalStandalone, SizePerPageDropdownStandalone} from 'react-bootstrap-table2-paginator';
import {isNullOrWhitespace} from '../../../../../util/string';
import useStandardPrint from '../../../../../hooks/useStandardPrint/useStandardPrint';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function InventoryGrid(props: Props) {
  const {inventory, getItemById, getCategoryById, actions: {archiveItem}} = props;
  const { SearchBar } = Search;
  const {ToggleList} = ColumnToggle;
  const [isDeleting, setIsDeleting] = useState('');
  const [isActivating, setIsActivating] = useState('');
  const [editingItemData, setEditingItemData] = useState<{editing: boolean; id: string}>({editing: false, id: ''});
  const [editingCategories, setEditingCategories] = useState(false);
  const [showColumnToggleFilters, setShowColumnToggleFilters] = useState(false);
  const [searchParams] = useSearchParams();
  const [redirectUrl, setRedirectUrl] = useState('');
  const printRef = useRef(null);
  const handleprint = useStandardPrint(printRef);

  const getFieldName = propertyOf<Item>;

  const renderRedirect = () => {
    if (redirectUrl.length !== 0) {
      return <Navigate to={redirectUrl} />;
    }
    return null;
  };

  const onColumnToggle = (col: ColumnDescription<Item>) => {
    const updatedTableColumns = columns.map((value) => value.dataField === col.dataField ? {...value, hidden: !col.hidden} : value);
    const visibleRecordInfo = updatedTableColumns.filter((value) => (!value.hidden || false) && !col.isDummyField).length > 1;
    setColumns(visibleRecordInfo ? updatedTableColumns : columns);
  };

  const FilteredToggleList = ({toggles}: {toggles: boolean[]}) => (
    <div className={gridStyles['filters-button-group']}>
      {
        columns
          .filter(col => !(col.isDummyField || col.dataField === (getFieldName('id'))))
          .map(column => ({...column, toggle: toggles[column.dataField]}))
          .map(column => (
            <Button
              variant={column.toggle ? 'success' : 'secondary'}
              key={ column.dataField }
              onClick={ () => onColumnToggle(column) }
            >
              {column.text === '' ? 'Image' : column.text}
            </Button>
          ))
      }
    </div>
  );

  const actionsFormatter = useCallback((cell: any, item: Item) => {
    return (
      <div className={gridStyles['table-actions-wrapper']}>
        <div>
          <IconButton
            icon={'pen'}
            styles={{color: AppTheme.colors.logoBlue}}
            iconToolTipText={'Edit'}
            onClick={() => setEditingItemData({editing: true, id: item.id})}
          />
        </div>
        <div>
          <IconButton
            icon={'trash-alt'}
            styles={{color: AppTheme.colors.danger, marginLeft: '.5rem'}}
            iconToolTipText={'Delete'}
            onClick={() => setIsDeleting(item.id)}
          />
        </div>
      </div>
    );
  }, []);

  const pictureFormatter = (cell: any, item: Item) => {
    if (isNullOrWhitespace(item.imagePath)) return null;
    return (
      <ItemPicture itemId={item.id} editable={false} style={{display: 'block', height: '2rem', width: '2rem', borderRadius: '50%'}}/>
    );
  };

  const [columns, setColumns] = useState<ColumnDescription<Item>[]>([
    {
      dataField: getFieldName('id'),
      text: 'ID',
      sort: true,
      searchable: false,
      hidden: true
    },
    {
      dataField: 'imagePath',
      text: '',
      sort: false,
      searchable: false,
      hidden: true,
      formatter: pictureFormatter,
      style: {width: '1px'}
    },
    {
      dataField: getFieldName('name'),
      text: 'Name',
      sort: true,
      searchable: true
    },
    {
      dataField: getFieldName('quantity'),
      text: 'Quantity',
      sort: true,
      hidden: true
    },
    {
      dataField: getFieldName('restrictOrderSize'),
      text: 'Restrict Order Size',
      sort: true,
      hidden: true,
      formatter: (cell, item) => item.restrictOrderSize ? 'True' : 'False'
    },
    {
      dataField: getFieldName('maxOrderSize'),
      text: 'Max Order Size',
      sort: true,
      hidden: true
    },
    {
      dataField: 'category.name',
      text: 'Category',
      sort: true,
      formatter: (cell, row) => getCategoryById(row.categoryId)?.name
    },
    {
      dataField: getFieldName('itemOptions'),
      text: 'Shown Options',
      sort: false,
      formatter: (cell, row) => row.itemOptions.map(itemOption => `${itemOption.name}\n`)
    },
    {
      dataField: 'actionsColumn',
      text: 'Actions',
      searchable: false,
      formatter: (cell, row) => actionsFormatter(cell, row),
      headerStyle: () => {
        return { width: 0};
      },
      style: () => {
        return { width: 0};
      }
    }
  ]);

  return (
    <PaginationProvider
      pagination={ paginationFactory({
        custom: true,
        totalSize: inventory.length,
        sizePerPage: 20,
        sizePerPageList: [ {
          text: '5 rows', value: 5
        }, {
          text: '10 rows', value: 10
        }, {
          text: '20 rows', value: 20
        }, {
          text: '30 rows', value: 30
        }, {
          text: '50 rows', value: 50
        }, {
          text: '100 rows', value: 100
        }, {
          text: 'All rows', value: inventory.length
        } ]
      }) }
      keyField='id'
      columns={ columns }
      data={ inventory }
    >
      {({paginationProps, paginationTableProps}: any) => (
        <ToolkitProvider
          keyField='id'
          data={inventory}
          columns={columns}
          search={{
            searchFormatted: true
          }}
          columnToggle={true}
        >
          {(tableProps: {
            baseProps: JSX.IntrinsicAttributes & JSX.IntrinsicClassAttributes<BootstrapTable<Item, number>> & Readonly<BootstrapTableProps>;
            searchProps: SearchProps<Item>;
            columnToggleProps: ToggleListProps;
          }) => (
            <>
              <div className={gridStyles['button-groups-container']}>
                <div className={gridStyles['button-group']}>
                  <Button
                    onClick={() => setEditingItemData({editing: true, id: ''})}
                    style={{backgroundColor: AppTheme.colors.logoBlue}}
                  >
                    Add Item
                  </Button>
                  <Button
                    onClick={() => setEditingCategories(true)}
                    style={{backgroundColor: AppTheme.colors.logoBlue}}
                  >
                    Edit Categories
                  </Button>
                  <Button onClick={handleprint} style={{backgroundColor: AppTheme.colors.logoBlue}}>
                    Print Table
                  </Button>
                  <Button style={{backgroundColor: AppTheme.colors.logoBlue}} onClick={() => setShowColumnToggleFilters(!showColumnToggleFilters)}>
                    {showColumnToggleFilters ? 'Hide' : 'Show'} Column Filters
                  </Button>
                </div>
                {showColumnToggleFilters ?
                  <div className={gridStyles['button-group']}>
                    <FilteredToggleList {...tableProps.columnToggleProps}/>
                  </div>
                  : null
                }
              </div>
              <Col style={{padding: '0'}}>
                <Row>
                  {renderRedirect()}
                  <div className={gridStyles['search-bar']}>
                    <SearchBar {...tableProps.searchProps} className={gridStyles['search-bar']}/>
                  </div>
                  <PaginationListStandalone { ...paginationProps } className={gridStyles['pagination-list']}/>
                  <SizePerPageDropdownStandalone{ ...paginationProps } className={gridStyles['page-dropdown']}/>
                  <div className={gridStyles['pagination-total']}>
                    <PaginationTotalStandalone { ...paginationProps }/>
                  </div>
                </Row>
                <div ref={printRef}>
                  <BootstrapTable
                    wrapperClasses={gridStyles['responsive-table-wrapper']}
                    classes={gridStyles['table-auto']}
                    rowStyle={{height: '100%'}}
                    {...tableProps.baseProps}
                    {...paginationTableProps}
                    striped={true}
                    headerClasses={gridStyles['print-header']}
                    bodyClasses={gridStyles['print-body']}
                    headerWrapperClasses={gridStyles['print-header']}
                  />
                </div>
                {isDeleting !== '' && (
                  <ConfirmationDialog
                    onAccept={async () => {
                      await archiveItem(isDeleting);
                      setIsDeleting('');
                    }}
                    onDecline={async () => { setIsDeleting(''); }}
                    open={isDeleting !== ''}
                    prompt={`Are you sure you want to remove the item "${getItemById(isDeleting)?.name}?"`}
                    positiveText='Yes'
                    negativeText='No'
                    positiveVariant='success'
                    negativeVariant='danger'
                  />
                )}
                {isActivating !== '' && (
                  <ConfirmationDialog
                    onAccept={async () => {
                      setIsActivating('');
                    }}
                    onDecline={async () => { setIsActivating(''); }}
                    open={isActivating !== ''}
                    prompt='Are you sure you want to activate this item?'
                    positiveText='Yes'
                    negativeText='No'
                    positiveVariant='success'
                    negativeVariant='danger'
                  />
                )}
                {editingItemData.editing ? <ItemModal
                  show={editingItemData.editing}
                  existing={getItemById(editingItemData.id)}
                  onSubmit={() => setEditingItemData({editing: false, id: ''})}
                  onCancel={() => setEditingItemData({editing: false, id: ''})}
                /> : null}
                {editingCategories ? <CategoriesModal
                  show={editingCategories}
                  onCancel={() => setEditingCategories(false)}
                  onSubmit={() => setEditingCategories(false)}
                /> : null}
              </Col>
            </>
          )}
        </ToolkitProvider>
        )}
    </PaginationProvider>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    archiveItem: itemStore.actions.archiveItem
  }, dispatch)});
const mapStateToProps = (state: CommonState) => ({
  inventory: itemStore.selectors.unarchivedItems(state),
  getItemById: itemStore.selectors.getById(state),
  getCategoryById: categoryStore.selectors.getById(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(InventoryGrid);
