import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../../../utils';
import {createStandardSelectors, getEntities, selector} from '../../../selectors';
import {combineReducers} from 'redux';

export interface OrderItemOption {
  id: string;
  orderItemId: string;
  itemOptionId: string;
  quantity: number;
}

export interface OrderItem {
  id: string;
  orderId: string;
  itemId: string;
  itemQuantity: number;
  extraComment: string;
  itemOptions: OrderItemOption[];
}

const actions = createStandardActions(placeholder<OrderItem>(), 'ORDER_ITEM/SET', 'ORDER_ITEM/SAVE');
const selectors = createStandardSelectors(placeholder<OrderItem>(), s => getEntities(s).orderItems);
export type OrderItemActions = GetActions<typeof actions>;
export const orderItems = combineReducers({items: standardItemsReducer<OrderItem, OrderItemActions>(actions)});
export const orderItemStore = readonly({
  selectors: {
    ...selectors
  },
  actions: {
    ...actions
  }
});
