import {Accordion, Button, Col, Form as BSForm, Modal, Row, Spinner} from 'react-bootstrap';
import {EOrderStatus, Order, orderStore} from '../../../../../redux/entities/inventory/order';
import {CommonState} from '../../../../../redux';
import {bindActionCreators, Dispatch} from 'redux';
import {itemStore} from '../../../../../redux/entities/inventory/item';
import {convertToDropDownOptions} from '../../../../../util/form';
import {Category, categoryStore} from '../../../../../redux/entities/inventory/category';
import {propertyOf} from '../../../../../util';
import {connect} from 'react-redux';
import {SubmitProps} from '../../../../../types';
import React, {CSSProperties, FormEvent, PropsWithChildren, useRef, useState} from 'react';
import {Form, Formik} from 'formik';
import {
  TitleRow,
  TopPaddedRow,
  TopPaddedRowSeparator
} from '../../../../../components/util/form-components/standardFormikInputLayout';
import {AxiosError} from 'axios';
import {getErrorResponseMessage} from '../../../../../util/http';
import {AppTheme} from '../../../../../appTheme';
import {OrderItem} from '../../../../../redux/entities/inventory/joining/orderItem';
import {formatIfPossibleUsPhone, isNullOrWhitespace} from '../../../../../util/string';
import Select from 'react-select';
import OrderReview from '../../../../public/HealthProgramOrder/review/OrderReview';
import styles from '../../../../public/HealthProgramOrder/HealthProgramOrder.module.scss';
import IconButton from '../../../../../components/util/widgets/IconButton/IconButton';
import useStandardPrint from '../../../../../hooks/useStandardPrint/useStandardPrint';
import OrderItemList from '../../../../../components/util/OrderItemList/OrderItemList';

const getFieldName = propertyOf<Order>;

const orderStatusDropdownOptions = [
  {value: EOrderStatus.Unapproved, label: 'Unapproved'},
  {value: EOrderStatus.Approved, label: 'Approved'},
  {value: EOrderStatus.Shipped, label: 'Shipped'},
  {value: EOrderStatus.Cancelled, label: 'Cancelled'}
];

const getOrderStatus = (val: EOrderStatus) => orderStatusDropdownOptions.find(o => o.value === val) ?? orderStatusDropdownOptions[0];

interface ItemForm {
  id: string;
  orderItems: OrderItem[];
}

export interface OrderUpdate extends ItemForm {
  orderStatus: EOrderStatus;
}

type Props = SubmitProps & {
  show: boolean;
  order: Order;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const OrderModal = (props: Props) => {
  const {show, onCancel, onSubmit, order, getItemById, actions: {upsertOrder}} = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const itemFormRef = useRef<HTMLFormElement | null>(null);
  const [currentOrderStatus, setCurrentOrderStatus] = useState(getOrderStatus(order.orderStatus));
  const printRef = useRef(null);
  const handlePrint = useStandardPrint(printRef);

  const processSubmit = async (e: FormEvent<HTMLFormElement>, association: ItemForm) => {
    setIsSubmitting(true);
    setErrorMessage('');
    e.persist();
    e.preventDefault();
    try {
      const request: OrderUpdate = {...association, id: order.id, orderStatus: currentOrderStatus.value};
      await upsertOrder(request);
      await onSubmit();
    } catch (e: AxiosError | any) {
      setErrorMessage(getErrorResponseMessage(e));
    }
    setIsSubmitting(false);
  };

  const renderButtons = () => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={onCancel} variant={'danger'} className={'modal-close-button'}>
            {'Cancel'}
          </Button>
        }
        {!isSubmitting ? <Button variant={'success'} type={'submit'}>
          Submit
        </Button> : null}
      </>
    );
  };

  return (
    <Modal show={show} centered={true} size={'xl'}>
      <Modal.Body>
        <Formik<Order> initialValues={order} onSubmit={() =>  undefined}>
          {({values}) => (
            <Form onSubmit={(e) => processSubmit(e, values)}>
              <Modal.Title>
                <Row style={{width: '100%'}}>
                View & Edit Order
                  <div style={{display: 'flex', marginLeft: 'auto', marginRight: '15px', fontWeight: 'initial', fontSize: '1.2rem'}}>
                    <IconButton icon={'print'} size={'2x'} onClick={handlePrint} color={AppTheme.colors.logoBlue} styles={{marginRight: '24px'}}/>
                    <Select
                      styles={{
                        control: (base) => ({...base, border: '1px solid', borderColor: AppTheme.colors.logoBlue})
                      }}
                      menuPlacement={'auto'}
                      value={currentOrderStatus}
                      options={orderStatusDropdownOptions}
                      isClearable={false}
                      isOptionSelected={(val) => val === currentOrderStatus}
                      isSearchable={false}
                      onChange={(newValue) => setCurrentOrderStatus(newValue ?? getOrderStatus(order.orderStatus))}
                    />
                  </div>
                </Row>
              </Modal.Title>
              <Col style={{paddingTop: '1rem'}}>
                <Accordion alwaysOpen={true} defaultActiveKey={[getFieldName('consumerInformation'), getFieldName('orderItems')]}>
                  <Accordion.Item eventKey={getFieldName('consumerInformation')} style={{borderRadius: 0, borderTop: `1px solid ${AppTheme.colors.logoBlue}`}}>
                    <Accordion.Header><h5>Requester Information</h5></Accordion.Header>
                    <Accordion.Body style={{paddingLeft: '1rem'}}>
                      <Row>
                        <Col style={{paddingLeft: '0', display: 'flex', flexDirection: 'column', flex: '0.5'}}>
                          <TitleRow label={'Contact Information'}>
                            <TopPaddedRowSeparator style={{paddingBottom: '0', paddingLeft: '15px'}}>
                              <div>{order.consumerInformation.name}</div>
                              <div>{order.consumerInformation.organization} - {order.consumerInformation.jobTitle}</div>
                              <div>{order.consumerInformation.emailAddress}</div>
                              <div>{formatIfPossibleUsPhone(order.consumerInformation.phoneNumber)}</div>
                            </TopPaddedRowSeparator>
                          </TitleRow>
                          <TitleRow label={'Organization Address'}>
                            <TopPaddedRowSeparator style={{paddingBottom: '0', paddingLeft: '15px'}}>
                            <div>
                              {order.consumerInformation.streetAddress}
                              {isNullOrWhitespace(order.consumerInformation.streetAddress) ? '' : `, ${order.consumerInformation.streetAddressLineTwo}`}
                            </div>
                            <div>{`${order.consumerInformation.city}, ${order.consumerInformation.state} ${order.consumerInformation.zip}`}</div>
                            </TopPaddedRowSeparator>
                          </TitleRow>
                        </Col>
                        <Col style={{padding: '0', display: 'flex', flexDirection: 'column', flex: '1'}}>
                          <TitleRow label={'Order Beneficiaries'} style={{padding: '0'}}>
                            <TopPaddedRowSeparator style={{paddingBottom: '0', paddingLeft: '15px'}}>
                              <InfoColumn label={'Who will benefit from this donation?'}>
                                {order.consumerInformation.beneficiaries}
                              </InfoColumn>
                              <InfoColumn label={'Please tell us how this donation will help the organization and/or the recipients.'}>
                                {order.consumerInformation.beneficiariesDescription}
                              </InfoColumn>
                              <InfoColumn label={'Approximately how many people will benefit from this donation?'}>
                                {order.consumerInformation.beneficiariesQuantity}
                              </InfoColumn>
                              <Row>
                                <Col style={{padding: 0, marginRight: '1rem'}}>
                                  <TopPaddedRowSeparator>
                                    <InfoColumn label={'Do you have any extra questions or concerns?'}>
                                      {order.consumerInformation.comment}
                                    </InfoColumn>
                                    <InfoColumn label={'What department or outreach will be serviced?'}>
                                      {order.consumerInformation.departmentOrOutreach}
                                    </InfoColumn>
                                  </TopPaddedRowSeparator>
                                </Col>
                                <div style={{marginLeft: 'auto', marginTop: '15px', border: `1px solid ${AppTheme.colors.publicPrimary}`, borderRadius: '5px'}}>
                                  <img alt={'signature'} src={order.consumerInformation.signaturePath}/>
                                </div>
                              </Row>
                            </TopPaddedRowSeparator>
                          </TitleRow>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item style={{borderTop: `1px solid ${AppTheme.colors.logoBlue}`}} eventKey={getFieldName('orderItems')}>
                    <Accordion.Header><h5>Item Selection</h5></Accordion.Header>
                    <Accordion.Body>
                      <OrderItemList orderItems={order.orderItems} separateByCategory={true}/>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <TopPaddedRow>
                  <BSForm.Group className={'modal-buttons'}>
                    {renderButtons()}
                  </BSForm.Group>
                </TopPaddedRow>
              </Col>
              <div ref={printRef} className={styles['print']}>
                <OrderReview
                  order={order}
                  canvasURL={() => order.consumerInformation.signaturePath}
                  errors={{}}
                  showDates={true}
                  separateByCategory={true}
                />
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

const InfoColumn = (props: {label: string; style?: CSSProperties} & PropsWithChildren) => (
  <Col style={{padding: '0', ...props.style}}>
    <div><h6>{props.label}</h6></div>
    {props.children}
  </Col>
);

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertOrder: orderStore.actions.upsert,
    updateOrderStatus: orderStore.actions.updateOrderStatus
  }, dispatch)});
const mapStateToProps = (state: CommonState) => ({
  inventory: itemStore.selectors.getAsArray(state),
  getItemById: itemStore.selectors.getById(state),
  categoryDropDownOptions: convertToDropDownOptions(categoryStore.selectors.getAsArray(state), propertyOf<Category>('name'))
});
export default connect(mapStateToProps, mapDispatchToProps)(OrderModal);
