import {useField, useFormikContext} from 'formik';
import React, {ForwardedRef, forwardRef} from 'react';
import {RedErrorMessage} from '../../RedErrorMessage/RedErrorMessage';
import PhoneInput from 'react-phone-number-input';
// tslint:disable-next-line:no-import-side-effect
import 'react-phone-number-input/style.css';
import {Form} from 'react-bootstrap';

interface Props {
  name: string;
  disabled?: boolean;
}

const BootstrapInputField = forwardRef((props: Props, ref: ForwardedRef<HTMLInputElement>) => {
  return (
    <Form.Control
      {...props}
      ref={ref}
    />

  );
});

const PhoneInputField = (props: Props) => {
  const {handleBlur, handleChange, setFieldValue} = useFormikContext<any>();
  const [field, {value, error, touched}] = useField(props.name);

  return (
    <>
      <PhoneInput
        {...field}
        disabled={props.disabled}
        inputstyle={{width: '100%'}}
        defaultCountry={'US'}
        country={'US'}
        inputprops={{name: props.name}}
        onChange={(phoneNumber)=> setFieldValue(field.name, phoneNumber)}
        onBlur={handleBlur}
        value={field.value}
        inputComponent={BootstrapInputField}
      />
      <RedErrorMessage name={props.name}/>
    </>
  );
};

export default PhoneInputField;
