import {CSSProperties} from 'react';
import {nodeListToArray} from './util/array';
import Logo from './assets/images/sos-logo.png';
import WhiteLogo from './assets/images/sos-white-logo.png';
import GreenLogo from './assets/images/sos-green-logo.png';
import NoProfilePicture from './assets/images/no-profile-picture.png';
import RbswLogo from './assets/images/rbsw-logo.png';
import RbswLogoSosVariant from './assets/images/rbsw-logo-sos-theme.png';

const darkMode =  process.env?.REACT_APP_DARK_MODE === 'true';

const colors = {
  primaryTextColor: darkMode ? '#fff' : 'black',
  publicPrimary: '#83d0e4',
  privatePrimary: '#05A762',
  logoGreen: '#05A762',
  logoBlue: '#2256a5',
  gray: '#6c757d',
  gray400: '#ced4da',
  darkGray: '#6c757d',
  background: darkMode ? '#222222' : '#fff',
  offsetBackground: darkMode ? '#202020' : '#f9f9f9',
  largerOffsetBackground: darkMode ? '#353535' : '#f1f1f1',
  warning: '#f8c839',
  success: '#5fb173',
  lighterGreen: '#defade',
  danger: '#c45b65'
} as const;

export const AppTheme = {
  colors: colors,
  styles: {
    offsetBackground: !darkMode ? { background: colors.offsetBackground} : {} as CSSProperties,
    background: !darkMode ? {background: colors.background} : {} as CSSProperties,
    largerOffsetBackground: {background: colors.largerOffsetBackground} as CSSProperties,
    primaryTextColor: {color: colors.primaryTextColor} as CSSProperties
  },
  darkMode: darkMode
};

export const getLogoLink = () => Logo;
export const getWhiteLogoLink = () => WhiteLogo;
export const getGreenLogoLink = () => GreenLogo;
export const noProfilePicture = () => NoProfilePicture;
export const rbswLogo = () => RbswLogo;
export const rbswLogoSosVariant = () => RbswLogoSosVariant;

function createLink(href: string) {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  return link;
}

export function configureTheme() {
  if (!darkMode) {
    return null;
  }
  document.body.classList.add('dark-mode');
  document.body.dataset['theme'] = 'dark';

  const link = createLink(process.env.PUBLIC_URL + '/bootstrap/css/dark-mode-bootstrap.min.css');
  document.head.appendChild(link);
  const stdLink = (nodeListToArray(document.head.children) as HTMLLinkElement[]).filter(c => (c.href ?? '').indexOf('css/bootstrap.min.css') !== -1).pop()!;
  document.head.removeChild(stdLink);
}
