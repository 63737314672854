import React, {CSSProperties, HTMLAttributes, PropsWithChildren} from 'react';
import {Col, Row} from 'react-bootstrap';
import {InputRow, InputRowProps} from '../InputRow';
import {InputColumn, InputColumnProps} from '../InputColumn';
import CheckBoxInput, {CheckboxInputProps} from '../formik-inputs/CheckBoxInput/CheckBoxInput';
import {BsPrefixProps} from 'react-bootstrap/helpers';

type StandardTopPaddedRowProps = {
  style?: CSSProperties;
} & HTMLAttributes<HTMLDivElement> & BsPrefixProps & PropsWithChildren;

export function TopPaddedRow(props: StandardTopPaddedRowProps) {
  return (
    <Row {...props} style={{paddingTop: '10px', paddingBottom: '10px', ...props.style}}>
      {props.children}
    </Row>
  );
}

export function TopPaddedRowSeparator(props: StandardTopPaddedRowProps & PropsWithChildren) {
  const {children, ...rest} = props;
  if (Array.isArray(children))
    return (
      <>
        {children.map((child, index) => <TopPaddedRow key={index} {...rest}>{child}</TopPaddedRow>)}
      </>
    );
  return <TopPaddedRow {...rest}>{children}</TopPaddedRow>;
}

export function TitleRow(props: InputRowProps & {
  header?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  titleStyle?: CSSProperties;
  centerTitle?: boolean;
}) {
  const centerStyle: CSSProperties = props.centerTitle ? {justifyContent: 'center', textAlign: 'center'} : {};

  const renderTitle = () => {
    switch (props.header) {
      case 'h1': return <h1>{props.label}</h1>;
      case 'h2': return <h2>{props.label}</h2>;
      case 'h3': return <h3>{props.label}</h3>;
      case 'h4': return <h4>{props.label}</h4>;
      case 'h5': return <h5>{props.label}</h5>;
      case 'h6': return <h6>{props.label}</h6>;
      default: return <h3>{props.label}</h3>;
    }
  };

  return (
    <Col style={props.style}>
      <Row style={{...centerStyle, paddingTop: '10px', ...props.titleStyle}}>
        {renderTitle()}
      </Row>
      <Row>
        <Col style={{padding: '0', paddingBottom: '30px'}}>
          {props.children}
        </Col>
      </Row>
    </Col>
  );
}

export function TopPaddedInputRow(props: InputRowProps) {
  return (
    <InputRow {...props} labelSize={props.labelSize ?? 2} columnSize={props.columnSize ?? 10} style={{paddingTop: '10px', ...props.style}}>
      {props.children}
    </InputRow>
  );
}

export function StyledInputColumn(props: InputColumnProps) {
  return (
    <InputColumn {...props} columnSize={props.columnSize ?? 12} style={{paddingLeft: '0', ...props.style}}>
      {props.children}
    </InputColumn>
  );
}

export function StyledCheckboxInput(props: CheckboxInputProps) {
  return (
    <CheckBoxInput {...props} style={{paddingTop: '5px', paddingBottom: '15px', ...props.style}}/>
  );
}

export function StyledRadioInput(props: CheckboxInputProps) {
  return (
    <CheckBoxInput
      singleRadioAllowed={true}
      type={'radio'}
      style={{paddingTop: '5px', paddingBottom: '15px', marginRight: '15px', ...props.style}}
      {...props}
    />
  );
}
