import styles from './UserInfo.module.scss';
import React from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {CommonState} from '../../../redux';
import {userStore} from '../../../redux/entities/user';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {RoutePaths} from '../../../router/RoutePaths';
import IconButton from '../../util/widgets/IconButton/IconButton';
import ProfilePicture from '../../../pages/private/Profile/components/ProfilePicture';
import {AppTheme, noProfilePicture} from '../../../appTheme';
import {isNullOrWhitespace} from '../../../util/string';
type Props = {} & ReturnType<typeof mapStateToProps>;

const UserInfo = (props: Props) => {
  const {currentUser} = props;
  return (
    <div className={styles['links-profile-wrapper']}>
      <div className={styles['profile-logout']}>
        <img
          style={{objectFit: 'contain', height: '3rem', width: '3rem', borderRadius: '50%', marginRight: '15px'}}
          src={(currentUser && !isNullOrWhitespace(currentUser?.profilePicturePath)) ? currentUser.profilePicturePath : noProfilePicture()}
          alt={'Profile picture'}
        />
        <div style={{display: 'flex', flexDirection: 'column', marginRight: '1rem', alignItems: 'flex-end'}}>
          <Link to={RoutePaths.profilePaths.View} style={{color: AppTheme.colors.logoBlue}}>
            <h5 style={{margin: 0}}>
              {currentUser?.name}
            </h5>
          </Link>
          <div style={{fontWeight: 'bold'}}>
            {currentUser?.role.roleName}
          </div>
        </div>
        <IconButton styles={{marginLeft: '1rem'}} icon='sign-out-alt' link={RoutePaths.authPaths.logout} size={'2x'} color={'#005A9C'}/>
      </div>
    </div>
  );

};


const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({}, dispatch)});
const mapStateToProps = (state: CommonState) => ({
  currentUser: userStore.selectors.getCurrentUser(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
