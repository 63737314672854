import {Navigate, Outlet} from 'react-router';
import React from 'react';
import {mapIsAdministratorToProps, mapIsAdministratorToPropsType} from '../../../redux/entities/user';
import {connect} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {RoutePaths} from '../../../router/RoutePaths';

type AdministratorRouteProps = mapIsAdministratorToPropsType & {
  children: JSX.Element;
};

function AdministratorRouteComponent({children, administrator}: AdministratorRouteProps) {
  const location = useLocation();
  return (
    administrator ? children : (
      <Navigate to={RoutePaths.authPaths.login} state={{ from: location }} replace={true}/>
    )
  );
}

export const AdministratorRoute = connect(mapIsAdministratorToProps)(AdministratorRouteComponent);
