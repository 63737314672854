import {ItemCategory} from './joining/itemCategory';
import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../../utils';
import {createStandardSelectors, getEntities, selector} from '../../selectors';
import {combineReducers} from 'redux';
import {isNullOrWhitespace} from '../../../util/string';
import {
  upsertCategories,
  UpsertCategoriesRequest,
  upsertCategory,
  upsertItem
} from '../../../api/inventory/inventoryApi';
import {CommonDispatch} from '../../';
import {Item, itemStore} from './item';
import {createCategoryTree} from '../../../hooks/useCategoryTree';

export interface Category {
  id: string;
  name: string;
  superCategoryId: string;
}

export interface CategoryTreeNode extends Category {
  children: CategoryTreeNode[];
}

const actions = createStandardActions(placeholder<Category>(), 'CATEGORY/SET', 'CATEGORY/SAVE');
const selectors = createStandardSelectors(placeholder<Category>(), s => getEntities(s).categories);
export type CategoryActions = GetActions<typeof actions>;
export const categories = combineReducers({items: standardItemsReducer<Category, CategoryActions>(actions)});
export const categoryStore = readonly({
  selectors: {
    ...selectors,
    topLevelCategories: selector(s => selectors.getAsArray(s).filter(c => isNullOrWhitespace(c.superCategoryId))),
    /*bottomLevelCategories: selector(s => selectors.getAsArray(s).filter(c => !c.subCategoryIds.length)),*/
    getSubcategories: selector(s => (category: Category) => selectors.getAsArray(s).filter(c => c.superCategoryId === category.id)),
    alphabeticallyOrganizedCategories: selector(s => [...selectors.getAsArray(s)].sort((a, b) => a.name.localeCompare(b.name))),
    asCategoryTree: selector(s => createCategoryTree(selectors.getAsArray(s))),
    removeEmptyCategories: (selector(s => {
      selectors.getAsArray(s).filter(c => itemStore.selectors.getCategoryItems(s)(c.id).length);
    }))
  },
  actions: {
    ...actions,
    upsert: (category: Category) => async (dispatch: CommonDispatch) => {
      const response = await upsertCategory(category);
      dispatch(actions.save(response));
    },
    upsertMany: (request: UpsertCategoriesRequest) => async (dispatch: CommonDispatch) => {
      const response = await upsertCategories(request);
      dispatch(actions.set(response));
    }
  }
});
