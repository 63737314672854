import React from 'react';
import {AppTheme, getGreenLogoLink, getWhiteLogoLink} from '../../../appTheme';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Sidebar} from 'react-pro-sidebar';
import styles from './SidebarPanel.module.scss';
import {MenuItemsForRole} from './MenuItemsForRole';
import {userStore} from '../../../redux/entities/user';
import {roleStore} from '../../../redux/entities/role';
import {CommonState} from '../../../redux';
import {Row} from 'react-bootstrap';

type Props = & ReturnType<typeof mapStateToProps>;


const SidebarPanel = (props: Props) => {
  const {currentUser} = props;
  return (
    <Sidebar customBreakPoint='1024px' backgroundColor={AppTheme.colors.logoBlue} color={'#ffffff'}>
      <Row style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
        <div style={{margin: '1rem 10px'}}>
          <img src={getGreenLogoLink()} alt='SOS Portal' className={styles['logo']}/>
        </div>
      </Row>
      {MenuItemsForRole(currentUser)}
    </Sidebar>
  );

};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({}, dispatch)});
const mapStateToProps = (state: CommonState) => ({
  currentUser: userStore.selectors.getCurrentUser(state),
  roleName: roleStore.selectors.getById(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarPanel);
