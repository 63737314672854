import {combineReducers} from 'redux';
import {UserActions, userPersistConfig, users} from './user';
import {persistReducer} from 'redux-persist';
import {RoleActions, roles} from './role';
import {ItemActions, items} from './inventory/item';
import {OrderItemActions, orderItems} from './inventory/joining/orderItem';
import {OrderActions, orders} from './inventory/order';
import {itemCategories, ItemCategoryActions} from './inventory/joining/itemCategory';
import {categories, CategoryActions} from './inventory/category';

export const entities = combineReducers({
  users: persistReducer(userPersistConfig, users) as unknown as typeof users,
  roles: roles,
  orders: orders,
  items: items,
  categories: categories,
  orderItems: orderItems,
  itemCategories: itemCategories
});

export type Entities  = ReturnType<typeof entities>;

export type EntitiesActions = UserActions | RoleActions | OrderActions | ItemActions | CategoryActions | OrderItemActions | ItemCategoryActions;
