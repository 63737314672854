import {CommonState} from './index';
import {Selector} from 'react-redux';
import {DropdownOption} from '../components/util/form-components/SearchableDropdown/SearchableDropdown';
import {createSelector} from 'reselect';
import memoize from 'lodash.memoize';
import {Dictionary, DropDownOptionRecord} from '../types';
import {convertToDropDownOptions} from '../util/form';

export const getEntities = (state: CommonState) => state.entities;

export const selector = <T>(func: (state: CommonState) => T) => func;

export type StandardState<T> = {
  items: Dictionary<T>;
};

export function createStandardSelectors<T, S extends StandardState<T>>(i: T, getStateParam: (s: CommonState) => S): StandardSelectors<T, S> {
  const getState = createSelector(getStateParam, s => s);
  const getItems = createSelector(getState, s => s.items);
  const getAsArray = createSelector(getItems, items => Object.values<T>(items));
  const getOptions = createSelector(getAsArray, arr => convertToDropDownOptions(arr as DropDownOptionRecord[]));
  const getById = createSelector(getItems, items => memoize((id: string) => items[id as any]));
  return {
    getState,
    getItems,
    getAsArray,
    getOptions,
    getById
  } as const;
}

export interface StandardSelectors<T, S extends StandardState<T>> {
  getState: Selector<CommonState, S>;
  getItems: Selector<CommonState, Dictionary<T>>;
  getAsArray: Selector<CommonState, T[]>;
  getOptions: Selector<CommonState, DropdownOption<number>[]>;
  getById: Selector<CommonState, (id: string) => T>;
}

export function createDictionaryReducerSelectors<T, S extends Dictionary<T>>(i: T, getStateParam: (s: CommonState) => S): DictionaryReducerState<T, S> {
  const getState = createSelector(getStateParam, s => s);
  const getAsArray = createSelector(getState, dict => Object.values<T>(dict));
  const getById = createSelector(getState, dict => memoize((id: string) => dict[id as any]));
  return {getState, getAsArray, getById} as const;
}

export interface DictionaryReducerState<T, S extends Dictionary<T>> {
  getState: Selector<CommonState, S>;
  getAsArray: Selector<CommonState, T[]>;
  getById: Selector<CommonState, (id: string) => T>;
}

export function createStandardSelector<T, S extends T>(i: T, getStateParam: (s: CommonState) => S): StandardSelector<T, S> {
  return { getState: createSelector(getStateParam, s => s) };
}

export interface StandardSelector<T, S extends T> {
  getState: Selector<CommonState, S>;
}
