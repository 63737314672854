export const propertyOf = <T>(name: keyof T ) => name as string;

export function combineCssClasses(...arr: (any|string|undefined|null)[]): string {
  return arr.filter((val) => !!val).join(' ');
}

export function nullable<T>(value: T | null): T | undefined {
  if (value) {
    return value;
  }
  return undefined;
}

export const toMutable = <T>(val: readonly T[]) => val as T[];

export function delay(timeout: number): Promise<void> {
  return new Promise(resolve => setTimeout(() => resolve(), timeout));
}

export const ipv4AddressRegex = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/;

export function getObjectValues<T extends Record<keyof T, any>>(obj: T): T[keyof T][] {
  return Object.keys(obj).map<T[keyof T]>(key => obj[key as keyof T]);
}

export function getEnumValues<T extends Record<keyof T, any>>(obj: T): string[] {
  return getObjectValues(obj);
}
