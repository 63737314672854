import {User} from '../../../redux/entities/user';
import {Link} from 'react-router-dom';
import {RoutePaths} from '../../../router/RoutePaths';
import AdministratorComponent from '../../util/AdministratorComponent/AdministratorComponent';
import React from 'react';
import {Menu, MenuItem, SubMenu} from 'react-pro-sidebar';
import {AppTheme} from '../../../appTheme';

export const MenuItemsForRole = (currentUser: User | null) => {
  return (
    <Menu menuItemStyles={{button: {color: '#FFFFFF', backgroundColor: AppTheme.colors.logoBlue, '&:hover': {backgroundColor: '#00AFFF'}}}}>
      <AdministratorComponent>
        <MenuItem component={<Link to={RoutePaths.privatePaths.configurationPaths.inventoryManagement}/>}> Inventory </MenuItem>
      </AdministratorComponent>
      <MenuItem component={<Link to={RoutePaths.privatePaths.configurationPaths.orderManagement}/>}> Orders </MenuItem>
      <AdministratorComponent>
        <MenuItem component={<Link to={RoutePaths.privatePaths.configurationPaths.userManagement}/>}> Users </MenuItem>
      </AdministratorComponent>
      <AdministratorComponent>
        <MenuItem component={<Link to={RoutePaths.privatePaths.configurationPaths.messageManagement}/>}> Order Alert </MenuItem>
      </AdministratorComponent>
    </Menu>
  );
};
