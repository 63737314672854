import React from 'react';
import {Form} from 'react-bootstrap';
import IconButton, {IconButtonProps} from '../IconButton/IconButton';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

interface InputIconButtonProps {
  inputComponent: any;
  iconButtonProps?: IconButtonProps;
}

export const InputIconButton = (props: InputIconButtonProps) => (
  <Form.Group style={{display: 'flex', alignItems: 'center'}}>
    <div style={{flexShrink: 1, flexGrow: 1}}>
    {props.inputComponent}
    </div>
    {!!props.iconButtonProps ?
      <IconButton {...props.iconButtonProps}/>
      : null}
  </Form.Group>
);
