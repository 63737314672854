import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {CenteredSpinner} from '../../../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {handleAxiosError} from '../../../../util/http';
import {loadUserManagementData} from '../../../../redux/stateResponses/userManagement';
import UserGrid from './components/UserGrid/UserGrid';
import {AxiosError} from 'axios';
import {useMount} from '../../../../hooks/useMount';
import {CommonState} from '../../../../redux';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function UserManagement(props: Props) {
  const {actions: {loadUserData}} = props;
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useMount(async () => {
    try {
      await loadUserData();
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load users'}));
    }
    setLoading(false);
  });

  return loading ?  <CenteredSpinner/> : (errorMessage ? <CenteredErrorMessage message={errorMessage} /> : <UserGrid/>);
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    loadUserData: loadUserManagementData
  }, dispatch)});
const mapStateToProps = (state: CommonState) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
