import {Col, Row} from 'react-bootstrap';
import React, {CSSProperties, ReactNode} from 'react';

interface Props {
  label: string | ReactNode;
  data: string | number | ReactNode;
  labelSize?: number;
  dataSize?: number;
  style?: CSSProperties;
  rowStyle?: CSSProperties;
}

const InformationLabel = (props: Props) => (
  <Row lg={12} style={props.rowStyle}>
    <Col lg={props.labelSize ?? (props.dataSize ? 12 - props.dataSize : 3)} style={{paddingTop: '1rem', margin: 0, ...props.style}}>
        {props.label}
    </Col>
    <Col lg={props.dataSize ?? (props.labelSize ? 12 - props.labelSize : 9)}>
      <h6 style={{paddingTop: '1.2rem', margin: 0, ...props.style}}>
        {props.data}
      </h6>
    </Col>
  </Row>
);

export default InformationLabel;
