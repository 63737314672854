import React from 'react';
import styles from './PrivateBody.module.scss';
import SidebarPanel from '../SidebarPanel/SidebarPanel';
import {Card, Container} from 'react-bootstrap';
import SidebarPanelMobile from '../SidebarPanel/SidebarPanelMobile';
import {Tooltip} from 'react-tooltip';
import UserInfo from '../UserInfo/UserInfo';
import {Outlet} from 'react-router';

const PrivateBody = (props: {}) => {
  return (
    <Container fluid={true} className={styles['page-body']}>
      <Tooltip id='tooltip-id-anchor' place='top' closeOnEsc={true} delayShow={100} style={{zIndex: 2000}}/>
      <div style={{display: 'flex', height: '100%', maxWidth: '100vw'}}>
        <SidebarPanel/>
        <main style={{display: 'flex', flexDirection: 'column', flex: '1', overflowY: 'scroll'}}>
          <div style={{display: 'flex'}}>
            <SidebarPanelMobile/>
            <UserInfo/>
          </div>
          <Container fluid={true} className={styles['config-container']}>
            <Card style={{display: 'flex', flexDirection: 'column', flex: '1', backgroundColor: '#f3f6f8', border: 'none'}}>
              <Card.Body>
                <Outlet/>
              </Card.Body>
            </Card>
          </Container>
        </main>
      </div>
    </Container>
  );
};

export default PrivateBody;
