import {api} from '../index';
import {InventoryManagementState} from '../../redux/stateResponses/inventoryManagement';
import {Item} from '../../redux/entities/inventory/item';
import {Category} from '../../redux/entities/inventory/category';
import {Dictionary} from '../../types';
import {ProductSelectionState} from '../../redux/stateResponses/productSelectionState';
import {EOrderStatus, Order} from '../../redux/entities/inventory/order';
import {UpdateProfilePictureRequest} from '../../pages/private/Profile/components/ProfilePicture';
import {User} from '../../redux/entities/user';
import {UpdateItemPictureRequest} from '../../components/util/ItemPicture/ItemPicture';
import {UpsertItemRequest} from '../../pages/private/Configuration/InventoryManagement/components/ItemModal/ItemModal';
import {OrderUpdate} from '../../pages/private/Configuration/OrderManagement/components/OrderModal';

export async function getInventoryManagementState() {
  return (await api.get<InventoryManagementState>('/Inventory/management')).data;
}

export async function getProductSelectionState() {
  return (await api.get<ProductSelectionState>('/PublicOrder')).data;
}

export async function upsertItem(item: UpsertItemRequest) {
  return (await api.post<Item>('/Inventory', item)).data;
}

export async function archiveItem(id: string) {
  return (await api.post<Item>(`/Inventory/${id}/archive`)).data;
}

export async function updateItemPicture(request: UpdateItemPictureRequest) {
  return (await api.post<Item>('/Inventory/itemPicture', request)).data;
}


export async function upsertCategory(category: Category) {
  return (await api.post<Category>('/Inventory/category', category)).data;
}

export interface UpsertCategoriesRequest {
  updateList: Category[];
  deleteIds: string[];
}

export async function upsertCategories(editedCategories: UpsertCategoriesRequest) {
  return (await api.post<Dictionary<Category>>('/Inventory/categories', editedCategories)).data;
}

export async function upsertOrder(order: OrderUpdate) {
  return (await api.post<Order>('/Order', order)).data;
}

export async function submitOrderStatus(id: string, status: EOrderStatus) {
  return (await api.post<Order>(`/Order/orderStatus/${id}`, status)).data;
}

export async function cancelOrder(id: string) {
  return (await api.post<Order>(`/Order/cancel/${id}`)).data;
}

export async function deleteOrder(id: string) {
  return (await api.delete<Order>(`/Order/delete/${id}`)).data;
}

export async function submitPublicOrder(order: Order) {
  return (await api.post('/PublicOrder', order)).data;
}
