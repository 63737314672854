import {
  StyledInputColumn,
  TitleRow,
  TopPaddedRow
} from '../../../../../components/util/form-components/standardFormikInputLayout';
import React, {Component, CSSProperties, useCallback, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {CommonState} from '../../../../../redux';
import {bindActionCreators, Dispatch} from 'redux';
import {Item, itemStore} from '../../../../../redux/entities/inventory/item';
import {Category, categoryStore, CategoryTreeNode} from '../../../../../redux/entities/inventory/category';
import {Accordion, Alert, Button, Col, Form as BSForm, Modal, Row, Spinner} from 'react-bootstrap';
import useCategoryTree from '../../../../../hooks/useCategoryTree';
import Input from '../../../../../components/util/form-components/formik-inputs/Input/Input';
import {propertyOf} from '../../../../../util';
import {AppTheme} from '../../../../../appTheme';
import {ErrorMessage, FastField, FieldArray, Form, Formik, useFormikContext} from 'formik';
import {OrderItem} from '../../../../../redux/entities/inventory/joining/orderItem';
import {isNullOrWhitespace} from '../../../../../util/string';
import ItemPicture from '../../../../../components/util/ItemPicture/ItemPicture';
import {convertToDropDownOptions} from '../../../../../util/form';
import Select, {SingleValue} from 'react-select';
import styles from './ItemSelectionForm.module.scss';
import {ChangeViewButton, ChangeViewButtonProps} from '../../HealthProgramOrder';
import {RedErrorMessage} from '../../../../../components/util/form-components/RedErrorMessage/RedErrorMessage';
import {InputRow} from '../../../../../components/util/form-components/InputRow';
import IconButton from '../../../../../components/util/widgets/IconButton/IconButton';
import InformationLabel from '../../../../../components/util/form-components/InformationLabel/InformationLabel';

const getFieldName = propertyOf<Category>;

type Props = {errors?: boolean} & Omit<ChangeViewButtonProps, 'title'> & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const ItemSelectionForm = (props: Props) => {
  const {items, categories, getCategoryItems, uncategorizedItems, alphabeticallySortedItems, topLevelCategories} = props;
  const [input, setInput] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [currentOption, setCurrentOption] = useState<SingleValue<any>>({});
  const dropDownOptions = convertToDropDownOptions(alphabeticallySortedItems);
  const [openCategoryIds, setOpenCategoryIds] = useState<string[]>([]);
  const [commentFieldName, setCommentFieldName] = useState<{name: string; fieldName: string} | null>(null);
  const {setFieldValue, getFieldProps} = useFormikContext();

  const categoryTree = useCategoryTree(categories);
  const topLevelCategoryTreeNodes = useMemo(() => categoryTree.filter((c) => isNullOrWhitespace(c.superCategoryId)), [categoryTree]);

  const treeFilterItemIds = useCallback((categoryItems: Item[]): string[] => categoryItems.reduce<string[]>((a, i) => {
      const currentFound = i.name.toUpperCase().includes(searchValue);
      if (currentFound)
        return [...a, i.id];
      return a;
    }, [])
    , [searchValue]);

  const treeFilterCategoryAndItemIds = useCallback((data: CategoryTreeNode[]): string[] => data.reduce<string[]>((acc, d) => {
    const returnAllChildren = (c: CategoryTreeNode): string[] => {
      const returnedItems = getCategoryItems(c.id).map(i => i.id);
      return [...c.children.reduce<string[]>((a, cat) => ([...a, ...returnAllChildren(cat)]), []), ...returnedItems, c.id];
    };

      if (d.name.toUpperCase().includes(searchValue)) {
        return returnAllChildren(d);
      }

      // Get matching category items along with matching subcategory and subcategory item ids.
      // Add accumulator then add current node ID if any of the above match.
      const filteredCategoryItemIds = treeFilterItemIds(getCategoryItems(d.id));
      const recursedSubcategoryAndSubcategoryItemIds = treeFilterCategoryAndItemIds(d.children);
      const returnedFilteredIds = [...acc, ...filteredCategoryItemIds, ...recursedSubcategoryAndSubcategoryItemIds];
      // return current node if child nodes or items are returned
      if (d.name.toUpperCase().includes(searchValue) || recursedSubcategoryAndSubcategoryItemIds.length || filteredCategoryItemIds.length)
        returnedFilteredIds.push(d.id);
      return returnedFilteredIds;
    }, [])
  , [treeFilterItemIds, searchValue, getCategoryItems]);

  const filteredSearchResults = useMemo(() => {
    const filteredCategoryTree = treeFilterCategoryAndItemIds(topLevelCategoryTreeNodes);
    const filteredUncategorizedItems = treeFilterItemIds(uncategorizedItems);
    return [...filteredCategoryTree, ...filteredUncategorizedItems];
  }, [treeFilterCategoryAndItemIds, treeFilterItemIds, uncategorizedItems, categoryTree]);

  const renderItemCommentModal = () => (
    <Modal show={!!commentFieldName} size={'lg'} centered={true}>
      <Modal.Body>
        <Modal.Title>Leave a Note on "{commentFieldName?.name}"</Modal.Title>
        <Col>
          <Formik
            initialValues={{comment: getFieldProps(commentFieldName?.fieldName)?.value ?? ''}}
            onSubmit={(values) => {setFieldValue(commentFieldName?.fieldName ?? '', values.comment); setCommentFieldName(null);}}
          >
            <Form>
              <TopPaddedRow>
                <Input name={'comment'} type={'textarea'} overrideDebouncePeriod={0}/>
              </TopPaddedRow>
              <TopPaddedRow>
                <BSForm.Group className={'modal-buttons'}>
                  <Button onClick={() => setCommentFieldName(null)} variant={'danger'} className={'modal-close-button'}>
                    {'Cancel'}
                  </Button>
                  <Button variant={'primary'} type={'submit'}>
                    Add Note
                  </Button>
                </BSForm.Group>
              </TopPaddedRow>
            </Form>
          </Formik>
        </Col>
      </Modal.Body>
    </Modal>
  );

  const renderItemOrOptions = (item: Item, prefix: string) => {
    const idFieldName = `${prefix}.${propertyOf<OrderItem>('itemId')}`;
    const quantityFieldName = `${prefix}.${propertyOf<OrderItem>('itemQuantity')}`;
    const itemOptionsFieldName = `${prefix}.${propertyOf<OrderItem>('itemOptions')}`;

    if (item.itemOptions !== undefined && item.itemOptions.length) return (
      <>
        <Input name={idFieldName} hidden={true} disabled={true} defaultValue={item.id}/>
        <FieldArray name={itemOptionsFieldName} render={() =>
          item.itemOptions.map((option, index) =>
            <div key={option.id}>
              <StyledInputColumn label={option.name} style={{fontWeight: 'bold', paddingRight: 0, paddingLeft: '10px'}}>
                <Input name={`${itemOptionsFieldName}[${index}].itemOptionId`} hidden={true} disabled={true} defaultValue={option.id}/>
                <Input
                  name={`${itemOptionsFieldName}[${index}].quantity`}
                  style={{borderColor: AppTheme.colors.logoBlue, fontSize: '0.8rem', width: '6rem'}}
                  placeholder={'Quantity'}
                  type={'number'}
                />
              </StyledInputColumn>
            </div>
          )}
        />
      </>
    );
    return (
      <>
        <Input name={idFieldName} hidden={true} disabled={true} defaultValue={item.id}/>
        <Input
          style={{display: 'flex', flexDirection: 'column', width: '6rem', fontSize: '0.8rem', borderColor: AppTheme.colors.publicPrimary}}
          name={quantityFieldName}
          placeholder={'Quantity'}
          type={'number'}
        />
      </>
    );
  };

  const renderItems = (categoryItems: Item[]) => {
    return categoryItems.map((item) => {
      const prefix = `orderItems[${items.findIndex(i => i.id === item.id)}]`;
      const comment = getFieldProps(`orderItems[${items.findIndex(i => i.id === item.id)}].extraComment`)?.value;
      return (
          <TopPaddedRow key={item.id} className={styles['category-item']}>
            <Col>
              <Row style={{width: '100%', flexWrap: 'nowrap', wordWrap: 'normal'}}>
                <div className={styles['image-print']}>
                  <ItemPicture
                    itemId={item.id}
                    editable={false}
                    className={styles['image-print']}
                    style={{display: 'block', height: '3rem', width: '3rem', marginRight: '15px'}}
                  />
                </div>
                <div style={{fontWeight: 'bold', marginRight: '1rem'}}>
                  {item.name}
                </div>
              </Row>
              {!isNullOrWhitespace(comment) ? <div style={{marginTop: '10px'}}>{comment}</div> : null}
            </Col>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center', marginLeft: 'auto'}}>
              <div style={{display: 'flex', marginLeft: 'auto', flexWrap: 'wrap', marginRight: '1rem', justifyContent: 'flex-end'}}>
                {renderItemOrOptions(item, prefix)}
              </div>
              <IconButton
                icon={'comment-alt'}
                size={'1x'}
                iconToolTipText={'Leave a note for SOS'}
                styles={{margin: 'auto 0 auto auto'}}
                color={AppTheme.colors.logoBlue}
                onClick={() => setCommentFieldName({fieldName: `${prefix}.extraComment`, name: item.name})}
              />
            </div>
          </TopPaddedRow>
      );
    });
  };

  const recursiveMapCategories = (categoryArray: CategoryTreeNode[], filterCategories?: boolean, parent?: boolean) => {
    const subcategoryHasItems = (category: CategoryTreeNode): boolean => {
      if (getCategoryItems(category.id)?.length) return true;
      if (category.children.length) return (category.children.map((c) => subcategoryHasItems(c)).includes(true));
      return false;
    };

    return (
      <Accordion className={styles['accordion']} alwaysOpen={true}>
        {categoryArray.map((category) => {
          const categoryItems = getCategoryItems(category.id);
          const renderedCategories = searchValue.length ? category.children.filter(c => filteredSearchResults.includes(c.id)) : category.children;
          const renderedItems = searchValue.length ? categoryItems.filter(i => filteredSearchResults.includes(i.id)) : categoryItems;
          if (subcategoryHasItems(category)) return (
            <Row key={category.id}>
              <Accordion.Item eventKey={category.id} className={styles['accordion-item']} style={{borderRadius: 0}}>
                <Accordion.Header style={parent ? {paddingTop: '0', paddingBottom: '0'} : {}}>
                  {parent ? <h4>{category.name}</h4> : <h6>{category.name}</h6>}
                </Accordion.Header>
                <Accordion.Body
                  onEnter={() => setOpenCategoryIds(prev => [...prev, category.id])}
                  onExited={() => setOpenCategoryIds(prev => prev.filter(id => id !== category.id))}
                >
                  {openCategoryIds.find(id => id === category.id) ? renderItems(renderedItems) : null}
                  {recursiveMapCategories(renderedCategories, (!!renderedCategories.length || filterCategories))}
                </Accordion.Body>
              </Accordion.Item>
            </Row>
          );
        })}
      </Accordion>
    );
  };

  return (
    <Col className={styles['container']}>
      {renderItemCommentModal()}
      <TitleRow
        label={
        <ChangeViewButton
          title={'Product Selection'} asSinglePage={props.asSinglePage}
          setAsSinglePage={props.setAsSinglePage}
          setTab={props.setTab}
        />}
        centerTitle={true}
      >
        <TopPaddedRow style={{position: 'sticky',width: '100%'}}>
          <Select
            options={dropDownOptions}
            backspaceRemovesValue={true}
            value={currentOption ?? undefined}
            onChange={(newValue) => {
              setCurrentOption(newValue);
              setInput(newValue?.label ?? '');
              setSearchValue(newValue?.label.toUpperCase() ?? '');
            }}
            onInputChange={(newValue, actionMeta) => {
              if (actionMeta.action === 'input-change') {
                setInput(newValue);
                setSearchValue(newValue?.toUpperCase() ?? '');
              }
            }}
            inputValue={!currentOption?.label ? input : undefined}
            onFocus={(e) => e.target.select()}
            blurInputOnSelect={true}
            openMenuOnFocus={false}
            openMenuOnClick={false}
            isClearable={true}
            isSearchable={true}
            menuShouldScrollIntoView={true}
            placeholder={'Search...'}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 3000, overflowY: 'visible', lineHeight: '0.5rem', transform: 'translate(0, -8px)'}),
              menu: base => ({...base, width: 'max-content', minWidth: '100%'}),
              container: base => ({...base, width: '100%'})

            }}
            menuPortalTarget={document.querySelector('body')}
            components={{}}
          />
          <RedErrorMessage name={'orderItems'}/>
        </TopPaddedRow>
        <Alert variant={'warning'}>
          Please select which items you are requesting and the quantity. We ask that you specify the number of items rather than number of boxes
          or packs (i.e. 2,000 masks vs 10 boxes). All items are based on availability.
          <div>Photos are representative only; the products you receive may not look exactly link the items pictured.</div>
          <div style={{fontWeight: 'bold'}}>
            If you have a specific request regarding item type or sizing, please click the speech box next to the item in question and leave a note.
          </div>
        </Alert>
        <TopPaddedRow>
          <FieldArray
            name={'orderItems'}
            render={arrayHelpers => (
              <>
                {renderItems(searchValue.length ? uncategorizedItems.filter(i => filteredSearchResults.includes(i.id)) : uncategorizedItems)}
                {recursiveMapCategories(searchValue.length ?
                  topLevelCategoryTreeNodes.filter(c => filteredSearchResults.includes(c.id)) :
                  topLevelCategoryTreeNodes, undefined, true
                )}
              </>
            )}
          />
        </TopPaddedRow>
      </TitleRow>
    </Col>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
  }, dispatch)});
const mapStateToProps = (state: CommonState) => ({
  items: itemStore.selectors.getAsArray(state),
  getCategoryItems: itemStore.selectors.getCategoryItems(state),
  alphabeticallySortedItems: itemStore.selectors.alphabeticallySortedItems(state),
  uncategorizedItems: itemStore.selectors.uncategorizedItems(state),
  categories: categoryStore.selectors.getAsArray(state),
  topLevelCategories: categoryStore.selectors.topLevelCategories(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemSelectionForm);
