import React from 'react';
import {
  TitleRow,
  TopPaddedRow,
  TopPaddedRowSeparator
} from '../../../../components/util/form-components/standardFormikInputLayout';
import {Link} from 'react-router-dom';
import {RoutePaths} from '../../../../router/RoutePaths';
import {Alert, Col} from 'react-bootstrap';
import {Order} from '../../../../redux/entities/inventory/order';
import styles from './SubmissionConfirmation.module.scss';
import {AppTheme} from '../../../../appTheme';

interface Props {
  order: Order;
  printOrder: () => void;
}

export const SubmissionConfirmation = (props: Props) => {

  return (
    <TitleRow label={'Your order has been placed.'} centerTitle={true}>
      <Col>
        <TopPaddedRowSeparator style={{display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
          <Alert variant={'info'}>
            A representative of SOS International should get in contact with your organization (Raven Bridge Software) through your documented
            organization contact's email ({props.order.consumerInformation.emailAddress}) or phone number ({props.order.consumerInformation.phoneNumber})
            within a 3 to five business days. For questions regarding this form, please contact Local Health Program Manager, Brian Jointer, at{' '}
            <a href={'mailto: abc@example.com'} style={{display: 'inline'}}>
              brian.jointer@soshealthandhope.org
            </a>
          </Alert>
          <div className={styles['link']}>
            <a onClick={props.printOrder}>
              <h2>Print your order</h2>
            </a>
          </div>
          <div>
            <a className={styles['link']} href={'https://order.soshealthandhope.org'}>
            Back to home
            </a>
          </div>
          <div>
            <a className={styles['link']} href={'https://soshealthandhope.org/'}>
              Return to SOSHealthAndHope.org
            </a>
          </div>
        </TopPaddedRowSeparator>
      </Col>
    </TitleRow>
  );
};
