import {Category} from './category';
import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../../utils';
import {createStandardSelectors, getEntities, selector} from '../../selectors';
import {combineReducers} from 'redux';
import {CommonDispatch} from '../../index';
import {OrderItem} from './joining/orderItem';
import {ItemCategory} from './joining/itemCategory';
import {archiveUser, updateProfilePicture} from '../../../api/userManagementApi';
import {User, userStore} from '../user';
import {archiveItem, updateItemPicture, upsertItem} from '../../../api/inventory/inventoryApi';
import {UpdateProfilePictureRequest} from '../../../pages/private/Profile/components/ProfilePicture';
import {UpdateItemPictureRequest} from '../../../components/util/ItemPicture/ItemPicture';
import {
  UpsertItemRequest
} from '../../../pages/private/Configuration/InventoryManagement/components/ItemModal/ItemModal';
import {isNullOrWhitespace} from '../../../util/string';

export interface ItemOption {
  id: string;
  itemId: string;
  name: string;
  quantity: number;
  archived: boolean;
}

export interface Item {
  id: string;
  name: string;
  quantity: number;
  restrictOrderSize: boolean;
  maxOrderSize: number;
  estimatedCost: number;
  imagePath: string;
  archived: boolean;
  categoryId: string;
  orderItems: OrderItem[];
  itemOptions: ItemOption[];
}

export interface ItemTreeNode extends Item {
  children: ItemOption[];
}

const actions = createStandardActions(placeholder<Item>(), 'ITEM/SET', 'ITEM/SAVE');
const selectors = createStandardSelectors(placeholder<Item>(), s => getEntities(s).items);
export type ItemActions = GetActions<typeof actions>;
export const items = combineReducers({items: standardItemsReducer<Item, ItemActions>(actions)});
export const itemStore = readonly({
  selectors: {
    ...selectors,
    getItemByName: selector(s => (name: string) => selectors.getAsArray(s).find(item => item.name === name)),
    alphabeticallySortedItems: selector(s => [...selectors.getAsArray(s)].filter(i => !i.archived).sort((a, b) => a.name.localeCompare(b.name))),
    unarchivedItems: selector(s => selectors.getAsArray(s).filter(i => !i.archived)),
    uncategorizedItems: selector(s => selectors.getAsArray(s).filter(i => isNullOrWhitespace(i.categoryId))),
    getCategoryItems: selector(s => (categoryId: string) => selectors.getAsArray(s).filter(i => i.categoryId === categoryId))
  },
  actions: {
    ...actions,
    upsert: (item: UpsertItemRequest) => async (dispatch: CommonDispatch) => {
      const response = await upsertItem(item);
      dispatch(actions.save(response));
    },
    updateItemPicture: (request: UpdateItemPictureRequest) => async (dispatch: CommonDispatch) => {
      const item: Item = await updateItemPicture(request);
      dispatch(itemStore.actions.save(item));
      return item.imagePath;
    },
    archiveItem: (id: string) => async (dispatch: CommonDispatch) => {
      const item: Item = await archiveItem(id);
      dispatch(itemStore.actions.save(item));
    }
  }
});
