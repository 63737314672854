import React, {CSSProperties, FocusEvent, RefObject, useEffect, useMemo, useState} from 'react';
import {useField, useFormikContext} from 'formik';
import styles from './Input.module.scss';
import {Form} from 'react-bootstrap';
import {RedErrorMessage} from '../../RedErrorMessage/RedErrorMessage';
import {debounce} from 'lodash';
import {OnChangeFormControlType} from '../../../../../types';
import {getFieldValue} from '../../../../../util/form';

interface InputProps {
  name: string;
  type?: 'number' | 'text' | 'password' | 'email' | 'textarea' | 'color' | 'file' | 'time' | 'date';
  step?: number;
  disabled?: boolean;
  valueOnDisabled?: any;
  rows?: number;
  defaultValue?: any;
  emptyAsNull?: boolean;
  placeholder?: string;
  autoComplete?: string;
  overrideDebouncePeriod?: number;
  setFormDataState?: (data: any) => void;
  forwardedRef?: RefObject<HTMLInputElement>;
  hidden?: boolean;
  style?: CSSProperties;
}

const Input = (props: InputProps) => {
  const {emptyAsNull, name, disabled, valueOnDisabled, style, overrideDebouncePeriod, setFormDataState} = props;
  const [field, {value, error, touched}] = useField(name);
  const {setFieldValue} = useFormikContext<any>();
  const fieldOnChange = field.onChange;
  if (!value && props.defaultValue) {
    setFieldValue(name, props.defaultValue, true);
  }
  useEffect(() => {
    if (valueOnDisabled !== undefined && disabled)
      setFieldValue(name, valueOnDisabled);
  }, [valueOnDisabled, disabled]);

  const [localValue, setLocalValue] = useState('');
  useEffect(() => {
    setLocalValue(getFieldValue(value));
  }, [value]);

  // optimize updates by keeping a local state for fast reflection of user input.
  // after a debounce period, the changes are committed to the entire formik state.
  const onChangeInnerLayer = useMemo(() => debounce((e: OnChangeFormControlType) => {
    if ((e.target as HTMLInputElement).value === '' && emptyAsNull) {
      setFieldValue(name, null, true);
    } else {
      fieldOnChange(e);
    }
  }, overrideDebouncePeriod ?? 1000), [fieldOnChange, setFieldValue, emptyAsNull, name, overrideDebouncePeriod]);
  const onChange = useMemo(() => (e: OnChangeFormControlType) => {
    e.persist();
    if(props.type === 'file') {
      // @ts-ignore
      setFormDataState(e.target.files[0]);
    }
    setLocalValue(e.currentTarget.value!);
    return onChangeInnerLayer(e);
  }, [onChangeInnerLayer]);
  const onBlur =  useMemo(() => (e: FocusEvent<HTMLInputElement>) => fieldOnChange(e), [fieldOnChange]);
  return (
    <React.Fragment>
      <Form.Control
        as={props.type === 'textarea' ? 'textarea' : 'input'}
        ref={props.forwardedRef}
        isInvalid={touched && Boolean(error)}
        className={styles['form-inputs']}
        style={props.type === 'file' ? {border: '0', boxShadow: 'none', paddingLeft: '0'} : style ? {...style} : undefined}
        // @ts-ignore
        rows={props.rows}
        {...field}
        value={localValue}
        onChange={(e) => onChange(e)}
        disabled={disabled}
        type={props.type || 'text'}
        placeholder={props.placeholder ? props.placeholder : ''}
        autoComplete={props.autoComplete ? props.autoComplete : 'on'}
        hidden={props.hidden ?? false}
      />
      <RedErrorMessage name={props.name}/>
    </React.Fragment>
  );
};

export default Input;
