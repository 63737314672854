import {useReactToPrint} from 'react-to-print';
import styles from './useStandardPrint.module.scss';
import {MutableRefObject} from 'react';

const useStandardPrint = (ref: MutableRefObject<null>) => useReactToPrint({
  content: () => ref.current,
  pageStyle: '@page { size: auto;  margin: 4rem 2rem; } }',
  bodyClass: styles['print']
});

export default useStandardPrint;
