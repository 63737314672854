import {ConsumerInformation, Order} from '../../../../redux/entities/inventory/order';
import {Alert, Col, Row} from 'react-bootstrap';
import {
  StyledInputColumn,
  TitleRow,
  TopPaddedRow
} from '../../../../components/util/form-components/standardFormikInputLayout';
import React, {forwardRef, ReactNode, RefObject} from 'react';
import InformationLabel from '../../../../components/util/form-components/InformationLabel/InformationLabel';
import {Dispatch} from 'redux';
import {CommonState} from '../../../../redux';
import {Item, itemStore} from '../../../../redux/entities/inventory/item';
import {connect} from 'react-redux';
import ItemPicture from '../../../../components/util/ItemPicture/ItemPicture';
import {ChangeViewButton, ChangeViewButtonProps} from '../HealthProgramOrder';
import itemSelectionStyles from '../form/ItemSelectionForm/ItemSelectionForm.module.scss';
import {formatIfPossibleUsPhone, isNullOrWhitespace} from '../../../../util/string';
import {format} from 'date-fns-tz';
import {getLogoLink} from '../../../../appTheme';
import {FormikErrors} from 'formik';
import {parseISO} from 'date-fns';
import {OrderItem} from '../../../../redux/entities/inventory/joining/orderItem';
import {makeItem} from '../../../../redux/factory/inventory/item';
import {v4} from 'uuid';
import orderItemList from '../../../../components/util/OrderItemList/OrderItemList';
import OrderItemList from '../../../../components/util/OrderItemList/OrderItemList';
import Input from '../../../../components/util/form-components/formik-inputs/Input/Input';
import {propertyOf} from '../../../../util';

type Props = {
  order: Order;
  canvasURL: () => string | undefined;
  errors: FormikErrors<Order>;
  showCanvas?: boolean;
  showSignature?: boolean;
  submissionTime?: Date;
  showDates?: boolean;
  commentAsInput?: boolean;
  separateByCategory?: boolean;
} & Omit<ChangeViewButtonProps, 'title'> & ReturnType<typeof mapStateToProps>;

export const OrderReview = forwardRef((props: Props, ref) => {
  const {order, canvasURL, getItemById, errors, showCanvas = false, showSignature = true, showDates = false, commentAsInput = false, separateByCategory = false} = props;

  const headerDate = () => {
    if (props.submissionTime) return ` - Placed ${formatOrderDate(props.submissionTime.toISOString())}`;
    if (!isNullOrWhitespace(order.shippedAt)) return ` - Shipped ${formatOrderDate(order.shippedAt)}`;
    if (!isNullOrWhitespace(order.approvedAt)) return ` - Approved ${formatOrderDate(order.approvedAt)}`;
    if (!isNullOrWhitespace(order.submittedAt)) return ` - Submitted ${formatOrderDate(order.submittedAt)}`;
    return null;
  };

  const formatOrderDate = (isoDateString?: string) => {
    if (isNullOrWhitespace(isoDateString)) return 'N/A';
    return format(parseISO(isoDateString!), 'MM/dd/yyyy hh:mm a');
  };

  const mapAllErrors = (data: FormikErrors<any> | FormikErrors<any>[] | string | string[] | undefined): ReactNode => {
    if (data === undefined) return null;
    if (typeof data === 'object') {
      return Object.entries(data).map(([key, value]) => {
        return mapAllErrors(value);
      });
    }
    if (Array.isArray(data))
      return data.map(e => mapAllErrors(e));
    return <Row key={data}>{data}</Row>;
  };

  const renderOrderDates = () => {
    if (showDates) return (
      <Row style={{display: 'flex', width: '100%', justifyContent: 'space-evenly', margin: '1rem 0'}}>
        <OrderDateLabel label={'Submission Date'} date={formatOrderDate(order.submittedAt)}/>
        <OrderDateLabel label={'Approval Date'} date={formatOrderDate(order.approvedAt)}/>
        <OrderDateLabel label={'Shipping Date'} date={formatOrderDate(order.shippedAt)}/>
      </Row>
    );
    return null;
  };

  const renderCommentLabelOrInput = () => {
    if (commentAsInput) return (
      <StyledInputColumn label={'Do you have any extra questions or concerns?'}>
        <Input name={`${propertyOf<Order>('consumerInformation')}.${propertyOf<ConsumerInformation>('comment')}`} type={'textarea'}/>
      </StyledInputColumn>
    );
    return (
      <InformationLabel
        label={'Do you have any extra questions or concerns?'}
        data={order.consumerInformation.comment}
        labelSize={5}
        dataSize={7}
        rowStyle={{width: '100%'}}
      />
    );
  };

  return (
    <Col style={{padding: 0}}>
      <img className={itemSelectionStyles['logo-print']} src={getLogoLink()}/>
      <Row>
        <TitleRow
          label={
          <ChangeViewButton
            title={(
              <>
                <h3>
                  <span className={itemSelectionStyles['title-print']}>SOS </span>
                  Order Review
                  <span className={itemSelectionStyles['title-print']}>{headerDate()}</span>
                </h3>
              </>
            )}
            asSinglePage={props.asSinglePage}
            setAsSinglePage={props.setAsSinglePage}
            setTab={props.setTab}
          />
          }
          centerTitle={true}
        >
          {!!Object.keys(errors).length ?
            <Alert variant={'danger'}>
              <h4 style={{paddingBottom: '10px'}}>
                There are errors in the form. Please {props.asSinglePage ? 'scroll up' : 'switch tabs'} and check for red inputs.
              </h4>
              {mapAllErrors(errors)}
            </Alert>
            : null}
          {renderOrderDates()}
          <TopPaddedRow>
            <Col style={{padding: '0'}}>
              <TitleRow label={'Contact Information'} header={'h4'}>
                <InformationLabel label={'Name of Organization'} labelSize={6} data={order.consumerInformation.organization}/>
                <InformationLabel label={'Department or Outreach to be Serviced'} data={order.consumerInformation.departmentOrOutreach}/>
                <InformationLabel label={'Name of Contact'} labelSize={6} data={order.consumerInformation.name}/>
                <InformationLabel label={'Contact Job Title'} labelSize={6} data={order.consumerInformation.jobTitle}/>
                <InformationLabel
                  label={'Contact Email Address'}
                  labelSize={6}
                  data={<span style={{wordBreak: 'break-word'}}>{order.consumerInformation.emailAddress}</span>}
                />
                <InformationLabel label={'Contact Phone Number'} labelSize={6} data={formatIfPossibleUsPhone(order.consumerInformation.phoneNumber)}/>
              </TitleRow>
            </Col>
            <Col style={{padding: '0'}}>
              <TitleRow label={'Organization Address'} header={'h4'}>
                <InformationLabel label={'Street Address'} labelSize={6} data={order.consumerInformation.streetAddress}/>
                <InformationLabel label={'Street Address Line Two'} labelSize={6} data={order.consumerInformation.streetAddressLineTwo}/>
                <InformationLabel label={'City'} labelSize={6} data={order.consumerInformation.city}/>
                <InformationLabel label={'State'} labelSize={6} data={order.consumerInformation.state}/>
                <InformationLabel label={'Postal / ZIP'} labelSize={6} data={order.consumerInformation.zip}/>
              </TitleRow>
            </Col>
          </TopPaddedRow>
          <TitleRow label={'Order Beneficiaries'} header={'h4'}>
            <InformationLabel
              label={'Who will benefit from this donation?'}
              data={order.consumerInformation.beneficiaries}
              labelSize={6}
              dataSize={6}
            />
            <InformationLabel
              label={'Please tell us how this donation will help the organization and/or the recipients.'}
              data={order.consumerInformation.beneficiariesDescription}
              labelSize={6}
              dataSize={6}
            />
            <InformationLabel
              label={'Approximately how many people will benefit from this donation?'}
              data={order.consumerInformation.beneficiariesQuantity}
              labelSize={6}
              dataSize={6}
            />
          </TitleRow>
          {showSignature ?
            <TitleRow label={'Signature'} header={'h4'}>
              <img src={canvasURL()}/>
            </TitleRow> : null}
          <TopPaddedRow style={{width: '100%'}}>
            {renderCommentLabelOrInput()}
          </TopPaddedRow>
          <TitleRow label={'Product Selection'} header={'h4'}>
            <OrderItemList orderItems={order.orderItems} separateByCategory={true}/>
          </TitleRow>
        </TitleRow>
      </Row>
      {showDates ?
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', position: 'absolute', top: '1vh', right: '0'}}>
          <div><span style={{fontWeight: 'bold'}}>Weight</span> _________________________</div>
          <div style={{marginTop: '2rem'}}><span style={{fontWeight: 'bold'}}>Date</span> _________________________</div>
        </div> : null
      }
    </Col>
  );
}
);

const OrderDateLabel = (props: {label: ReactNode, date: ReactNode}) => {
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <Row style={{whiteSpace: 'nowrap'}}>
        <div>{props.label}</div><h6 style={{marginLeft: '1rem', marginTop: '2px'}}>{props.date}</h6>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: CommonState) => ({
  getItemById: itemStore.selectors.getById(state)
});
export default connect(mapStateToProps)(OrderReview);
