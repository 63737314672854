import {EBootstrapVariant, PublicMessage} from "../../types/PublicMessage";
import {Alert} from "react-bootstrap";
import React, {CSSProperties} from "react";

export function PublicMessageAlert (props: {message: PublicMessage, styles?: CSSProperties}) {
  const {message} = props;
  const formattedMessage = message.message.replace(/(\r\n|\r|\n)/g, '\r\n')

  if (message.bootstrapVariant === EBootstrapVariant.none) return <div style={{...props.styles, whiteSpace: 'pre-line'}}>{formattedMessage}</div>;
  return <Alert style={{...props.styles, whiteSpace: 'pre-line'}} variant={EBootstrapVariant[message.bootstrapVariant]}>{formattedMessage}</Alert>;
}
