import {Dictionary} from '../../types';
import {Item, itemStore} from '../entities/inventory/item';
import {Category, categoryStore} from '../entities/inventory/category';
import {getProductSelectionState} from '../../api/inventory/inventoryApi';
import {batchActions} from 'redux-batched-actions';
import {Dispatch} from 'redux';

export interface ProductSelectionState {
  items: Dictionary<Item>;
  categories: Dictionary<Category>;
}

export const loadProductSelectionStateData = () => async (dispatch: Dispatch) => {
  const response: ProductSelectionState = await getProductSelectionState();
  await dispatch(batchActions([
    dispatch(itemStore.actions.set(response.items)),
    dispatch(categoryStore.actions.set(response.categories))
  ], 'LOAD_PRODUCT_SELECTION_STATE'));
};
