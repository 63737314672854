import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../../utils';
import {createStandardSelectors, getEntities, selector} from '../../selectors';
import {combineReducers} from 'redux';
import {OrderItem} from './joining/orderItem';
import {UpdateItemPictureRequest} from '../../../components/util/ItemPicture/ItemPicture';
import {
  cancelOrder,
  deleteOrder,
  submitOrderStatus,
  updateItemPicture,
  upsertOrder
} from '../../../api/inventory/inventoryApi';
import {CommonDispatch} from '../../index';
import {OrderUpdate} from '../../../pages/private/Configuration/OrderManagement/components/OrderModal';
import {createAction} from 'typesafe-actions';

export enum EOrderStatus {
  Unapproved = 0,
  Approved = 1,
  Shipped = 2,
  Cancelled = 3
}

export type ConsumerInformation = {
  id: string;
  organization: string;
  name: string;
  jobTitle: string;
  emailAddress: string;
  phoneNumber: string;
  streetAddress: string;
  streetAddressLineTwo: string;
  city: string;
  state: string;
  zip: string;
  departmentOrOutreach: string;
  beneficiaries: string;
  beneficiariesDescription: string;
  beneficiariesQuantity: string;
  signaturePath: string;
  comment: string;
};

export interface Order {
  id: string;
  orderStatus: EOrderStatus;
  approvedById: string | null;
  consumerInformationId: string | null;
  consumerInformation: ConsumerInformation;
  orderItems: OrderItem[];
  submittedAt: string;
  approvedAt: string;
  shippedAt: string;
}

const actions = createStandardActions(placeholder<Order>(), 'ORDER/SET', 'ORDER/SAVE');
const selectors = createStandardSelectors(placeholder<Order>(), s => getEntities(s).orders);
const deleteOrderAction = createAction('ORDER/DELETE')<string>();
export type OrderActions = GetActions<typeof actions> | ReturnType<typeof deleteOrderAction>;
export const orders = combineReducers({items: standardItemsReducer<Order, OrderActions>(actions)
    .handleAction(deleteOrderAction, (state, action) => {
      const newState = {...state};
      delete newState[action.payload as any];
      return newState;
  })
});
export const orderStore = readonly({
  selectors: {
    ...selectors,
    unapproved: selector(s => selectors.getAsArray(s).filter(order => order.orderStatus === EOrderStatus.Unapproved)),
    approved: selector(s => selectors.getAsArray(s).filter(order => order.orderStatus === EOrderStatus.Approved)),
    shipped: selector(s => selectors.getAsArray(s).filter(order => order.orderStatus === EOrderStatus.Shipped)),
    cancelled: selector(s => selectors.getAsArray(s).filter(order => order.orderStatus === EOrderStatus.Cancelled)),
    notCancelled: selector(s => selectors.getAsArray(s).filter(order => order.orderStatus !== EOrderStatus.Cancelled))
  },
  actions: {
    ...actions,
    upsert: (order: OrderUpdate) => async (dispatch: CommonDispatch) => {
      const response = await upsertOrder(order);
      dispatch(orderStore.actions.save(response));
    },
    updateOrderStatus: (id: string, orderStatus: EOrderStatus) => async (dispatch: CommonDispatch) => {
      const order = await submitOrderStatus(id, orderStatus);
      dispatch(orderStore.actions.save(order));
    },
    cancelOrder: (id: string) => async (dispatch: CommonDispatch) => {
      const order = await cancelOrder(id);
      dispatch(orderStore.actions.save(order));
    },
    deleteOrder: (id: string) => async (dispatch: CommonDispatch) => {
      await deleteOrder(id);
      dispatch(deleteOrderAction(id));
    }
  }
});
