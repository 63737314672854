import {Alert, Button, Card, Row} from 'react-bootstrap';
import styles from './FormHeader.module.scss';
import {LoadingButton} from '../../widgets/LoadingButton/LoadingButton';
import React, {CSSProperties, SyntheticEvent, useCallback} from 'react';
import IconButton from '../../widgets/IconButton/IconButton';
import {Link, Navigate} from 'react-router-dom';
import {combineCssClasses} from '../../../../util';
import {StandardEditorAdditionalButtonProps} from '../EditorForm/hooks';

interface FormHeaderProps {
  title: string;
  backUrl?: string;
  editable: boolean;
  isNew: boolean;
  saving: boolean;
  onCancel: () => void;
  onEdit?: () => void;
  onClone?: () => void;
  onDelete?: () => void;
  errorMessage?: string;
  redirectUrl?: string;
  editButtonText?: string;
  saveButtonText?: string;
  deleteButtonText?: string;
  cancelButtonText?: string;
  submitButtonColor?: string;
  wrapperStyle?: CSSProperties;
  buttonWrapperStyle?: CSSProperties;
  additionalButtonsProps?: StandardEditorAdditionalButtonProps;
}

export function FormHeader(props: FormHeaderProps) {
  const {title, editable, isNew, saving, onCancel, onClone, onDelete, redirectUrl, backUrl, wrapperStyle, buttonWrapperStyle,
    editButtonText, saveButtonText, deleteButtonText, cancelButtonText, submitButtonColor, additionalButtonsProps} = props;
  const onEdit = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (props.onEdit) {
        props.onEdit();
      }
    }, [props]);

  const renderAdditionalButtons = () => (
    <>
      {additionalButtonsProps?.buttons.filter(b => !editable ? b.showView : b.showEdit).map((buttonProps, index) => {
        const {children, showView, showEdit, ...rest} = buttonProps;
        return ( <Button key={index} {...rest} className={styles['form-buttons']}>{children}</Button>);
      })}
    </>
  );

  // Row that is the buttons wrapper was previously <Col className='d-flex align-items-center justify-content-end'>
  return (
    <>
      <Row style={{marginBottom: '1rem', ...wrapperStyle}}>
        {backUrl ?
          (
            <Link
              to={backUrl}
            >
              <IconButton
                icon={'angle-left'}
                size={'2x'}
                iconToolTipText={'Back'}
                color={'#005A9C'}
              />
            </Link>
          )
          : null}
        <div style={{display: 'flex', alignItems: 'center', marginLeft: '20px'}}>
          <Card.Title>{(isNew ? 'New' : editable ? 'Edit' : 'View') + ` ${title}`}</Card.Title>
        </div>
        <Row style={{display: 'flex', flex: '1', justifyContent: 'flex-end', ...buttonWrapperStyle}}>
            {additionalButtonsProps?.position === 'left' && renderAdditionalButtons()}
            {onDelete && !editable && <Button onClick={onDelete} className={combineCssClasses(styles['btn-with-margin'], 'btn-danger')}>
              {deleteButtonText ?? 'Delete'}
            </Button>}
            {onClone && !editable && <IconButton size='2x' icon='copy' onClick={onClone} className={styles['btn-with-margin']} iconToolTipText='Clone'/>}
            {editable && <Button className={styles['form-buttons']} variant='danger' onClick={onCancel}>{cancelButtonText ?? 'Cancel'}</Button>}
            <LoadingButton
              loading={saving}
              label={editable ? saveButtonText ?? 'Save' : editButtonText ?? 'Edit'}
              className={styles['form-buttons']}
              color={submitButtonColor}
              type={editable ? 'submit' : 'button'}
              variant='primary'
              disabled={saving || (!editable && !props.onEdit)}
              onClick={!editable ? onEdit : undefined}
            />
            {additionalButtonsProps?.position === 'right' && renderAdditionalButtons()}
        </Row>

      </Row>
      <Alert show={!!props.errorMessage} variant='danger'>{props.errorMessage}</Alert>
      {redirectUrl ? <Navigate to={redirectUrl}/> : null}
    </>
  );
}
