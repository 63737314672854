import { useMemo, useState } from 'react';
import {
  SimpleTree,
  CreateHandler,
  DeleteHandler,
  MoveHandler,
  RenameHandler,
} from 'react-arborist';
import {v4} from 'uuid';

export type SimpleTreeData = {
  id: string;
  name: string;
  children?: SimpleTreeData[];
};

export function useCustomSimpleTree<T>(initialData: readonly T[], foreignKey: keyof T) {
  const [data, setData] = useState(initialData);
  const tree = useMemo(
    () =>
      new SimpleTree<// @ts-ignore
        T>(data),
    [data]
  );

  const onMove: MoveHandler<T> = (args: {
    dragIds: string[];
    parentId: null | string;
    index: number;
  }) => {
    for (const id of args.dragIds) {
      tree.move({ id, parentId: args.parentId, index: args.index });
      // @ts-ignore
      tree.update({id: id, changes: {[foreignKey]: args.parentId ?? ''}});
    }
    setData(tree.data);
  };

  const onRename: RenameHandler<T> = ({ name, id }) => {
    tree.update({ id, changes: { name } as any });
    setData(tree.data);
  };

  const onCreate: CreateHandler<T> = ({ parentId, index, type }) => {
    // tslint:disable-next-line:no-shadowed-variable
    const data = { id: v4(), name: '', [foreignKey]: parentId } as any;
    if (type === 'internal') data.children = [];
    tree.create({ parentId, index, data });
    setData(tree.data);
    return data;
  };

  const onDelete: DeleteHandler<T> = (args: { ids: string[] }) => {
    args.ids.forEach((id) => tree.drop({ id }));
    setData(tree.data);
  };

  const controller = { onMove, onRename, onCreate, onDelete };

  return [data, controller] as const;
}
