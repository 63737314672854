import {array, boolean, number, object, string} from 'yup';
import {Order} from '../../../redux/entities/inventory/order';
import {createAllObjectShapes} from '../../../util/yup';
import {makeConsumerInformation} from '../../../redux/factory/inventory/order';
import {isNullOrWhitespace} from '../../../util/string';
import {OrderItem, OrderItemOption} from '../../../redux/entities/inventory/joining/orderItem';

export const HealthProgramOrderSchema = object<Order>({
  id: string().nullable(),
  orderItems: array().min(1, 'An item needs to be selected for your order. Please search for an item or scroll down to an item, and enter an amount.')
    .test(
      'item-selected',
      () => 'An item needs to be selected for your order. Please search for an item or scroll down to an item, and enter an amount.',
      (val) => (val && val.find(i => i !== undefined && ((i.itemQuantity !== undefined && i.itemQuantity > 0) ||
        i.itemOptions?.find((io: OrderItemOption) => io !== undefined && io.quantity !== undefined && io.quantity > 0)))
      )
  ),
  consumerInformation: object().shape(createAllObjectShapes(makeConsumerInformation(), undefined, {
    emailAddress: string().required('Email is a required field.').email('Email must be in a valid format.'),
    streetAddressLineTwo: string().nullable(),
    signaturePath: string().nullable(),
    comment: string().nullable()
  }))
});
