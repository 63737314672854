import {ConsumerInformation, EOrderStatus, Order} from '../../entities/inventory/order';

export function makeOrder(existing?: Order): Order {
  return existing ?? {
    id: '',
    approvedById: '',
    consumerInformationId: '',
    orderStatus: EOrderStatus.Unapproved,
    orderItems: [],
    consumerInformation: makeConsumerInformation(),
    approvedAt: '',
    shippedAt: '',
    submittedAt: ''
  };
}

export function makeConsumerInformation(existing?: ConsumerInformation): ConsumerInformation {
  return existing ?? {
    id: '',
    organization: '',
    name: '',
    jobTitle: '',
    emailAddress: '',
    phoneNumber: '+1',
    streetAddress: '',
    streetAddressLineTwo: '',
    city: '',
    state: '',
    zip: '',
    departmentOrOutreach: '',
    beneficiaries: '',
    beneficiariesDescription: '',
    beneficiariesQuantity: '',
    signaturePath: '',
    comment: ''
  };
}

export const makePrefilledOrder = (): Order => {
  return {
    id: '',
    consumerInformationId: '',
    approvedById: '',
    consumerInformation: {
      id: '',
      name: 'Alex Sedlacek',
      organization: 'Raven Bridge Software',
      jobTitle: 'Project Manager',
      emailAddress: 'AKSedlacek@gmail.com',
      phoneNumber: '+15023335388',
      zip: '47111',
      departmentOrOutreach: 'Development',
      city: 'Charlestown',
      state: 'Indiana',
      beneficiaries: 'me',
      beneficiariesQuantity: 'Several',
      beneficiariesDescription: 'i need money',
      streetAddressLineTwo: 'N/A',
      streetAddress: '4107 Tunnel Mill Rd',
      signaturePath: '',
      comment: ''
    },
    orderItems: [],
    orderStatus: EOrderStatus.Unapproved,
    approvedAt: '',
    shippedAt: '',
    submittedAt: ''
  };
};
