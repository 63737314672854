import {combineReducers, PreloadedState} from 'redux';
import {commonReducers} from './index';
import {persistReducer, persistStore} from 'redux-persist';
// tslint:disable-next-line:no-submodule-imports
import storage from 'redux-persist/lib/storage';
import {info} from '../util/logger';
import {configureStore} from '@reduxjs/toolkit';

const configureSharedStore = <T>(initialState?: PreloadedState<T>, onReady?: () => void) => {
    const rootReducer = combineReducers({
        ...commonReducers
    });
    const root = persistReducer(persistConfig, rootReducer);
    const storeInstance = configureStore({
        reducer: root,
        devTools: process.env.NODE_ENV !== 'production'
      }
        /*enableBatching(root),
        initialState,
        compose(applyMiddleware(thunk as ThunkMiddleware<T, AnyAction>, ...middleware))*/
    );
    const persistorInstance = persistGlobalStore(storeInstance, onReady);
    return {store: storeInstance, persistor: persistorInstance, appDispatch: storeInstance.dispatch};
};

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['entities']
};

export function persistGlobalStore(storeInstance: any, onReady?: () => void) {
  return persistStore(storeInstance, null, () => {
    info('Store Re-hydrated. See state in redux dev-tools');
    if (onReady) {
      onReady();
    }
  });
}

export const {store, persistor} = configureSharedStore();
