import {isNaN, split} from 'lodash';

export const now = new Date();

export const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const localTzFriendlyFormat = localTz.replace('_', ' ');

const getTimezoneFromDate = (dateAsString: string) => dateAsString.match(/\(([^\)]+)\)$/)![1];

export const splitHTMLTimeInput = (militaryTime: string) => {
  return split(militaryTime, ':', 2).map((i) => {
    const int = parseInt(i, 10);
    if (isNaN(int)) {
      throw new Error('TimeNaN');
    }
    return int;
  });
};

export const setDateHoursAndMinutes = (date: Date, time: string) => {
  const parts = (time as any).split(':');
  date.setHours(parts[0]);
  date.setMinutes(parts[1]);
  return date;
};

export function seconds(numSeconds: number) {
  return numSeconds * 1000;
}
