import {Alert, Button, Row} from 'react-bootstrap';
import styles from './FormFooter.module.scss';
import {LoadingButton} from '../../widgets/LoadingButton/LoadingButton';
import React, {CSSProperties, SyntheticEvent, useCallback} from 'react';
import IconButton from '../../widgets/IconButton/IconButton';
import {Navigate} from 'react-router-dom';
import {combineCssClasses} from '../../../../util';
import {StandardEditorAdditionalButtonProps} from '../EditorForm/hooks';

interface FormFooterProps {
  title: string;
  backUrl?: string;
  editable: boolean;
  isNew: boolean;
  saving: boolean;
  onCancel: () => void;
  onEdit?: () => void;
  onClone?: () => void;
  onDelete?: () => void;
  errorMessage?: string;
  redirectUrl?: string;
  iconButtons: boolean;
  editButtonText?: string;
  saveButtonText?: string;
  deleteButtonText?: string;
  cancelButtonText?: string;
  submitButtonColor?: string;
  wrapperStyle?: CSSProperties;
  buttonWrapperStyle?: CSSProperties;
  additionalButtonsProps?: StandardEditorAdditionalButtonProps;
}

export function FormFooter(props: FormFooterProps) {
  const {editable, saving, onCancel, onClone, onDelete, redirectUrl, iconButtons, wrapperStyle, buttonWrapperStyle,
    editButtonText, saveButtonText, deleteButtonText, cancelButtonText, submitButtonColor, additionalButtonsProps} = props;
  const onEdit = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (props.onEdit) {
        props.onEdit();
      }
    }, [props]);

  const renderAdditionalButtons = () => (
    <>
      {additionalButtonsProps?.buttons.filter(b => !editable ? b.showView : b.showEdit).map((buttonProps, index) => {
        const {children, showView, showEdit, ...rest} = buttonProps;
        return ( <Button key={index} {...rest} className={styles['form-buttons']}>{children}</Button>);
      })}
    </>
  );

  const renderButtons = () => {
    if (!iconButtons) {
      return (
        <>
          {additionalButtonsProps?.position === 'left' && renderAdditionalButtons()}
          {onDelete && !editable && <Button onClick={onDelete} className={combineCssClasses(styles['btn-with-margin'], 'btn-danger')}>
            {deleteButtonText ?? 'Delete'}
          </Button>}
          {onClone && !editable && <IconButton size='2x' icon='copy' onClick={onClone} className={styles['btn-with-margin']}/>}
          {editable && <Button className={styles['form-buttons']} variant='danger' onClick={onCancel}>{cancelButtonText ?? 'Cancel'}</Button>}
          {additionalButtonsProps?.position === 'right' && renderAdditionalButtons()}
        </>
      );
    } else {
      return (
        <>
          {editable && <IconButton onClick={onCancel} icon={'times'} color={'#005A9C'} customSize={22}/>}
          {onDelete && !editable &&  <IconButton onClick={onDelete} icon={'minus'} color={'#005A9C'}/>}
        </>
      );
    }
  };

  // parent row to loading button was previously <Col className='d-flex align-items-center justify-content-start'>
  return (
    <>
      <Row style={{marginBottom: '1rem', marginTop: '1rem', ...wrapperStyle}}>
        <Row style={{display: 'flex', flex: '1', justifyContent: 'flex-start', ...buttonWrapperStyle}}>
          <LoadingButton
            loading={saving}
            label={editable ? saveButtonText ?? 'Save' : editButtonText ?? 'Edit'}
            className={iconButtons ? styles['form-button-icons'] : styles['form-buttons']}
            type={editable ? 'submit' : 'button'}
            disabled={saving || (!editable && !props.onEdit)}
            color={submitButtonColor}
            onClick={!editable ? onEdit : undefined}
            iconButtons={iconButtons}
          />
          {renderButtons()}
        </Row>
      </Row>
      <Alert show={!!props.errorMessage} variant='danger'>{props.errorMessage}</Alert>
      {redirectUrl ? <Navigate to={redirectUrl}/> : null}
    </>
  );
}
