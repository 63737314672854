import React from 'react';
import {Card, Col, Container} from 'react-bootstrap';
import styles from './PublicBody.module.scss';
import {Outlet} from 'react-router';
import {PageFooter} from '../PageFooter/PageFooter';
import {getLogoLink} from '../../../appTheme';
import {Link} from 'react-router-dom';
import {RoutePaths} from '../../../router/RoutePaths';
import {Tooltip} from 'react-tooltip';

export function PublicBody(props: {}) {

  return (
    <Container fluid={true} className={styles['container']}>
      <Tooltip id='tooltip-id-anchor' place='top' closeOnEsc={true} delayShow={100} style={{zIndex: 2000}}/>
        <Col className={styles['page-body']}>
          <Link to={RoutePaths.publicPaths.landing} style={{display: 'flex', justifyContent: 'center'}}>
            <img src={getLogoLink()} className={styles['logo']}/>
          </Link>
          <Card className={styles['content']}>
            <Card.Body className={styles['card-body']}>
              <Container style={{minHeight: '100%'}}>
                <Outlet/>
              </Container>
            </Card.Body>
          </Card>
          <PageFooter/>
        </Col>
    </Container>
  );
}
