import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Col, Form as BSForm, Modal, Row, Spinner} from 'react-bootstrap';
import {Item, itemStore} from '../../../../../../redux/entities/inventory/item';
import {propertyOf} from '../../../../../../util';
import {Dispatch, bindActionCreators} from 'redux';
import {AxiosError} from 'axios';
import {getErrorResponseMessage} from '../../../../../../util/http';
import {SubmitProps} from '../../../../../../types';
import {CommonState} from '../../../../../../redux';
import {connect} from 'react-redux';
import {categoryStore, CategoryTreeNode} from '../../../../../../redux/entities/inventory/category';
import {NodeApi, NodeRendererProps, Tree, TreeApi, useSimpleTree} from 'react-arborist';
import IconButton from '../../../../../../components/util/widgets/IconButton/IconButton';
import {Form} from 'react-bootstrap';
import useCategoryTree, {createCategoryTree} from '../../../../../../hooks/useCategoryTree';
import {AppTheme} from '../../../../../../appTheme';
import styles from './CategoriesModal.module.scss';
import {UpsertCategoriesRequest} from '../../../../../../api/inventory/inventoryApi';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';
import {isNullOrWhitespace} from '../../../../../../util/string';
import {flattenTreeNodes} from '../../../../../../util/array';
import {v4} from 'uuid';
import {ListTable} from '../../../../../../components/util/lists/ListTable/ListTable';
import {makeCategory} from '../../../../../../redux/factory/inventory/category';
import useMeasure from 'react-use-measure';
import {TopPaddedRow} from '../../../../../../components/util/form-components/standardFormikInputLayout';
import ItemModal from '../ItemModal/ItemModal';
import {useCustomSimpleTree} from '../../../../../../hooks/useCustomSimpleTree';
import {isEqual} from 'lodash';
import {makeItem} from '../../../../../../redux/factory/inventory/item';

type Props = SubmitProps & {
  show: boolean;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const getFieldName = propertyOf<Item>;

const CategoriesModal = (props: Props) => {
  const {show, onSubmit, onCancel, items, alphabeticallySortedItems, categories, getCategoryById, getItemById,
    actions: {upsertCategories, upsertItem}} = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [selectedItemId, setSelectedItemId] = useState<string>('');
  const [editingItemId, setEditingItemId] = useState<string | null>(null);

  const [addCategoryButtonRef, addCategoryButtonBounds] = useMeasure();
  const [treeContainerRef, treeContainerBounds] = useMeasure();
  const [simpleTree, handlers] = useCustomSimpleTree(createCategoryTree(categories), 'superCategoryId');
  const treeRef = useRef<TreeApi<CategoryTreeNode> | undefined>();
  const flattenedTree = useMemo(() => flattenTreeNodes(simpleTree, 'children'), [simpleTree, flattenTreeNodes]);

  const editedNodes = useMemo(() => flattenedTree
    .filter(newCat => categories.find(oldCat =>
          oldCat.id === newCat.id && (!isEqual(oldCat.name, newCat.name) || !isEqual(oldCat.superCategoryId, newCat.superCategoryId))
      )), [flattenedTree, categories]);
  const newNodes = () => flattenedTree.filter(fc => !categories.find(c => isEqual(c.id, fc.id)));
  const nodeIsEdited = (categoryId: string) => editedNodes.findIndex(c => c.id === categoryId) > -1;
  const nodeIsNew = (categoryId: string) => newNodes().findIndex(c => c.id === categoryId) > -1;
  const deletedNodes = categories.filter(c => !flattenedTree.find(tsc => c.id === tsc.id));

  const processSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsSubmitting(true);
    setErrorMessage('');
    e.persist();
    e.preventDefault();
    try {
      const request: UpsertCategoriesRequest = {updateList: [...editedNodes, ...newNodes()], deleteIds: deletedNodes.map(c => c.id)};
      await upsertCategories(request);
      onSubmit();
    } catch (e: AxiosError | any) {
      setErrorMessage(getErrorResponseMessage(e));
    }
    setIsSubmitting(false);
  };

  const renderButtons = () => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={onCancel} variant={'danger'} className={'modal-close-button'}>
            {'Cancel'}
          </Button>
        }
        {!isSubmitting ? <Button variant={'success'} type='submit' onClick={processSubmit}>Submit</Button> : null}
      </>
    );
  };

  const renderAddCategoryButton = (superCategoryId: string, size: SizeProp = '1x') =>
    <IconButton
      icon={'plus'}
      size={size}
      color={AppTheme.colors.privatePrimary}
      iconToolTipText={superCategoryId ? 'Add new subcategory' : 'Add new category'}
      styles={{marginLeft: 'auto'}}
      onClick={() => {
        treeRef.current?.create({type: 'leaf', parentId: superCategoryId});
      }}
    />;

  const renderNameInput = (nodeApi: NodeApi<CategoryTreeNode>) => {
    return (
      <div>
      <Form.Control
        as={'input'}
        autoFocus={true}
        type={'text'}
        defaultValue={!isNullOrWhitespace(nodeApi.data.name) ? nodeApi.data.name : 'New Category'}
        onBlur={() => nodeApi.reset()}
        onSubmit={(e) => isNullOrWhitespace(e.currentTarget.value) && nodeApi.tree.delete(nodeApi.id)}
        onFocus={(e) => e.currentTarget.select()}
        style={{display: 'flex', height: '25px'}}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') {
            nodeApi.submit(e.currentTarget.value);
          }
          if (e.key === 'Escape') nodeApi.reset();
        }}
      />
      </div>
    );
  };

  const renderNodeActions = (nodeApi: NodeApi<CategoryTreeNode>) => {
    return (
      <Row style={{margin: 'auto 5px auto auto'}}>
        <div style={{paddingLeft: '5px'}}>
          {renderAddCategoryButton(nodeApi.data.id)}
        </div>
        <div style={{paddingLeft: '5px'}}>
          <IconButton
            icon={'pen'}
            size={'1x'}
            iconToolTipText={'Rename category'}
            color={AppTheme.colors.logoBlue}
            onClick={async () => await nodeApi.edit()}
          />
        </div>
        <div style={{paddingLeft: '5px'}}>
          <IconButton
            icon={'trash-alt'}
            size={'1x'}
            iconToolTipText={'Remove category and subcategories (does not remove items from database)'}
            color={AppTheme.colors.danger}
            onClick={async () => {
              await handlers.onDelete({ids: [nodeApi.id], nodes: [nodeApi]});
            }}
          />
        </div>
      </Row>
    );
  };

  function treeNode({ node, style, dragHandle }: NodeRendererProps<CategoryTreeNode>) {
    return (
      <Row
        ref={dragHandle}
        style={{...style, backgroundColor: node.isSelected ? AppTheme.colors.publicPrimary : ''}}
      >
        <Col style={{display: 'flex', flexDirection: 'column', flex: '1'}}>
          <Row className={styles['node-row']}>
            <div style={{marginRight: '15px'}}>
              <IconButton
                icon={!node.isOpen ? 'caret-down' : 'caret-right'}
                styles={{color: AppTheme.colors.logoBlue, fontSize: `${treeContainerBounds.height / 720}rem`}}
                size={'1x'}
                onClick={() => {
                  node.toggle();
                  setSelectedCategoryId(node.data.id);
                }}
              />
            </div>
            {
              node.isEditing ?
                renderNameInput(node) :
                <div
                  style={{
                    fontSize: '1rem',
                    color: nodeIsNew(node.data.id) ? AppTheme.colors.success : (
                      nodeIsEdited(node.data.id) ? AppTheme.colors.warning : 'black'),
                    marginTop: 'auto', marginBottom: '2px'}}
                >
                  {node.data.name}
                </div>
            }
          </Row>
        </Col>
          <div className={styles['node-row']} style={{zIndex: '1000'}}>
            {node.isSelected && renderNodeActions(node)}
          </div>
      </Row>
    );
  }

  const getItemBoxTitle = () => {
    const categoryName = getCategoryById(selectedCategoryId)?.name;
    if (!categoryName) return `Selected Category's Items`;
    return categoryName.charAt(categoryName.length - 1).toUpperCase() === 'S' ?
      `${categoryName}' Items` : `${categoryName}'s Items`;
  };

  return (
    <Modal show={show} centered={true} size={'xl'} style={{opacity: editingItemId === null ? '100%' : '0%'}}>
      <Modal.Body>
        <Modal.Title>Edit Categories</Modal.Title>
        <Col style={{minHeight: '20rem'}}>
          <TopPaddedRow style={{height: '100%', flexWrap: 'nowrap'}}>
            <Col className={styles['tree-container']} ref={treeContainerRef}>
              <Row ref={addCategoryButtonRef}>
                <h5>Categories</h5>{renderAddCategoryButton('')}
              </Row>
              <div style={{paddingTop: '10px'}}>
                <Tree<CategoryTreeNode>
                  ref={treeRef}
                  data={simpleTree}
                  idAccessor={'id'}
                  childrenAccessor={(category) => category.children}
                  onCreate={handlers.onCreate}
                  onDelete={handlers.onDelete}
                  onRename={handlers.onRename}
                  onMove={handlers.onMove}
                  onSelect={nodes => nodes.length ? setSelectedCategoryId(nodes[0].id) : setSelectedCategoryId('')}
                  disableMultiSelection={true}
                  openByDefault={true}
                  rowHeight={treeContainerBounds.height / 24}
                  height={treeContainerBounds.height - addCategoryButtonBounds.height - 10}
                  width={treeContainerBounds.width}
                  selectionFollowsFocus={true}
                  className={styles['tree']}
                >
                  {treeNode}
                </Tree>
              </div>
            </Col>
            <Col className={styles['tree-container']} style={{marginLeft: '2rem', flex: '0.6', minWidth: '10rem'}}>
              <Row>
                <h5>{getItemBoxTitle()}</h5>
                {selectedCategoryId ? <IconButton
                  icon={'plus'}
                  onClick={() => setEditingItemId('')}
                  color={AppTheme.colors.logoGreen}
                  styles={{marginLeft: 'auto', marginTop: '5px'}}
                /> : null}
              </Row>
              <div style={{paddingTop: '10px', height: '100%', fontSize: '0.95rem'}}>
                <ListTable
                  items={alphabeticallySortedItems.filter(i => i.categoryId === selectedCategoryId)}
                  renderItem={(i, index) => (
                    <Row
                      onClick={() => setSelectedItemId(i.id)}
                      style={{
                        padding: '2px 5px 2px 1rem',
                        borderTopLeftRadius: !index ? '5px' : undefined,
                        borderTopRightRadius: !index ? '5px' : undefined,
                        backgroundColor: selectedItemId === i.id ?  'rgb(131, 208, 228)' : undefined
                      }}
                    >
                      {i.name}
                      {
                        selectedItemId === i.id &&
                        <IconButton
                          icon={'pen'}
                          iconToolTipText={'Edit item'}
                          color={AppTheme.colors.logoBlue}
                          styles={{marginLeft: 'auto'}}
                          onClick={() => setEditingItemId(i.id)
                        }/>
                      }
                    </Row>
                  )}
                  noItemsLabel={'No items are present in this category. This category will not be shown to clients when ordering.'}
                  style={{width: '100%', height: treeContainerBounds.height - addCategoryButtonBounds.height - 8, overflow: 'auto'}}
                  className={styles['items-list']}
                />
              </div>
            </Col>
          </TopPaddedRow>
          {
            editingItemId !== null ?
              <ItemModal
                show={true}
                existing={getItemById(editingItemId)}
                onSubmit={async (item) => {
                  await upsertItem({...item, categoryId: selectedCategoryId} as Item);
                  setEditingItemId(null);
                }}
                onCancel={() => setEditingItemId(null)}
                predeterminedCategoryId={selectedCategoryId}
                selectable={true}
                title={`Add Item to Category "${getCategoryById(selectedCategoryId)?.name}"`}
              />
              : null
          }
          <TopPaddedRow>
            <BSForm.Group className={'modal-buttons'}>
              {renderButtons()}
            </BSForm.Group>
          </TopPaddedRow>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertCategory: categoryStore.actions.upsert,
    upsertCategories: categoryStore.actions.upsertMany,
    upsertItem: itemStore.actions.upsert
  }, dispatch)});
const mapStateToProps = (state: CommonState) => ({
  items: itemStore.selectors.getAsArray(state),
  alphabeticallySortedItems: itemStore.selectors.alphabeticallySortedItems(state),
  getItemById: itemStore.selectors.getById(state),
  categories: categoryStore.selectors.getAsArray(state),
  getCategoryById: categoryStore.selectors.getById(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(CategoriesModal);
