import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faArchive,
  faBars,
  faBox,
  faBoxOpen,
  faCancel,
  faCheck,
  faCopy,
  faEdit,
  faEye,
  faFile,
  faFileLines,
  faMinus,
  faPen,
  faPlus,
  faQuestionCircle,
  faSearch,
  faSignOutAlt,
  faSpinner,
  faTrashAlt,
  faUser,
  faUserLock,
  faUserPlus,
  faUsers,
  faFolder,
  faCaretDown,
  faCaretRight,
  faTruck,
  faX, faPrint, faComment, faCommentAlt
} from '@fortawesome/free-solid-svg-icons';

export function configureFontAwesome(): void {
  library.add(
    faSearch,
    faTrashAlt,
    faEdit,
    faSignOutAlt,
    faCheck,
    faBars,
    faCopy,
    faSpinner,
    faUserPlus,
    faArchive,
    faUsers,
    faMinus,
    faPlus,
    faBox,
    faBoxOpen,
    faPen,
    faUser,
    faQuestionCircle,
    faFile,
    faFileLines,
    faCancel,
    faUserLock,
    faFolder,
    faCaretDown,
    faCaretRight,
    faTruck,
    faX,
    faPrint,
    faComment,
    faCommentAlt,
    faEye
  );
}
