import React from 'react';
import {mapIsAdministratorToProps, mapIsAdministratorToPropsType} from '../../../redux/entities/user';
import {connect} from 'react-redux';

type AdministratorRouteProps = mapIsAdministratorToPropsType & {
  children: JSX.Element;
};

function AdministratorComponent({children, administrator}: AdministratorRouteProps) {
  return administrator ? children : null;
}

export default connect(mapIsAdministratorToProps)(AdministratorComponent);
