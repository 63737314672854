import {date, object, string} from 'yup';
import {UserUpsert} from './UserModal';


// @ts-ignore
export const UserModalSchema = object<UserUpsert>({
  id: string().nullable(),
  email: string().nullable().email('Email must be a valid format'),
  name: string().required('Name is required'),
  primaryPhone: string().nullable(),
  secondaryPhone: string().nullable(),
  address: string().nullable(),
  addressLineTwo: string().nullable(),
  city: string().nullable(),
  state: string().nullable(),
  zip: string().nullable()
});
