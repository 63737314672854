import React from 'react';
import {Card, Col} from 'react-bootstrap';
import styles from './AuthBody.module.scss';
import {Outlet} from 'react-router';
import {PageFooter} from '../PageFooter/PageFooter';
import SosGlobe from '../../../assets/images/sos_globe_stock.jpg';
import {getLogoLink} from '../../../appTheme';

export function AuthBody(props: {}) {

  return (
    <div className={styles['container']}>
      <div className={styles['page-body']}>
          <div className={styles['auth-content']}>
            <Card className={'fill-col'} style={{borderRadius: 0}}>
              <Card.Body style={{display: 'flex'}}>
                <Col className={'fill-col'} style={{justifyContent: 'center', alignItems: 'center', paddingBottom: '2rem'}}>
                  <img src={getLogoLink()} className={styles['logo']}/>
                  <Outlet/>
                </Col>
              </Card.Body>
            </Card>
          </div>
        <div>
          <div className={styles['image-container']}>
            <img src={SosGlobe} className={styles['background-image']}/>
          </div>
        </div>
        <div className={styles['footer']}>
          <PageFooter sosVariant={true}/>
        </div>
      </div>
    </div>
  );
}
