import {Navigate, Outlet} from 'react-router';
import React, {ReactNode} from 'react';
import {mapIsAuthenticatedToProps, mapIsAuthenticatedToPropsType} from '../../../redux/entities/user';
import {connect} from 'react-redux';
import {useLocation} from 'react-router-dom';

type PrivateRouteProps = mapIsAuthenticatedToPropsType & {
  authStateToRedirect: boolean;
  redirectRoute: string;
  children?: ReactNode;
};

function AuthenticationGatedRoute({authenticated, authStateToRedirect, redirectRoute, children}: PrivateRouteProps) {
  const location = useLocation();
  const redirect = authenticated === authStateToRedirect;
  return (
    redirect ? <Navigate to={redirectRoute} state={{ from: location }} replace={true}/> : children ? <>{children}</> : <Outlet/>
  );
}

export default connect(mapIsAuthenticatedToProps)(AuthenticationGatedRoute);
