import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../../../utils';
import {createStandardSelectors, getEntities, selector} from '../../../selectors';
import {combineReducers} from 'redux';

export interface ItemCategory {
  id: string;
  categoryId: string;
  itemId: string;
}

const actions = createStandardActions(placeholder<ItemCategory>(), 'ITEM_CATEGORY/SET', 'ITEM_CATEGORY/SAVE');
const selectors = createStandardSelectors(placeholder<ItemCategory>(), s => getEntities(s).itemCategories);
export type ItemCategoryActions = GetActions<typeof actions>;
export const itemCategories = combineReducers({items: standardItemsReducer<ItemCategory, ItemCategoryActions>(actions)});
export const itemCategoryStore = readonly({
  selectors: {
    ...selectors
  },
  actions: {
    ...actions
  }
});
