import * as React from 'react';
import {createBrowserRouter} from 'react-router-dom';
import AuthenticationGatedRoute from '../components/util/AuthenticationGatedRoute/AuthenticationGatedRoute';
import Login from '../pages/auth/Login/Login';
import {RoutePaths} from './RoutePaths';
import LogOut from '../pages/auth/Logout/Logout';
import Home from '../pages/private/Home/Home';
import ForgotPassword from '../pages/auth/ForgotPassword/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword/ResetPassword';
import NotFound from '../pages/NotFound/NotFound';
import {Landing} from '../pages/public/Landing/Landing';
import {PublicBody} from '../components/layout/PublicBody/PublicBody';
import {AuthBody} from '../components/layout/AuthBody/AuthBody';
import HealthProgramOrder from '../pages/public/HealthProgramOrder/HealthProgramOrder';
import PageHeader from '../components/layout/PrivateBody/PrivateBody';
import UserManagement from '../pages/private/Configuration/UserManagement/UserManagement';
import Profile from '../pages/private/Profile/Profile';
import OrderManagement from '../pages/private/Configuration/OrderManagement/OrderManagement';
import InventoryManagement from '../pages/private/Configuration/InventoryManagement/InventoryManagement';
import PrivateBody from '../components/layout/PrivateBody/PrivateBody';
import {AdministratorRoute} from '../components/util/AdministratorRoute/AdministratorRoute';
import {MessageManagement} from "../pages/private/Configuration/MessageManagement";


const AppRoutes = createBrowserRouter([
  // private / staff
  {
    path: RoutePaths.privateRoot,
    element:
      <AuthenticationGatedRoute authStateToRedirect={false} redirectRoute={RoutePaths.authPaths.login}>
        <PrivateBody/>
      </AuthenticationGatedRoute>,
    children: [
      {path: RoutePaths.privatePaths.configurationPaths.userManagement, element: <AdministratorRoute><UserManagement/></AdministratorRoute>},
      {path: RoutePaths.privatePaths.configurationPaths.inventoryManagement, element: <AdministratorRoute><InventoryManagement/></AdministratorRoute>},
      {path: RoutePaths.privatePaths.configurationPaths.orderManagementQuery, Component: OrderManagement},
      {path: RoutePaths.privatePaths.configurationPaths.messageManagement, element: <AdministratorRoute><MessageManagement/></AdministratorRoute>},
      {path: RoutePaths.privatePaths.profilePaths.EditPassword, Component: Profile},
      {path: RoutePaths.privatePaths.profilePaths.View, Component: Profile},
      {path: RoutePaths.privatePaths.profilePaths.Edit, Component: Profile}

    ]
  },
  // public
  {
    path: RoutePaths.publicRoot,
    element:
      <AuthenticationGatedRoute authStateToRedirect={true} redirectRoute={RoutePaths.privatePaths.configurationPaths.orderManagement}>
        <PublicBody/>
      </AuthenticationGatedRoute>,
    children: [
      {path: RoutePaths.publicPaths.order, Component: HealthProgramOrder},
      {path: RoutePaths.publicRoot, Component: Landing}
    ]
  },
  // logout - could be under private, but here for clarity
  {path: RoutePaths.authPaths.logout, Component: LogOut},
  // public auth
  {
    path: RoutePaths.authRoot,
    element:
      <AuthenticationGatedRoute authStateToRedirect={true} redirectRoute={RoutePaths.privatePaths.configurationPaths.orderManagement}>
        <AuthBody/>
      </AuthenticationGatedRoute>,
    children: [
      {path: RoutePaths.authPaths.login, Component: Login},
      {path: RoutePaths.authPaths.forgotPassword, Component: ForgotPassword},
      {path: RoutePaths.authPaths.resetPassword, Component: ResetPassword}
    ]
  },
  // any other route - not found
  {path: RoutePaths.all, Component: NotFound}
]);

export default AppRoutes;
