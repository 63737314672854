import React, {useState} from 'react';
import styles from '../Login/Login.module.scss';
import {LoadingButton} from '../../../components/util/widgets/LoadingButton/LoadingButton';
import {Alert} from 'react-bootstrap';
import {Form, Formik, FormikProps} from 'formik';
import Input from '../../../components/util/form-components/formik-inputs/Input/Input';
import Row from 'react-bootstrap/Row';
import {forgotPassword} from '../../../api/authApi';
import {Link} from 'react-router-dom';
import {RoutePaths} from '../../../router/RoutePaths';
import {getErrorResponseMessage, isBadRequest, isServerError, isUnknownError} from '../../../util/http';
import {propertyOf} from '../../../util';
import ForgotPasswordSchema from './ForgotPasswordSchema';

interface ResetPasswordForm {
  email: string;
}

function ForgotPassword() {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async (form: ResetPasswordForm) => {
    setErrorMessage('');
    setSubmitting(true);
    try {
      const msg = await forgotPassword(form.email);
      setSuccessMessage(msg);
      setSubmitted(true);
    } catch (e) {
      if (isBadRequest(e)) {
        setErrorMessage(getErrorResponseMessage(e));
        setSubmitting(false);
      } else if (isUnknownError(e) || isServerError(e)) {
        setErrorMessage(isUnknownError(e) ? 'Could not make a connection!' : 'A server error has occurred');
        setSubmitting(false);
      }
    }
  };

  return !submitted ?
    <>
      <Formik<ResetPasswordForm>
        enableReinitialize={true}
        onSubmit={onSubmit}
        initialValues={{email: ''} as ResetPasswordForm}
        validationSchema={ForgotPasswordSchema}
      >
        {({handleSubmit}: FormikProps<ResetPasswordForm>) => (
          <Form onSubmit={handleSubmit}>
            <Input name={propertyOf<ResetPasswordForm>('email')} disabled={submitting} placeholder={'Email'}/>
            <Row className={styles['login-button']}>
              <LoadingButton type='submit' loading={submitting} label='Submit'/>
            </Row>
            {errorMessage ?
              <Alert style={{marginTop: '1rem'}} variant='danger'>{errorMessage}</Alert> : null}
          </Form>
        )}
      </Formik>
      <Link to={RoutePaths.authPaths.login} style={{marginTop: '1rem'}}>Back to login</Link>
      </>
    :
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <label style={{textAlign: 'center'}}>{successMessage}</label>
        <div className={styles['forgot-password']}>
          <Link to={RoutePaths.authPaths.login}>Return to Login</Link>
        </div>
      </div>;
}

export default ForgotPassword;
