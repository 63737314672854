import {ConsumerInformation, EOrderStatus, Order, orderStore} from '../../../../../redux/entities/inventory/order';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {connect} from 'react-redux';
import gridStyles from '../../../../GridStyles.module.scss';
import {bindActionCreators, Dispatch} from 'redux';
import {ConfirmationDialog} from '../../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import IconButton from '../../../../../components/util/widgets/IconButton/IconButton';
import {Button, Col, Row} from 'react-bootstrap';
import BootstrapTable, {BootstrapTableProps, ColumnDescription, SearchProps} from 'react-bootstrap-table-next';
// @ts-ignore @formatter:off
import ToolkitProvider, {ColumnToggle, Search, ToggleListProps} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {Navigate, useLocation, useParams, useSearchParams} from 'react-router-dom';
import {format, utcToZonedTime} from 'date-fns-tz';
import {parseISO} from 'date-fns';
import {CommonState} from '../../../../../redux';
import {localTz, localTzFriendlyFormat} from '../../../../../util/date';
import {propertyOf} from '../../../../../util';
import {formatIfPossibleUsPhone, isNullOrWhitespace} from '../../../../../util/string';
import OrderModal from './OrderModal';
import {AppTheme} from '../../../../../appTheme';
import {AxiosError} from 'axios/index';
import {getErrorResponseMessage} from '../../../../../util/http';
import Select from 'react-select';
// @ts-ignore @formatter:off
import paginationFactory, {PaginationListStandalone, PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone} from 'react-bootstrap-table2-paginator';
import useStandardPrint from '../../../../../hooks/useStandardPrint/useStandardPrint';

interface TFilterDD {value: 'all' | 'unapproved' | 'approved' | 'shipped' | 'cancelled' | 'notCancelled'; label: string;}

const orderFilterDropdownOptions: TFilterDD[] = [
  {value: 'all', label: 'All Orders'},
  {value: 'unapproved', label: 'Unapproved Orders'},
  {value: 'approved', label: 'Approved Orders'},
  {value: 'shipped', label: 'Shipped Orders'},
  {value: 'cancelled', label: 'Cancelled Orders'},
  {value: 'notCancelled', label: 'Do Not Show Cancelled Orders'}
];

const defaultFilterValue = orderFilterDropdownOptions[5];

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function OrderGrid(props: Props) {
  const {orders, getOrderById, unapprovedOrders, approvedOrders, shippedOrders, cancelledOrders, notCancelledOrders,
    actions: {updateOrderStatus, cancelOrder, deleteOrder}} = props;
  const { SearchBar } = Search;
  const {ToggleList} = ColumnToggle;
  const [isDeleting, setIsDeleting] = useState('');
  const location = useLocation();
  const {id : paramsId} = useParams();
  const [editingOrderData, setEditingOrderData] = useState<{editing: boolean; id: string}>(
    {editing: !isNullOrWhitespace(paramsId), id: paramsId ?? ''});
  const [showColumnToggleFilters, setShowColumnToggleFilters] = useState(false);
  const [editingStatusOrder, setEditingStatusOrder] = useState<Order | null>(null);
  const [cancellingOrder, setCancellingOrder] = useState<Order | null>(null);
  const [deletingOrder, setDeletingOrder] = useState<Order | null>(null);
  const [searchParams] = useSearchParams();
  const [orderFilter, setOrderFilter] = useState<TFilterDD>(defaultFilterValue);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const printRef = useRef(null);
  const handleprint = useStandardPrint(printRef);

  const getFieldName = propertyOf<Order>;
  const getConsumerInfoName = (value: keyof ConsumerInformation) => `${getFieldName('consumerInformation')}.${value}`;

  const filteredOrders = useMemo(() => {
    switch (orderFilter.value) {
      case 'unapproved':
        return unapprovedOrders;
      case 'approved':
        return approvedOrders;
      case 'shipped':
        return shippedOrders;
      case 'cancelled':
        return cancelledOrders;
      case 'notCancelled':
        return notCancelledOrders;
      default: return orders;
    }
  }, [orderFilter, unapprovedOrders, approvedOrders, shippedOrders, cancelledOrders, orders]);

  const processStatusSubmit = async (order: Order) => {
    try {
      await updateOrderStatus(order.id, order.orderStatus + 1);
    } catch (e: AxiosError | any) {
      setErrorMessage(getErrorResponseMessage(e));
    }
  };

  const renderDeletionModal = () => {
    if (!deletingOrder) return null;
    return (
      <ConfirmationDialog
        open={true}
        onAccept={async () => {
          await deleteOrder(deletingOrder.id);
          setDeletingOrder(null);
        }}
        onDecline={async () => setDeletingOrder(null)}
        prompt={`Are you sure you would like to permenantly delete ${deletingOrder.consumerInformation.organization}'s
         order? This CANNOT be undone, it is recommended to leave orders cancelled.`}
        positiveText={`Delete order`}
        negativeText={'Cancel'}
        positiveVariant={'warning'}
        negativeVariant={'danger'}
      />
    );
  };

  const renderCancellationModal = () => {
    if (!cancellingOrder) return null;
    return (
      <ConfirmationDialog
        open={true}
        onAccept={async () => {
          await cancelOrder(cancellingOrder.id);
          setCancellingOrder(null);
        }}
        onDecline={async () => setCancellingOrder(null)}
        prompt={`Are you sure you would like to cancel ${cancellingOrder.consumerInformation.organization}'s
         order? This can be undone.`}
        positiveText={`Mark as cancelled`}
        negativeText={'Cancel'}
        positiveVariant={'success'}
        negativeVariant={'danger'}
      />
    );
  };

  const renderStatusSubmitModal = () => {
    if (!editingStatusOrder) return null;
    const text = editingStatusOrder.orderStatus === EOrderStatus.Unapproved ? 'approved' : 'shipped';
    return (
      <ConfirmationDialog
        open={true}
        onAccept={async () => {
          await processStatusSubmit(editingStatusOrder);
          setEditingStatusOrder(null);
        }}
        onDecline={async () => setEditingStatusOrder(null)}
        prompt={`Are you sure you would like to update ${editingStatusOrder.consumerInformation.organization}'s
         order to the status "${text}?"`}
        positiveText={`Mark as ${text}`}
        negativeText={'Cancel'}
        positiveVariant={'success'}
        negativeVariant={'danger'}
      />
    );
  };

  const renderRedirect = () => {
    if (redirectUrl.length !== 0) {
      return <Navigate to={redirectUrl} />;
    }
    return null;
  };

  const onColumnToggle = (col: ColumnDescription<Order>) => {
    const updatedTableColumns = columns.map((value) => value.dataField === col.dataField ? {...value, hidden: !col.hidden} : value);
    const visibleRecordInfo = updatedTableColumns.filter((value) => (!value.hidden || false) && !col.isDummyField).length > 1;
    setColumns(visibleRecordInfo ? updatedTableColumns : columns);
  };

  const FilteredToggleList = ({toggles}: {toggles: boolean[]}) => (
    <div className={gridStyles['filters-button-group']}>
      {
        columns
          .filter(col => !(col.isDummyField || col.dataField === (getFieldName('id'))))
          .map(column => ({...column, toggle: toggles[column.dataField]}))
          .map(column => {
            const text = () => {
              if (column.text.includes('(')) {
                const index = column.text.indexOf('(');
                return column.text.substring(0, index);
              }
              return column.text;
            };
            return (
              <Button
                variant={column.toggle ? 'success' : 'secondary'}
                key={ column.dataField }
                onClick={ () => onColumnToggle(column) }
              >
                {text()}
              </Button>
            );
          })
      }
    </div>
  );

  const getDisabledToolTipText = (order: Order) => {
    if (order.orderStatus === EOrderStatus.Cancelled)
      return 'Order is cancelled';
    if (order.orderStatus === EOrderStatus.Unapproved)
      return 'Order needs approval first';
    if (order.orderStatus === EOrderStatus.Approved)
      return 'Order has already been approved';
    return 'Order has already been shipped';
  };

  const actionsFormatter = useCallback((cell: any, order: Order) => {
    const approveEnabled = order.orderStatus === EOrderStatus.Unapproved;
    const shipEnabled = order.orderStatus === EOrderStatus.Approved;
    return (
      <div className={gridStyles['table-actions-wrapper']}>
        {order.orderStatus !== EOrderStatus.Cancelled ? (
          <>
            <div>
              <IconButton
                icon={'pen'}
                styles={{color: AppTheme.colors.logoBlue, marginLeft: '.5rem'}}
                size={'1x'}
                iconToolTipText={'Edit'}
                onClick={() => setEditingOrderData({editing: true, id: order.id})}
              />
            </div>
            <div>
              <IconButton
                icon={'x'}
                styles={{color: AppTheme.colors.danger, marginLeft: '.5rem'}}
                size={'1x'}
                iconToolTipText={'Cancel'}
                onClick={() => setCancellingOrder(order)}
              />
            </div>
            <div>
              <IconButton
                icon={'check'}
                styles={{color: approveEnabled ? AppTheme.colors.logoGreen : AppTheme.colors.gray400, marginLeft: '.5rem'}}
                size={'1x'}
                disabled={!approveEnabled}
                iconToolTipText={approveEnabled ? 'Mark as approved' : getDisabledToolTipText(order)}
                onClick={() => setEditingStatusOrder(order)}
              />
            </div>
            <div>
              <IconButton
                icon={'truck'}
                styles={{color: shipEnabled ? AppTheme.colors.logoGreen : AppTheme.colors.gray400, marginLeft: '.5rem'}}
                size={'1x'}
                disabled={order.orderStatus !== EOrderStatus.Approved}
                iconToolTipText={order.orderStatus === EOrderStatus.Approved ? 'Mark as shipped' : getDisabledToolTipText(order)}
                onClick={() => setEditingStatusOrder(order)}
              />
            </div>
        </> ): (
          <>
            <div>
              <IconButton
                icon={'pen'}
                styles={{color: AppTheme.colors.logoBlue, marginLeft: '.5rem'}}
                size={'1x'}
                iconToolTipText={'Edit'}
                onClick={() => setEditingOrderData({editing: true, id: order.id})}
              />
            </div>
            <div>
              <IconButton
                icon={'trash-alt'}
                styles={{color: AppTheme.colors.danger, marginLeft: '.5rem'}}
                size={'1x'}
                iconToolTipText={'Permenantly delete order'}
                onClick={() => setDeletingOrder(order)}
              />
            </div>
          </>
    )}
      </div>
    );
  }, []);

  const dateTimeFormatter = (isoDateTime: string) => {
    if (isNullOrWhitespace(isoDateTime)) return '';
    return format(utcToZonedTime(parseISO(isoDateTime), localTz as any as string), 'MM/dd/yyyy hh:mm:ss a', {timeZone: localTz as any as string});
  };

  const [columns, setColumns] = useState<ColumnDescription<Order>[]>([
    {
      dataField: getFieldName('id'),
      text: 'ID',
      sort: true,
      searchable: false,
      hidden: true
    },
    {
      dataField: getConsumerInfoName('organization'),
      text: 'Organization',
      sort: true,
      searchable: true
    },
    {
      dataField: getConsumerInfoName('phoneNumber'),
      text: 'Phone',
      sort: true,
      searchable: true,
      hidden: true,
      formatter: (cell, order) => formatIfPossibleUsPhone(order.consumerInformation.phoneNumber)
    },
    {
      dataField: 'address',
      text: 'Address',
      sort: true,
      searchable: true,
      hidden: true,
      formatter: (cell, row) => `${row.consumerInformation.streetAddress} ${row.consumerInformation.streetAddressLineTwo ? ` ${row.consumerInformation.streetAddressLineTwo}` : ''} ` +
        `${row.consumerInformation.city}, ${row.consumerInformation.state}, ${row.consumerInformation.zip}`
    },
    {
      dataField: getFieldName('orderStatus'),
      text: 'Status',
      sort: true,
      searchable: true,
      formatter: (cell, row) => EOrderStatus[row.orderStatus]
    },
    {
      dataField: getFieldName('submittedAt'),
      text: `Submitted At (${localTzFriendlyFormat})`,
      sort: true,
      searchable: true,
      formatter: (cell, row) => dateTimeFormatter(row.submittedAt)
    },
    {
      dataField: getFieldName('approvedAt'),
      text: `Approved At (${localTzFriendlyFormat})`,
      sort: true,
      searchable: true,
      hidden: true,
      formatter: (cell, row) => dateTimeFormatter(row.approvedAt)
    },
    {
      dataField: getFieldName('shippedAt'),
      text: `Shipped At (${localTzFriendlyFormat})`,
      sort: true,
      searchable: true,
      hidden: true,
      formatter: (cell, row) => dateTimeFormatter(row.shippedAt)
    },
    {
      dataField: 'total',
      text: 'Total Items',
      sort: true,
      searchable: true,
      formatter: (cell, row) => row.orderItems.reduce<number>((acc, oi) =>
        acc + oi.itemQuantity + oi.itemOptions.reduce<number>((a, io) => a + io.quantity, 0), 0)
    },
    {
      dataField: 'unique',
      text: 'Unique Items',
      sort: true,
      searchable: true,
      formatter: (cell, row) => row.orderItems.length
    },
    {
      dataField: 'actionsColumn',
      text: 'Actions',
      searchable: false,
      formatter: (cell, row, rowIndex, formatExtraData) => actionsFormatter(cell, row),
      headerStyle: () => {
        return { width: 0};
      },
      style: () => {
        return { width: 0};
      }
    }
  ]);

  return (
    <PaginationProvider
      pagination={ paginationFactory({
        custom: true,
        totalSize: filteredOrders.length,
        sizePerPage: 20,
        sizePerPageList: [ {
          text: '5 rows', value: 5
        }, {
          text: '10 rows', value: 10
        }, {
          text: '20 rows', value: 20
        }, {
          text: '30 rows', value: 30
        }, {
          text: '50 rows', value: 50
        }, {
          text: '100 rows', value: 100
        }, {
          text: 'All rows', value: filteredOrders.length
        } ]
      }) }
      keyField='id'
      columns={ columns }
      data={ filteredOrders }
    >
      {({paginationProps, paginationTableProps}: any) => (
        <ToolkitProvider
          keyField='id'
          data={filteredOrders}
          columns={columns}
          search={{
            searchFormatted: true
          }}
          columnToggle={true}
        >
          {(tableProps:
              { baseProps: JSX.IntrinsicAttributes & JSX.IntrinsicClassAttributes<BootstrapTable<Order, number>> & Readonly<BootstrapTableProps>;
                searchProps: SearchProps<Order>;
                columnToggleProps: ToggleListProps;}) => (
            <>
              <div className={gridStyles['button-groups-container']}>
                <div className={gridStyles['button-group']}>
                  <Button onClick={handleprint} style={{backgroundColor: AppTheme.colors.logoBlue}}>
                    Print Table
                  </Button>
                  <Button style={{backgroundColor: AppTheme.colors.logoBlue}} onClick={() => setShowColumnToggleFilters(!showColumnToggleFilters)}>
                    {showColumnToggleFilters ? 'Hide' : 'Show'} Column Filters
                  </Button>
                  <Select
                    menuPlacement={'auto'}
                    value={orderFilter}
                    options={orderFilterDropdownOptions}
                    isClearable={false}
                    isOptionSelected={(val) => val === orderFilter}
                    isSearchable={false}
                    onChange={(newValue) => setOrderFilter(newValue ?? defaultFilterValue)}
                    menuPortalTarget={document.querySelector('body')}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 3000, overflowY: 'visible', transform: 'translate(0, -8px)'}),
                      control: base => ({...base, minWidth: '12rem'})
                    }}
                  />
                </div>
                {showColumnToggleFilters ?
                  <div className={gridStyles['button-group']}>
                    <FilteredToggleList {...tableProps.columnToggleProps}/>
                  </div>
                  : null
                }
              </div>
              <Col style={{padding: '0'}}>
                <Row>
                  {renderRedirect()}
                  <div className={gridStyles['search-bar']}>
                    <SearchBar {...tableProps.searchProps} className={gridStyles['search-bar']}/>
                  </div>
                  <PaginationListStandalone { ...paginationProps } className={gridStyles['pagination-list']}/>
                  <SizePerPageDropdownStandalone{ ...paginationProps } className={gridStyles['page-dropdown']}/>
                  <div className={gridStyles['pagination-total']}>
                    <PaginationTotalStandalone { ...paginationProps }/>
                  </div>
                </Row>
                <div ref={printRef}>
                  <BootstrapTable
                    wrapperClasses={gridStyles['responsive-table-wrapper']}
                    classes={gridStyles['table-auto']}
                    rowStyle={{height: '100%'}}
                    {...tableProps.baseProps}
                    {...paginationTableProps}
                    striped={true}
                  />
                </div>
                {getOrderById(editingOrderData.id) ? <OrderModal
                  show={editingOrderData.editing}
                  order={getOrderById(editingOrderData.id)}
                  onSubmit={() => setEditingOrderData({editing: false, id: ''})}
                  onCancel={() => setEditingOrderData({editing: false, id: ''})}
                /> : null}
                {isDeleting !== '' && (
                  <ConfirmationDialog
                    onAccept={async () => {
                      setIsDeleting('');
                    }}
                    onDecline={async () => { setIsDeleting(''); }}
                    open={isDeleting !== ''}
                    prompt='Are you sure you want to remove this item?'
                    positiveText='Yes'
                    negativeText='No'
                    positiveVariant='success'
                    negativeVariant='danger'
                  />
                )}
              </Col>
              {renderStatusSubmitModal()}
              {renderCancellationModal()}
              {renderDeletionModal()}
            </>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    updateOrderStatus: orderStore.actions.updateOrderStatus,
    cancelOrder: orderStore.actions.cancelOrder,
    deleteOrder: orderStore.actions.deleteOrder
  }, dispatch)});
const mapStateToProps = (state: CommonState) => ({
  orders: orderStore.selectors.getAsArray(state),
  unapprovedOrders: orderStore.selectors.unapproved(state),
  approvedOrders: orderStore.selectors.approved(state),
  shippedOrders: orderStore.selectors.shipped(state),
  cancelledOrders: orderStore.selectors.cancelled(state),
  notCancelledOrders: orderStore.selectors.notCancelled(state),
  getOrderById: orderStore.selectors.getById(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(OrderGrid);
