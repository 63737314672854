import styles from './PageFooter.module.scss';
import {RoutePaths} from '../../../router/RoutePaths';
import {AppTheme, rbswLogo, rbswLogoSosVariant} from '../../../appTheme';
import React, {CSSProperties, memo} from 'react';
import {Row} from 'react-bootstrap';


type Props = {style?: CSSProperties; sosVariant?: boolean};


export const PageFooter = memo(({style, sosVariant = false}: Props) => {
  const footerTextColor = sosVariant ? {color: AppTheme.colors.logoGreen} : {};
  return (
    <Row className={styles['footer']} style={style}>
      <div className={styles['footer-text']} style={{...footerTextColor}}>
        Created by RavenBridge Software LLC
      </div>
      <a href={RoutePaths.rbswLink} style={{display: 'flex', maxWidth: '4%', minWidth: '4rem'}} target={'_blank'}>
        <img src={sosVariant ? rbswLogoSosVariant() : rbswLogo()} alt='RavenBridge Software Logo' className={styles['logo']}/>
      </a>
    </Row>
  );
});
