import {FormEvent} from 'react';
import {FormControl, FormControlProps} from 'react-bootstrap';
import {FieldInputProps} from 'formik';
import {DropdownOption} from '../components/util/form-components/SearchableDropdown/SearchableDropdown';
import {DropDownOptionRecord} from '../types';

export function combineClasses(...arr: (any|string|undefined|null)[]): string {
  return arr.filter((val) => !!val).join(' ');
}

export type BootstrapFormEvent = FormEvent<typeof FormControl & FormControlProps>;

export function getFieldValue(value: string | null | undefined) {
  if (value === undefined || value === null) {
    return '';
  }
  return value;
}

export function getFieldCheckValue<T>(field: FieldInputProps<T>) {
  if (field.value === undefined || field.value === null) {
    return false;
  }
  return field.value;
}

export function convertToDropDownOptions<T extends string|number = number>(
  objs: DropDownOptionRecord[],
  labelProperty: string = 'name',
  valueProperty: string = 'id'): DropdownOption<T>[] {
  return objs.map(obj => convertObjectToOption<T>(obj, labelProperty, valueProperty));
}

export function convertObjectToOption<T extends string|number>(
  obj: DropDownOptionRecord,
  labelProperty: string = 'name',
  valueProperty: string = 'id'): DropdownOption<T> {
  return ({value: obj[valueProperty], label: obj[labelProperty]});
}
