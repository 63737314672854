export const getExtension = (fileName: string) => fileName !== undefined ? fileName.slice(fileName.lastIndexOf('.'), fileName.length) : '';

export function getBase64(file: File):Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || '');
    reader.onerror = error => reject(error);
  });
}

export function getBase64FromArrayBuffer(array: ArrayBuffer):Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const blob = new Blob([array], { type: 'application/pdf' });
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result?.toString() || '');
    reader.onerror = error => reject(error);
  });
}
