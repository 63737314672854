import {Category, CategoryTreeNode} from '../redux/entities/inventory/category';
import {useMemo} from 'react';
import {isNullOrWhitespace} from '../util/string';

export const createCategoryTree = (categories: Category[]) => {
  const withSubCategories = (category: Category): CategoryTreeNode =>
    ({...category as CategoryTreeNode, children: categories.filter(c => c.superCategoryId === category.id).map(c => withSubCategories(c))});

  return categories.map(c => ({...c, children: []}) as CategoryTreeNode)
    .filter(c => isNullOrWhitespace(c.superCategoryId))
    .map(category => withSubCategories(category));
};

export type SimpleTreeData = {
  id: string;
  name: string;
  children?: SimpleTreeData[];
};

const useCategoryTree = (categories: Category[]) => useMemo(() => {
  return createCategoryTree(categories);
}, [categories]);

export default useCategoryTree;
