import {DropdownOption} from "../components/util/form-components/SearchableDropdown/SearchableDropdown";

export interface PublicMessage {
  message: string;
  bootstrapVariant: EBootstrapVariant
}

export enum EBootstrapVariant {
  none,
  primary,
  secondary,
  success,
  danger,
  warning,
  info,
  light,
  dark
}

/*{value: EBootstrapVariant.primary, label: 'Primary'},
  {value: EBootstrapVariant.secondary, label: 'Secondary'},*/

export const BootstrapVariantDropdownOptions: DropdownOption[] = [
  {value: EBootstrapVariant.none, label: 'Plain text'},
  {value: EBootstrapVariant.success, label: 'Success'},
  {value: EBootstrapVariant.danger, label: 'Danger'},
  {value: EBootstrapVariant.warning, label: 'Warning'},
  {value: EBootstrapVariant.info, label: 'Info'},
  {value: EBootstrapVariant.light, label: 'Light'},
  {value: EBootstrapVariant.dark, label: 'Dark'},
]
