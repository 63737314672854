import React, {FormEvent, ReactNode, useEffect, useRef, useState} from 'react';
import {Form, Formik, FormikErrors} from 'formik';
import {Alert, Button, Col, Form as BSForm, Modal, Nav, Row, Spinner, Tab} from 'react-bootstrap';
import {Order} from '../../../redux/entities/inventory/order';
import {makeOrder, makePrefilledOrder} from '../../../redux/factory/inventory/order';
import {propertyOf} from '../../../util';
import {ConsumerInformation} from '../../../redux/entities/inventory/order';
import {ConsumerInformationForm} from './form/ConsumerInformationForm';
import {TermsOfServiceForm} from './form/TermsOfServiceForm';
import ItemSelectionForm from './form/ItemSelectionForm/ItemSelectionForm';
import {useMount} from '../../../hooks/useMount';
import {CommonState} from '../../../redux';
import {bindActionCreators, Dispatch} from 'redux';
import {loadProductSelectionStateData} from '../../../redux/stateResponses/productSelectionState';
import {connect} from 'react-redux';
import {AxiosError} from 'axios/index';
import {getErrorResponseMessage, handleAxiosError} from '../../../util/http';
import {TopPaddedRow} from '../../../components/util/form-components/standardFormikInputLayout';
import OrderReview from './review/OrderReview';
import ReactSignatureCanvas from 'react-signature-canvas';
import {submitPublicOrder} from '../../../api/inventory/inventoryApi';
import {SubmissionConfirmation} from './review/SubmissionConfirmation';
import {HealthProgramOrderSchema} from './HealthProgramOrderSchema';
import IconButton from '../../../components/util/widgets/IconButton/IconButton';
import {AppTheme} from '../../../appTheme';
import styles from './HealthProgramOrder.module.scss';
import useStandardPrint from '../../../hooks/useStandardPrint/useStandardPrint';
import {CenteredSpinner} from '../../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {PublicMessage} from "../../../types/PublicMessage";
import {getPublicMessage} from "../../../api/publicMessageApi";

enum EOrderTab {
  customer,
  agreement,
  order,
  review
}

export const getConsumerInfoName = (name: keyof ConsumerInformation) =>
  `${propertyOf<Order>('consumerInformation')}.${name}`;

type Props = {} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function HealthProgramOrder(props: Props) {
  const {actions: {loadProductSelectionData}} = props;
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState<EOrderTab>(EOrderTab.customer);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [submissionErrorMessage, setSubmissionErrorMessage] = useState<string | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittedOrderData, setSubmittedOrderData] = useState<Order | null>(null);
  const [submissionTime, setSubmissionTime] = useState<Date | undefined>();
  const printRef = useRef(null);
  const canvasRef = useRef<ReactSignatureCanvas>(null);
  const secondCanvasRef = useRef<ReactSignatureCanvas>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const [asSinglePage, setAsSinglePage] = useState(false);
  const [canvasData, setCanvasData] = useState<SignaturePad.Point[][]>([[]]);
  const [publicMessage, setPublicMessage] = useState<PublicMessage | null>(null);

  useMount(async () => {
    try {
      await loadProductSelectionData();
      setLoading(false);
      const response = await getPublicMessage();
      setPublicMessage(response);
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load inventory'}));
    }
  });

  const currentCanvasRef = () => asSinglePage ? canvasRef.current : secondCanvasRef.current;

  const handlePrint = useStandardPrint(printRef);
  const handleViewChange = (val: boolean): void => {
    const update = canvasRef.current?.toData().length && canvasRef.current.toData()[0].length;
    const updateSecond = secondCanvasRef.current?.toData().length && secondCanvasRef.current.toData()[0].length;
    const data = val ? (update ? canvasRef.current?.toData() : canvasData) : (updateSecond ? secondCanvasRef.current?.toData() : canvasData);
    setCanvasData(() => data);
    setAsSinglePage(val);
  };

  useEffect(() => {
    if (canvasData.length && canvasData[0].length) {
      canvasRef.current?.fromData(canvasData);
      secondCanvasRef.current?.fromData(canvasData);
    }
  }, [canvasData]);

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    association: Order,
    validate: (values: Order) => Promise<FormikErrors<Order>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    setIsSubmitting(true);
    setSubmissionErrorMessage('');
    e.persist();
    e.preventDefault();
    const errors = await validate({
      ...association, consumerInformation: {...association.consumerInformation, signaturePath: currentCanvasRef()?.isEmpty() ?? false}
    } as any);
    if (Object.values(errors).length !== 0) {
      setAsSinglePage(true);
      formikHandleSubmit(e);
      setShowConfirmationModal(false);
      setCurrentTab(EOrderTab.customer);
      window.scrollTo(0, -400);
      setIsSubmitting(false);
    } else {
      try {
        const request: Order = {
          ...association,
          orderItems: association.orderItems.filter(oi =>
              (oi?.itemQuantity !== undefined && oi?.itemQuantity) ||
              (oi?.itemOptions?.length > 0 && oi?.itemOptions?.find(o => o.quantity > 0))
            )
        };
        request.consumerInformation.signaturePath = canvasRef.current?.toDataURL('image/png') ?? '';
        await submitPublicOrder(request);
        setSubmittedOrderData(request);
        setShowConfirmationModal(false);
        setSubmissionTime(new Date());
      } catch (e: AxiosError | any) {
        setSubmissionErrorMessage(getErrorResponseMessage(e));
      }
    }
    setIsSubmitting(false);
  };

  const renderConfirmationModal = () => (
    <Modal show={showConfirmationModal} size={'lg'} centered={true}>
      <Modal.Body>
        <Modal.Title>Confirmation</Modal.Title>
        <Col>
          <TopPaddedRow>
            Are you sure you are ready to submit your order to SOS International?
          </TopPaddedRow>
          <TopPaddedRow>
            <BSForm.Group className={'modal-buttons'}>
              {isSubmitting ?
                <Spinner animation='border' role='status'>
                  <span className='sr-only'>Loading...</span>
                </Spinner>
                :
                <Button onClick={() => setShowConfirmationModal(false)} variant={'danger'} className={'modal-close-button'}>
                  Cancel & Edit
                </Button>
              }
              {!isSubmitting ? <Button variant={'primary'} onClick={() => formRef.current?.requestSubmit()} type='submit'>Submit</Button> : null}
            </BSForm.Group>
          </TopPaddedRow>
        </Col>
      </Modal.Body>
    </Modal>
  );

  const handleBack = () => currentTab !== EOrderTab.customer && setCurrentTab((prev) => prev - 1);
  const handleForward = () => (currentTab === EOrderTab.review || asSinglePage) ? setShowConfirmationModal(true) : setCurrentTab((prev) => prev + 1);

  const renderTabs = (values: Order, errors: FormikErrors<Order>) => (
    <Tab.Container activeKey={currentTab}>
      <Row style={{padding: '0 30px'}}>
        <Nav variant={'pills'} justify={true}>
          <Nav.Item style={{whiteSpace: 'nowrap'}}>
            <Nav.Link eventKey={EOrderTab.customer} onClick={() => setCurrentTab(EOrderTab.customer)}>
              General
            </Nav.Link>
          </Nav.Item>
          <Nav.Item style={{whiteSpace: 'nowrap'}}>
            <Nav.Link eventKey={EOrderTab.agreement} onClick={() => setCurrentTab(EOrderTab.agreement)}>
              Donation Agreement
            </Nav.Link>
          </Nav.Item>
          <Nav.Item style={{whiteSpace: 'nowrap'}}>
            <Nav.Link eventKey={EOrderTab.order} onClick={() => setCurrentTab(EOrderTab.order)}>
              Product Selection
            </Nav.Link>
          </Nav.Item>
          <Nav.Item style={{whiteSpace: 'nowrap'}}>
            <Nav.Link eventKey={EOrderTab.review} onClick={() => setCurrentTab(EOrderTab.review)}>
              Order Review
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Row>
      <Row style={{display: 'flex', marginTop: '1rem'}}>
        <Tab.Content style={{width: '100%', height: '100%', padding: '0'}}>
          <Tab.Pane eventKey={EOrderTab.customer} style={{width: '100%'}}>
            <ConsumerInformationForm asSinglePage={asSinglePage} setAsSinglePage={handleViewChange} setTab={() => setCurrentTab(EOrderTab.customer)} message={publicMessage}/>
          </Tab.Pane>
          <Tab.Pane eventKey={EOrderTab.agreement} style={{width: '100%'}}>
            <TermsOfServiceForm
              ref={canvasRef}
              asSinglePage={asSinglePage}
              setAsSinglePage={handleViewChange}
              setTab={() => setCurrentTab(EOrderTab.agreement)}
            />
          </Tab.Pane>
          <Tab.Pane eventKey={EOrderTab.order} style={{width: '100%'}}>
            <ItemSelectionForm asSinglePage={asSinglePage} setAsSinglePage={handleViewChange} setTab={() => setCurrentTab(EOrderTab.order)}/>
          </Tab.Pane>
          <Tab.Pane eventKey={EOrderTab.review} style={{width: '100%'}}>
            <OrderReview
              order={values}
              asSinglePage={asSinglePage} setAsSinglePage={handleViewChange}
              errors={errors}
              canvasURL={() => canvasRef.current?.toDataURL()}
              setTab={() => setCurrentTab(EOrderTab.review)}
              commentAsInput={true}
            />
          </Tab.Pane>
        </Tab.Content>
      </Row>
    </Tab.Container>
  );

  const renderForm = () => (
    <Formik<Order>
      initialValues={makeOrder()}
      validationSchema={HealthProgramOrderSchema}
      onSubmit={() => undefined}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {({values, validateForm, handleSubmit, errors, setValues}) => {
        return (
        <Form
          style={{display: 'flex', flexDirection: 'column', minHeight: '100%'}}
          onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}
          ref={formRef}
        >
          <>
          <div ref={printRef} className={styles['print']}>
            <OrderReview
              order={values}
              errors={errors}
              canvasURL={() => !asSinglePage ? canvasRef.current?.toDataURL() : secondCanvasRef.current?.toDataURL()}
              showCanvas={true}
              showSignature={true}
              submissionTime={submissionTime}
            />
          </div>
          {submittedOrderData ? <SubmissionConfirmation order={values} printOrder={handlePrint}/> : (
            <>
              {!asSinglePage ? renderTabs(values, errors) :
                <Col>
                  <ConsumerInformationForm asSinglePage={asSinglePage} setAsSinglePage={handleViewChange} setTab={() => setCurrentTab(EOrderTab.customer)} message={publicMessage}/>
                  <TermsOfServiceForm ref={secondCanvasRef}/>
                  <ItemSelectionForm asSinglePage={asSinglePage}/>
                  <OrderReview
                    order={values}
                    errors={errors}
                    canvasURL={() => canvasRef.current?.toDataURL()}
                    showCanvas={!asSinglePage}
                    showSignature={false}
                    asSinglePage={asSinglePage}
                    commentAsInput={true}
                  />
                </Col>
              }
              {submissionErrorMessage ? <Alert>{submissionErrorMessage}</Alert> : null}
              <Row style={{justifyContent: 'flex-end', paddingRight: '45px', marginTop: 'auto'}}>
                {process.env.NODE_ENV === 'development' &&
                  <Button style={{marginRight: '15px'}} onClick={() => setValues(makePrefilledOrder())}>Autofill values (dev only)</Button>}
                {currentTab !== EOrderTab.customer ? <Button onClick={handleBack}>Previous</Button> : null}
                <Button onClick={() => handleForward()} style={{marginLeft: '1rem'}}>
                  {(currentTab === EOrderTab.review || asSinglePage) ? 'Submit Order' : 'Next'}
                </Button>
              </Row>
              {renderConfirmationModal()}
            </>
          )}
            </>
        </Form>
        );
      }}
    </Formik>
  );

  return loading ?  <CenteredSpinner/> : ((loading && errorMessage) ? <CenteredErrorMessage message={errorMessage} /> : renderForm());
}

export interface ChangeViewButtonProps {
  title: string | ReactNode;
  asSinglePage?: boolean;
  setAsSinglePage?: (val: boolean) => void;
  setTab?: () => void;
}

export const ChangeViewButton = (props: ChangeViewButtonProps) => {
  if (props.asSinglePage === undefined || !props.setAsSinglePage || !props.setTab) return <div>{props.title}</div>;
  return (
    <Row>
      <div style={{marginLeft: '15px'}}>
        {props.title}
      </div>
      <IconButton
        icon={'eye'}
        iconToolTipText={`Switch to ${props.asSinglePage ? 'tab' : 'single page'} view`}
        color={AppTheme.colors.logoBlue}
        onClick={() => {
          props.setAsSinglePage!(!props.asSinglePage);
          props.setTab!();
        }}
        styles={{marginLeft: '15px'}}
      />
    </Row>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    loadProductSelectionData: loadProductSelectionStateData
  }, dispatch)});
const mapStateToProps = (state: CommonState) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HealthProgramOrder);
