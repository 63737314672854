import {Col, Row} from 'react-bootstrap';
import React from 'react';
import {NoItemsList} from '../NoItemsList/NoItemsList';

interface Props<T> extends React.HTMLAttributes<HTMLElement> {
  items: T[];
  renderHeader?: () => JSX.Element;
  renderFooter?: () => JSX.Element;
  renderItem: (item: T, index: number) => JSX.Element;
  noItemsLabel: string;
}

export function ListTable<T>({items, renderHeader, renderFooter, renderItem, noItemsLabel, style, className}: Props<T>) {
  return (
    <>
      {renderHeader && renderHeader()}
      <Row style={style} className={className}>
        <Col style={{display: 'flex', flexDirection: 'column', flex: '1', width: '100%', padding: '0'}}>
          {
            items.length === 0 ? <NoItemsList label={noItemsLabel}/> :
              items.map((item, index) => (<React.Fragment key={index}>{renderItem(item, index)}</React.Fragment>))
          }
        </Col>
      </Row>
      {renderFooter && renderFooter()}
    </>
  );
}
