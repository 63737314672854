export interface RoutePath {
  path: string;
  component: any;
}
const notFound = '/not-found';

const PrivateBase = '/staff' as const;
const AuthBase = '/auth';

const RootPath = {
  all: '*',
  publicRoot: '/',
  privateRoot: PrivateBase,
  authRoot: AuthBase
} as const;

enum AuthenticationPaths {
  login = AuthBase + '/login',
  logout = AuthBase + '/logout',
  forgotPassword = AuthBase + '/forgot-password',
  resetPassword = AuthBase + '/reset-password'
}

const PublicPaths = {
  ...AuthenticationPaths,
  order: '/order',
  landing: RootPath.publicRoot
} as const;

const ProfilePaths = {
  View: PrivateBase + '/profile/view',
  Edit: PrivateBase + '/profile/edit',
  EditPassword: PrivateBase + '/profile/password'
} as const;

const ConfigurationPaths = {
  userManagement: PrivateBase + '/configuration/users',
  inventoryManagement: PrivateBase + '/configuration/inventory',
  orderManagement: PrivateBase + '/configuration/orders',
  orderManagementQuery: PrivateBase + '/configuration/orders/:id?',
  messageManagement: PrivateBase + '/configuration/message'
};

const PrivatePaths = {
  home: PrivateBase + '/home',
  configurationPaths: ConfigurationPaths,
  profilePaths: ProfilePaths
} as const;

const RavenBridgeLink = 'https://www.ravenbridgesoftware.com/';


export function applyBasePath(basePath: string, pathFn: (basePath: string) => string) {
  return pathFn(basePath);
}

export function applyBasePaths(basePath: string, ...pathsFns: ((basePath: string) => string)[]) {
  return pathsFns.map(fn => applyBasePath(basePath, fn));
}

export interface ComponentPath {
  component: any;
  paths: string[];
}

export function convertComponentPaths(...editorPaths: ComponentPath[]): RoutePath[] {
  return editorPaths.map(editorPath => editorPath.paths.map(path => ({
    path: path,
    component: editorPath.component
  }))).flat(1);
}

export type UrlParam = number|string;

export const RoutePaths = {
  ...RootPath,
  authPaths: AuthenticationPaths,
  publicPaths: PublicPaths,
  privatePaths: PrivatePaths,
  notFound: notFound,
  profilePaths: ProfilePaths,
  rbswLink: RavenBridgeLink
} as const;
